import { getLookupGroupByIdOrCode } from "@/components/lookup/lookupGroupApi.js";
import type { Paging, UseApiConfig } from "@/utils/api";
import { getMurnParts, lookupMurnRegex } from "@/utils/helpers/murn.js";
import type { AxiosResponse, RawAxiosResponseHeaders } from "axios";

/** Get the description for the Lookup associated with a murn.
 * This uses the cached LookupGroup data. */
export async function getLookupDescription(murn: string) {
  return (await getLookupByMurn(murn))?.description;
}

/** Get the longDescription for the Lookup associated with a murn.
 * This uses the cached LookupGroup data.
 */
export async function getLookupLongDescription(murn: string) {
  return (await getLookupByMurn(murn))?.longDescription;
}

/** Get the longDescription if available, otherwise get the description for the Lookup associated with a murn.
 * This uses the cached LookupGroup data.
 */
export async function getLookupLongDescriptionWithFallback(murn: string) {
  const lookup = await getLookupByMurn(murn);
  return lookup?.longDescription && lookup?.longDescription.length > 0 ? lookup.longDescription : lookup?.description;
}

/** Get the metadata for the Lookup associated with a murn.
 * This uses the cached LookupGroup data.
 */
export async function getLookupMetadata(murn: string) {
  return (await getLookupByMurn(murn))?.metadata;
}

/** Get the Lookup associated with a murn.
 * This uses the cached LookupGroup data.
 */
export async function getLookupByMurn(murn: string) {
  const codes = getGroupCodeAndCodeFromLookupMurn(murn);
  // Get the whole group, then search through that to take advantage of the caching without having to cache individual requests for specific lookups.
  const groupResponse = await getLookupGroupByIdOrCode(codes.groupCode);
  return groupResponse.data.lookups._items.find((x) => x.code === codes.code);
}

/** Extract the groupCode and code for a Lookup from a murn. */
export function getGroupCodeAndCodeFromLookupMurn(murn: string) {
  const parts = getMurnParts(murn, lookupMurnRegex).id?.split("/");
  if (!parts || parts.length !== 2) throw new Error("Invalid Lookup MURN");
  return { groupCode: parts[0], code: parts[1] };
}

/** Beware - This method mutates the provided `response` object!
 * Runs the `responseAdapter` asynchronously against every item in the response's `_items`
 * @returns `response.data`
 */
export async function adaptPagedResponse<T>(
  response: AxiosResponse<Paging<T>, any>,
  responseAdapter: Required<UseApiConfig<T, T>>["responseAdapter"],
  transformer = (item: T): AxiosResponse<T, any> => ({ ...response, data: item }),
): Promise<Paging<T>> {
  return new Promise(async (resolve) => {
    await Promise.all(response.data._items.map((item) => responseAdapter(transformer(item))));
    resolve(response.data);
  });
}

/**
 * This function will iterate through the entire object and fetch the murn value for any property that ends with 'Murn'.
 * It then adds this value to a new property named after the original property with 'Value' appended to it.
 * (EX: data.stateMurn will end up adding data.stateMurnValue)
 * This function is the easiest to implement as it does not require knowning which/how many murn properties there are.
 * However, it provides much less control and is likely overkill in many cases.
 * Most of the time, we do not need all these values filled.
 * @param {object} data The entity to retrive murn values for
 */
export async function fillAllMurnValues(data: any) {
  for (const key in data) {
    if (key.endsWith("Murn")) {
      if (data[key]) {
        const result = await getLookupDescription(data[key]);
        data[`${key}Value`] = result;
      }
    }
  }
}

/**
 * This function will iterate through the property names given and fetch the murn values for them.
 * It then adds this value to a new property named after the original property with 'Value' appended to it.
 * (EX: data.stateMurn will end up adding data.stateMurnValue)
 * @param {object} data The entity to retrive murn values for
 * @param {string[]} propertyNames List of property names that should get the murn values fetched for. Must be full property name (must include Murn prefix if present)
 */
export async function fillMurnValues(data: any, propertyNames: string[]): Promise<void> {
  await Promise.all(
    propertyNames.map(async (name) => {
      if (data[name]) {
        const result = await getLookupDescription(data[name]);
        data[`${name}Value`] = result;
      }
    }),
  );
}

/**
 * This function will iterate through the property names given and fetch the murn values for them.
 * It is similar to `fillMurnValues` except it does not require the 'Murn' suffix, instead it adds it on its own.
 * Instead of supplying ['stateMurn', 'countryMurn'] you can give it ['state', 'country']
 * (This function can only be used if all murn properties given do have the murn suffix - which they should.)
 * It then adds this value to a new property named after the original property with 'Value' appended to it.
 * (EX: data.stateMurn will end up adding data.stateMurnValue)
 * @param {object} data The entity to retrive murn values for
 * @param {string[]} propertyNames List of property names that should get the murn values fetched for. Should exclude Murn prefix.
 */
export async function fillMurnValuesWithMurnSuffix(data: any, propertyNames: string[]): Promise<void> {
  await Promise.all(
    propertyNames.map(async (name) => {
      if (data[`${name}Murn`]) {
        const result = await getLookupDescription(data[`${name}Murn`]);
        data[`${name}MurnValue`] = result;
      }
    }),
  );
}

/**
 * This function will fill in the murn values for state and country for an entity that has a plain address.
 * It will append stateMurnValue and countryMurnValue to the object provided.
 * @param {object} data The entity to retrive murn values for
 */
export async function fillAddressMurnValues(data: any) {
  if (data.stateMurn) {
    let state = await getLookupDescription(data.stateMurn);
    data.stateMurnValue = state;
  }
  if (data.countryMurn) {
    let country = await getLookupDescription(data.countryMurn);
    data.countryMurnValue = country;
  }
}

/**
 * This function will fill in the murn values for state and country for an entity that has prefixed address(es).
 * It will append [prefix]stateMurnValue and [prefix]countryMurnValue for each prefix provided to the object provided.
 * @param {object} data The entity to retrive murn values for
 * @param {string[]} prefixes List of prefixes for the addresses the entity has
 */
export async function fillPrefixedAddressMurnValues(data: any, prefixes: string[]) {
  await Promise.all(
    prefixes.map(async (prefix) => {
      if (data[`${prefix}StateMurn`]) {
        let state = await getLookupDescription(data[`${prefix}StateMurn`]);
        data[`${prefix}StateMurnValue`] = state;
      }
      if (data[`${prefix}CountryMurn`]) {
        let country = await getLookupDescription(data[`${prefix}CountryMurn`]);
        data[`${prefix}CountryMurnValue`] = country;
      }
    }),
  );
}
