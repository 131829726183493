export class NotifyOptions {
  _title: string;
  _text: string;

  constructor(hide: boolean, group: string, type: string, title: string, text: string, time: number) {
    this.hide = hide;
    this.group = group;
    this.type = type;
    this._title = title;
    this._text = text;
    this.time = time;
  }
  /**
   * Used in apiService to determine whether to render a Notification
   * @type {boolean}  */
  hide;
  /**
   * Specifies which NotificationGroup to use for rendering
   * @type {string}  */
  group;
  /**
   * Sub-type to modify appearance within a group
   * @type {string} */
  type;
  /**
   * Notif title (use Title Case)
   * @type {string} */
  get title() {
    return this._title;
  }
  set title(val) {
    this._title = val;
  }
  /**
   * Notif body (use Sentence case)
   * @type {string} */
  get text() {
    return this._text;
  }
  set text(val) {
    this._text = val;
  }
  /**
   * Overrides default timeout (varies depending upon the notif type)
   * @type {number} */
  time;

  toOptions() {
    return {
      group: this.group,
      type: this.type,
      title: this.title,
      text: this.text,
    };
  }
}
