import {
  addressFromFormkit,
  addressToFormkit,
  plainAddressFromFormkit,
  plainAddressToFormkit,
} from "@mui/components/fk";
import type { HistoricalLandmark } from "./historicalLandmark";

export default {
  addAuthRequestFields,
  getInitialData,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: HistoricalLandmark) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}

function getInitialData() {
  const result = {} as HistoricalLandmark; //TODO:TS
  return addAuthRequestFields(result);
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: HistoricalLandmark) {
  plainAddressToFormkit(data);
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: any) {
  //TODO:TS
  plainAddressFromFormkit(data as HistoricalLandmark);
}
