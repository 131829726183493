<template>
  <div class="flex-auto h-1 min-h-0 overflow-y-auto">
    <div class="px-3 py-3 prose md:px-6 lg:pt-5" :class="viewerStore.showSidebar ? 'visible' : 'invisible'">
      <!-- bold text-lg mb-1 -->
      <h4 class="flex items-center font-medium"><MapPinIcon class="h-5 w-5 inline-block mr-2" />Locations</h4>
      <p>Search locations using keywords on the Locations panel.</p>

      <h4 class="flex items-center font-medium">
        <FunnelIcon class="h-5 w-5 inline-block mr-2" />Datasets and Filters
      </h4>
      <p>Show and hide datasets on the Datasets and Filters panel or toggle buttons atop the map.</p>
      <p class="hidden">Some datasets allow you to add additional filters specific to that type of data.</p>

      <!-- bold text-lg mt-5 mb-1 -->
      <h4 class="flex items-center font-medium"><Square3Stack3DIcon class="h-5 w-5 inline-block mr-2" />Layers</h4>
      <p>Geometry layers control the location types displayed on the map.</p>
      <p>Base layers control the map imagery (e.g. street, satellite, topographical).</p>
      <p>Reference layers provide additional data on the map but cannot be searched or filtered.</p>

      <!-- bold text-lg mt-5 mb-1 -->
      <h4 class="flex items-center font-medium"><ArrowUturnLeftIcon class="h-5 w-5 inline-block mr-2" />Reset</h4>
      <p>Reset button will remove the keyword search, restore all datasets, and delete all filters.</p>

      <h4 class="flex items-center font-medium"><HomeIcon class="h-5 w-5 inline-block mr-2" />Home</h4>
      <p>Home button restores the initial default map view.</p>

      <h4 class="flex items-center font-medium"><WrenchIcon class="h-5 w-5 inline-block mr-2" />Tools</h4>
      <p>Measure Distance tool can be used to compute the distance between linear segments on the map.</p>
      <p>Measure Area tool can be used to calculate the area of a polygon on the map</p>
      <p>Zoom To tool can be used to center focus on your location or an island on the map.</p>
    </div>
  </div>
</template>

<script lang="ts">
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import { useUser } from "@/utils/auth/authentication";
import { Authorize } from "@/utils/auth/authorization";
import { useAppSettingsStore } from "@/components/common";
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/16/solid";
import {
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  FunnelIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  Square3Stack3DIcon,
  WrenchIcon,
  MapPinIcon,
} from "@heroicons/vue/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent, ref } from "vue";
import { useViewerStore } from "@/components/viewer/viewerStore";

/** Displays Viewer help / user guide
 */
export default defineComponent({
  name: "ViewerPanelHelp",
  components: {
    Authorize,
    Bars3Icon,
    XMarkIcon,
    SkeletonButton,
    ChevronDownIcon,
    EyeIcon,
    EyeSlashIcon,
    ArrowUturnLeftIcon,
    FunnelIcon,
    MagnifyingGlassIcon,
    Square3Stack3DIcon,
    WrenchIcon,
    HomeIcon,
    MapPinIcon,
  },
  setup(props, context) {
    const viewerStore = useViewerStore();
    const appSettingsStore = useAppSettingsStore();
    const { userRef } = useUser();

    return {
      appSettingsStore,
      userRef,
      viewerStore,
    };
  },
});
</script>

<style module></style>
