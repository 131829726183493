import { NotifyOptions } from "@/components/notifications/NotifyOptions.js";

export class NotifyOptionsSuccess extends NotifyOptions {
  constructor(
    hide: boolean,
    group: string,
    type: string,
    title: string,
    text: string,
    created: string,
    updated: string,
    deleted: string,
    time: number,
  ) {
    super(hide, group, type, title, text, time);
    this.created = created;
    this.updated = updated;
    this.deleted = deleted;
  }

  get title() {
    if (this._title) {
      return this._title;
    }

    if (this.updated) {
      return this.updated + " Updated";
    } else if (this.created) {
      return this.created + " Created";
    } else if (this.deleted) {
      return this.deleted + " Deleted";
    }

    return "";
  }
  set title(val) {
    this._title = val;
  }

  /**
   * Entity name injected in standard Created title
   * @type {string}  */
  created;
  /**
   * Entity name injected in standard Updated title
   * @type {string}  */
  updated;
  /**
   * Entity name injected in standard Deleted title
   * @type {string}  */
  deleted;

  toOptions() {
    return {
      group: this.group,
      type: this.type,
      title: this.title,
      text: this.text,
    };
  }
}
