import { acceptHMRUpdate, defineStore } from "pinia";
import { computed, ref } from "vue";
import type { AuditEntry, EntityWithAudits, Timeline } from "@/components/entity/audit/index.js";
import { TimelineItem, getEntityAudits, getEntityAuditsForWidget } from "@/components/entity/audit/entityAudit.js";

/** Returns a store for Entity Audits (audit history for the current entity), creates it if necessary. */
export const useEntityAuditStore = defineStore("EntityAuditStore", () => {
  // state
  const auditEntries = ref([] as Array<AuditEntry>);
  const httpStatusCode = ref<string | undefined>(undefined);
  const entityType = ref("");
  const entityId = ref(0);
  const count = ref(0);
  const totalResultsCount = ref(0);
  const href = ref("");

  // Do we need the rest?
  const httpStatusMessage = ref(null);
  const links = ref(null);
  const rel = ref(null);

  // getters
  /** Generates the Audit items required for the Entity Audit Widget */
  const entityAuditsForWidget = computed<TimelineItem[]>(() => {
    if (!auditEntries.value.length || httpStatusCode.value === "403") return []; // Makes this more component friendly

    return getEntityAuditsForWidget(auditEntries.value, entityType.value, entityId.value);
  });
  /** Generates the Audit items required for the Entity Audit page */
  const entityAudits = computed<Timeline>(() => {
    if (!auditEntries.value.length || httpStatusCode.value === "403") return []; // Makes this more component friendly

    return getEntityAudits(auditEntries.value, entityType.value, entityId.value);
  });

  // actions
  /** Resets the values of the store. Used to prevent residual data from a previous entity appearing on screen while waiting for the new entities audits to load. */
  function reset() {
    auditEntries.value = [];
    httpStatusCode.value = undefined;
    entityType.value = "";
    entityId.value = -1;
    count.value = 0;
    totalResultsCount.value = 0;
    href.value = "";

    httpStatusMessage.value = null;
    links.value = null;
    rel.value = null;
  }

  /** Hydrates the Entity Audit Store
   *
   * @param {EntityWithAudits} entity Current entity being used that audits should be available for
   * @param {Object} config configuration object with the entityTupe and entityId
   */
  function fill(entity: EntityWithAudits, config?: { entityType: string; entityId: number }) {
    auditEntries.value = entity?.auditHistory?._items || [];
    httpStatusCode.value = entity?.auditHistory?.httpStatusCode;
    count.value = entity?.auditHistory?.count;
    totalResultsCount.value = entity?.auditHistory?.totalResultsCount;
    href.value = entity?.auditHistory?.href;

    if (config) {
      entityType.value = config.entityType;
      entityId.value = config.entityId;
    }
    //TODO: hydrate the other properties?
  }

  return {
    //state
    auditEntries,
    httpStatusCode,
    count,
    totalResultsCount,
    href,
    entityId,
    //getters
    entityAuditsForWidget,
    entityAudits,
    //actions
    reset,
    fill,
  };
});

export type UseEntityAuditStore = ReturnType<typeof useEntityAuditStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEntityAuditStore, import.meta.hot));
}
