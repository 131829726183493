<template>
  <div class="mb-8 px-2 sm:px-0">
    <h2 class="text-sm font-medium text-neutral-500">{{ entityNoteConfigStore.noteLabelPlural }}</h2>
    <ol
      role="list"
      class="mt-2 grid grid-cols-1 gap-3 md:gap-2"
      v-if="entityNoteStore.entityNotesForWidget.length > 0"
      v-auto-animate
    >
      <li v-for="(item, itemIdx) in entityNoteStore.entityNotesForWidget" :key="item.id">
        <div class="relative ml-1 pb-5">
          <div class="relative flex space-x-3">
            <div>
              <span
                :class="[
                  'flex items-center justify-center rounded-full ring-8 ring-neutral-100',
                  true ? '' : 'mx-0.5 my-0.5 h-4 w-4 border border-neutral-300 bg-white',
                ]"
              >
                <InitialBadge size="sm" :names="item.auditUserName" />
              </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-0">
              <div>
                <p class="text-sm text-neutral-500">
                  <span class="font-medium text-neutral-900">{{ item.auditUserName }}</span>
                </p>
              </div>
              <div class="whitespace-nowrap text-right text-sm text-neutral-500">{{ item.displayDate }} ago</div>
            </div>
          </div>
          <div class="ml-9 text-sm text-neutral-700 pt-1 whitespace-pre-wrap break-words">{{ item.noteText }}</div>
        </div>
      </li>
      <li class="hidden">
        <div class="relative pb-8">
          <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-neutral-200" aria-hidden="true"></span>
        </div>
      </li>
      <li>
        <BaseButton
          @click="viewAllNotes"
          :label.once="`View all ${entityNoteConfigStore.noteLabelPlural}`"
          class=""
        ></BaseButton>
      </li>
    </ol>
    <div class="mt-6 flex gap-x-3 pb-4">
      <InitialBadge size="sm" :names="displayName" />
      <FormKit
        type="form"
        id="noteFormId"
        @submit="handleNoteSubmit"
        :actions="false"
        autocomplete="off"
        form-class="$reset flex-auto"
        #default="{ value, state: formState }"
      >
        <div class="">
          <FormKit
            type="textarea"
            label="Note"
            name="noteText"
            rows="3"
            validation="length:0,255"
            :sections-schema="noLabel"
            :help="`${value?.noteText ? (value.noteText as string).length : 0} / 255`"
            input-class="!h-auto w-full resize-none"
          />
        </div>

        <div class="flex justify-end" :class="formState.valid ? `-mt-7` : `-mt-2`">
          <BaseButton
            @click="saveNote(formState)"
            label="Add Note"
            variant="create"
            :disabled="!formState.valid || !value?.noteText"
          ></BaseButton>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script lang="ts">
import InitialBadge from "@/components/badge/InitialBadge.vue";
import { useApi } from "@/utils/api";
import { noLabelSchema } from "@/utils/helpers";

import { getUser } from "@/components/common";
import { FormKitFrameworkContextState, reset, submitForm } from "@formkit/core";
import { PropType, computed, defineComponent } from "vue";
import { useRoute, useRouter, type RouteLocationRaw } from "vue-router";
import { Annotation } from ".";
import { saveAnnotation } from "@/components/entity/note/annotationApi";
import { useEntityNoteConfigStore } from "@/components/entity/note/entityNoteConfigStore";
import { useEntityNoteStore } from "@/components/entity/note/entityNoteStore";

/** This component is a condensed version of the notes that can fit in the View page of an entity.
 * Within this component, you can see the last few notes, add a new note, or navigate to the notes list.
 */
export default defineComponent({
  name: "EntityNoteWidget",
  emits: ["updateEntityChild"],
  props: {
    /** This is the route to the notes list page (EntityNotes) for this entity  */
    notesTo: {
      type: Object as PropType<RouteLocationRaw>,
      default: () => ({
        path: "./notes",
      }),
    },
    /** Name of the field ID (entityId) that the note belongs to */
    idField: {
      type: String,
      required: true,
    },
    /** Object containing additional Ids and their values that the note should be tied to (E.g. {exampleEntityId: 3}) */
    additionalIds: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const noLabel = noLabelSchema();
    const route = useRoute();
    const router = useRouter();
    const userRef = getUser();
    const entityNoteStore = useEntityNoteStore();
    const entityNoteConfigStore = useEntityNoteConfigStore();

    const viewAllNotes = () => {
      router.push(props.notesTo);
    };

    const {
      dataRef: annotationResult,
      // statusRef,
      exec: execSaveAnnotation,
    } = useApi(saveAnnotation, {
      responseAdapter: (response) => {
        return response.data;
      },
    });

    const handleNoteSubmit = async (data: Annotation) => {
      if (!data.noteText) return;

      data[props.idField] = route.params.id;
      for (let key in props.additionalIds) {
        data[key] = props.additionalIds[key];
      }
      const newNote = await execSaveAnnotation(data);
      if (!newNote) return; //error?
      entityNoteStore.addNote(newNote);

      // Reset the form.
      reset("noteFormId");
    };

    const saveNote = (formState: FormKitFrameworkContextState) => {
      if (formState.loading) return;

      submitForm("noteFormId");
    };

    const displayName = computed(() => [userRef.value.firstName, userRef.value.lastName]);
    return { entityNoteStore, entityNoteConfigStore, viewAllNotes, displayName, handleNoteSubmit, noLabel, saveNote };
  },
  components: {
    InitialBadge,
  },
});
</script>
