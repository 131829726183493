import { cachedApiService } from "@/utils/api/index.js";

import type { NotifyOptionsError, NotifyOptionsSuccess } from "@/components/notifications/index.js";
import type { PagingApiAndNotifierConfig } from "@/utils/api/index.js";

import type { LookupGroup } from "@/components/lookup/lookup.js";
import { useLookupConfigStore } from "@/components/lookup/lookupConfigStore.js";

const URL = "lookup-groups";

let api: ReturnType<typeof cachedApiService<LookupGroup>>;

const getApi = () => {
  if (api) return api;

  const lookupConfigStore = useLookupConfigStore();
  const axiosParams = {
    // Set different base URL based on the environment
    baseURL: lookupConfigStore.baseURL,
  };
  api = cachedApiService<LookupGroup>(lookupConfigStore.apiHost, axiosParams);
  return api;
};

const getEntityLabel = () => {
  const lookupConfigStore = useLookupConfigStore();
  return lookupConfigStore.lookupLabel;
};

/**
 * getLookupGroupByIdOrCode is cached and is meant for everyday read-only access to Lookups by LookupGroup
 * @param {string | number} idOrCode
 */
const getLookupGroupByIdOrCode = (idOrCode: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = getEntityLabel();
    config?.errorNotifCb?.(options);
  };

  return getApi().get(URL + "/" + encodeURIComponent(idOrCode), {
    successNotifCb,
    errorNotifCb,
  });
};

export { getLookupGroupByIdOrCode };
