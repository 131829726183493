import { apiHosts } from "@casa/api/constants/apiHosts";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
import { Paging, PagingApiAndNotifierConfig, apiService } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";
import type { AxiosResponse } from "axios";
import type { HistoricalLandmark, HistoricalLandmarkNew } from "./historicalLandmark";
import historicalLandmarkService from "./historicalLandmarkService";

const URL = "historical-landmarks";
const ENTITY_NAME = "Historical Landmark";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};

const api = apiService<HistoricalLandmark, Paging<HistoricalLandmark>>(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} config.abortMethod Callback for when a request has been aborted
 */
const getHistoricalLandmarks = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getHistoricalLandmarkById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewHistoricalLandmark = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: historicalLandmarkService.getInitialData() } as AxiosResponse<HistoricalLandmarkNew, any>;
};

const createHistoricalLandmark = (historicalLandmark: HistoricalLandmark, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, historicalLandmark, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateHistoricalLandmark = (historicalLandmark: HistoricalLandmark, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + historicalLandmark.id, historicalLandmark, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {HistoricalLandmark} historicalLandmark This cannot be a 'ref' to a historicalLandmark
 */
const saveHistoricalLandmark = (historicalLandmark: HistoricalLandmark, config: PagingApiAndNotifierConfig = {}) => {
  if (historicalLandmark.id) {
    return updateHistoricalLandmark(historicalLandmark, config);
  } else {
    return createHistoricalLandmark(historicalLandmark, config);
  }
};

const deleteHistoricalLandmarkById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  createHistoricalLandmark,
  deleteHistoricalLandmarkById,
  getHistoricalLandmarkById,
  getHistoricalLandmarks,
  getNewHistoricalLandmark,
  saveHistoricalLandmark,
  updateHistoricalLandmark,
};
