<template>
  <p class="text-sm text-neutral-500" v-bind="$attrs">
    {{ val }}
    <!-- @slot Slot for optional badge to be displayed under the `value` -->
    <slot name="subBadge"></slot>
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** Standardized styling for the subheader on the list card.
 */
export default defineComponent({
  name: "ListCardSubHeader",
  props: {
    /** Value to be displayed */
    val: {
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
