<template>
  <ReadFieldBase v-bind="baseProps">
    {{ parsedValue }}
  </ReadFieldBase>
</template>

<script lang="ts">
import {
  getLookupDescription,
  getLookupLongDescription,
  getLookupLongDescriptionWithFallback,
} from "@/components/lookup/helpers.js";
import { LookupFormat, readValueFormatter } from "@/components/read/read.js";
import ReadFieldBase from "@/components/read/ReadFieldBase.vue";
import { computedAsync } from "@vueuse/core";
import { computed, defineComponent, PropType } from "vue";
/** Component to parse and display value.
 * Most common component used inside the `ReadCard`.
 */
export default defineComponent({
  name: "ReadField",
  components: {
    ReadFieldBase,
  },
  props: {
    /** Label for the item to be displayed */
    label: {
      type: String,
      required: true,
    },
    /** Controls the column span for the item
     * @values 1, 2
     */
    span: {
      type: Number,
      default: 1,
    },
    /** Determines whether to useMonoFont */

    useMonoFont: {
      type: Boolean,
      default: false,
    },
    /** Allows the item to use multiple lines (commonly used for descriptions, comments, and other long values) */
    multiline: {
      type: Boolean,
      default: false,
    },
    /** Rendering strategy for a Lookup value. If provided, the value will be treated as a Lookup */
    lookupFormat: {
      type: String as PropType<LookupFormat>,
      default: undefined,
    },
    /** Value to be parsed and then displayed */
    val: {
      required: true,
    },
  },
  setup(props, context) {
    const { val, ...baseProps } = props;

    const parsedValue = computedAsync(async () => {
      return await readValueFormatter(
        { rawValue: props.val, lookupFormat: props.lookupFormat },
        { isNullVisible: true, joinAsNewlines: props.multiline },
      );
    });

    return { parsedValue, baseProps };
  },
});
</script>

<style module></style>
