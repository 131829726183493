import { NotifyOptions, NotifyOptionsSuccess, useNotifier } from "@/components/notifications/index.js";
import type { PagingApiAndNotifierConfig } from "@/utils/api/index.js";

const { notifySuccess, notifyInfo } = useNotifier();

/**
 * Accepts a value to be written to the users clipboard when called.
 *
 * @param {String} value Value to be copied to clipboard
 * @param {String} successMessage Message to be displayed upon successful copy
 * @param {String} errorMessage Message to be displayed upon unsucessful copy
 * @param {PagingApiAndNotifierConfig} [config]
 */
export async function copyToClipboard(
  value: string,
  successMessage: string,
  errorMessage: string,
  config: PagingApiAndNotifierConfig = {},
) {
  try {
    await navigator.clipboard.writeText(value);
    const successNotifCb = (options: NotifyOptionsSuccess) => {
      options.title = successMessage;
      config?.successNotifCb?.(options);
    };
    notifySuccess(successNotifCb);
  } catch {
    const infoNotifCb = (options: NotifyOptions) => {
      options.title = errorMessage;
      config?.infoNotifCb?.(options);
    };
    notifyInfo(infoNotifCb);
  }
}
