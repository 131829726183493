<template>
  <li class="pl-0.5" v-if="hasValue || label">
    <span class="text-neutral-500" v-if="label">{{ label }}: </span>
    <span class="text-neutral-900">
      <template v-if="isEmail">
        <!-- prettier-ignore -->
        <EmailWbr :val="(val as string | string[] | null | undefined)"></EmailWbr>
      </template>
      <template v-else>
        {{ parsedValue }}
      </template>
    </span>
  </li>
</template>

<script lang="ts">
import { EmailWbr, LookupFormat, readValueFormatter } from "@/components/read";
import ReadFieldBase from "@/components/read/ReadFieldBase.vue";
import injectionSymbols from "@/components/read/injectionSymbols";
import { computedAsync } from "@vueuse/core";
import { computed, defineComponent, inject, onUnmounted, PropType, watch } from "vue";

/** This component will properly style and parse the list items for ReadFieldList.  */
export default defineComponent({
  name: "ReadFieldListItem",
  components: {
    ReadFieldBase,
    EmailWbr,
  },
  props: {
    /** Optional label for the list item. Typically only used if the value is not self explanatory (E.g. a list with multiple phone numbers that should be distinguished) */
    label: {
      type: String,
    },
    /** The value of the item to be displayed */
    val: {
      required: true,
    },
    /** Renders the `val` as an email - adds a <wbr> breakpoint at the @ symbol */
    isEmail: {
      type: Boolean,
      default: false,
    },
    /** Rendering strategy for a Lookup value. If provided, the value will be treated as a Lookup */
    lookupFormat: {
      type: String as PropType<LookupFormat>,
      default: undefined,
    },
  },
  setup(props, context) {
    const parsedValue = computedAsync(async () => {
      return await readValueFormatter(
        { rawValue: props.val, lookupFormat: props.lookupFormat },
        { isNullVisible: true, joinAsNewlines: false },
      );
    });

    const hasValue = computed(() => {
      return (
        props.val !== null &&
        props.val !== undefined &&
        props.val !== "" &&
        (!Array.isArray(props.val) || props.val.filter((item) => item || item === 0 || item === false).length > 0)
      );
    });

    const childUpdateHandler = inject(injectionSymbols.ReadFieldListChildUpdateHandlerKey, () => {});

    watch(
      hasValue,
      (newValue) => {
        childUpdateHandler(newValue);
      },
      { immediate: true },
    );

    onUnmounted(() => {
      childUpdateHandler(false);
    });

    return { parsedValue, hasValue };
  },
});
</script>

<style module></style>
