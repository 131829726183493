import { NotifyOptionsError, NotifyOptionsSuccess } from "@/components/notifications/index.js";
import { useViewerConfigStore } from "@/components/viewer/index.js";
import { PagingApiAndNotifierConfig, apiService } from "@/utils/api/index.js";
import { serializeQueryParams } from "@/utils/helpers/index.js";

import type { Location, LocationQuery } from "@/components/viewer/location.js";

const URL = "locations";
const ENTITY_NAME = "Location";

let api: ReturnType<typeof apiService<Location>>;

const getApi = () => {
  if (api) return api;

  const viewerConfigStore = useViewerConfigStore();
  const axiosParams = {
    // Set different base URL based on the environment
    baseURL: viewerConfigStore.baseURL,
  };
  api = apiService(viewerConfigStore.apiHost, axiosParams);
  return api;
};

/**
 * Returns list of locations in their larger form
 * @param {LocationQuery} params Query parameters
   @param {PagingApiAndNotifierConfig} config 
 */
const getLocations = (params: LocationQuery, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    options.hideStatusCodes = [422]; //TODO: remove this blanket hide once we can address it in debouncedSearch
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return getApi().getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
   @param {PagingApiAndNotifierConfig} config 
 */
const getLocationById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return getApi().get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * Returns list of locations in their smaller form (ideal for pins because of minimal data)
 * @param {LocationQuery} params Query parameters
   @param {PagingApiAndNotifierConfig} config 
 */
const getPinLocations = (params: LocationQuery, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL + "/pins";

  return getApi().getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

export { getLocationById, getLocations, getPinLocations };
