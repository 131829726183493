import { EntityDocuments } from "@/components/entity/document";
import { EntityNotes } from "@/components/entity/note";
import EntityAudits from "@casa/components/EntityAudits.vue";
import { RouterView } from "vue-router";

/** @type {import('vue-router').RouterOptions['routes']} */

const names = {
  historicalLandmarks: "HistoricalLandmarks",
  historicalLandmarkList: "HistoricalLandmarkList",
  historicalLandmarkNew: "HistoricalLandmarkNew",
  historicalLandmarkBase: "HistoricalLandmarkBase",
  historicalLandmarkView: "HistoricalLandmarkView",
  historicalLandmarkEdit: "HistoricalLandmarkEdit",
  historicalLandmarkNotes: "HistoricalLandmarkNotes",
  historicalLandmarkNoteEdit: "HistoricalLandmarkNoteEdit",
  historicalLandmarkNoteNew: "HistoricalLandmarkNoteNew",
  historicalLandmarkDocuments: "HistoricalLandmarkDocuments",
  historicalLandmarkDocumentEdit: "HistoricalLandmarkDocumentEdit",
  historicalLandmarkDocumentNew: "HistoricalLandmarkDocumentNew",
  historicalLandmarkAudit: "HistoricalLandmarkAudit",
};

export { names as historicalLandmarkRouteNames };

const historicalLandmarkRoutes = [
  {
    path: "/historicalLandmarks",
    name: names.historicalLandmarks,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.historicalLandmarkList },
    children: [
      {
        path: "list",
        name: names.historicalLandmarkList,
        meta: { title: "Historical Landmarks" },
        component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkList.vue"),
      },
      {
        path: "new",
        component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkBase.vue"),
        redirect: { name: names.historicalLandmarkNew },
        props: true,
        children: [
          {
            path: "",
            name: names.historicalLandmarkNew,
            meta: { title: "New Historical Landmark", isNew: true },
            component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.historicalLandmarkBase,
        component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkBase.vue"),
        redirect: { name: names.historicalLandmarkView },
        children: [
          {
            path: "view",
            name: names.historicalLandmarkView,
            meta: { title: "Historical Landmark View" },
            component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkView.vue"),
          },
          {
            path: "edit",
            name: names.historicalLandmarkEdit,
            meta: { title: "Historical Landmark Edit", isForm: true },
            component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkEdit.vue"),
          },
          {
            path: "notes",
            name: names.historicalLandmarkNotes,
            meta: { title: "Historical Landmark Site Internal Notes" },
            component: {
              render: () => <EntityNotes idField="historicalLandmarkId" routeNamePrefix="HistoricalLandmarkNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.historicalLandmarkNoteEdit,
            meta: { title: "Edit Historical Landmark Site Internal Note", isForm: true },
            component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkNote.vue"),
          },
          {
            path: "notes/new",
            name: names.historicalLandmarkNoteNew,
            meta: { title: "Create Historical Landmark Site Internal Note", isForm: true },
            component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkNote.vue"),
          },
          {
            path: "documents",
            name: names.historicalLandmarkDocuments,
            meta: { title: "Historical Landmark Documents" },
            component: {
              render: () => (
                <EntityDocuments idField="historicalLandmarkId" routeNamePrefix="HistoricalLandmarkDocument" />
              ),
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.historicalLandmarkDocumentEdit,
            meta: { title: "Edit Historical Landmark Document", isForm: true },
            component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.historicalLandmarkDocumentNew,
            meta: { title: "Create Historical Landmark Document", isForm: true },
            component: () => import("@casa/views/historicalLandmark/HistoricalLandmarkDocument.vue"),
          },
          {
            path: "activity",
            name: names.historicalLandmarkAudit,
            meta: { title: "Historical Landmark Site Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="HistoricalLandmark" />,
            },
          },
        ],
      },
    ],
  },
];
export default historicalLandmarkRoutes;
