import type { SomeOptional } from "@/types/helpers";
import type { Paging } from "@/utils/api";
import Feature from "ol/Feature";
import Geometry from "ol/geom/Geometry";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import type LayerGroup from "ol/layer/Group";
import type VectorLayer from "ol/layer/Vector";
import type Icon from "ol/style/Icon";
import type Style from "ol/style/Style";
import type { ComputedRef, Ref } from "vue";
import type { Location } from "@/components/viewer/location";

/** Generates a map pin svg using the color and options provided
 * @param {ViewerColor} viewerColor Desired color theme for the pin
 * @param {Object} options - Additional options to modify pin style, including hover and selection states.
 * @param {boolean} [options.isHover] - Optional flag indicating if the pin is hovered.
 * @param {boolean} [options.isSelected] - Optional flag indicating if the pin is selected.
 * @returns {string} svg markup string
 */
export function getMapPin(viewerColor: ViewerColor, options: { isHover?: boolean; isSelected?: boolean } = {}) {
  let weight = "";
  if (viewerColor.foreground === "rgb(0,0,0)") weight = 'font-weight="bold"';
  let transformStyle = "";
  if (options?.isHover || options?.isSelected) transformStyle = "transform: scale(1.5);";

  return `<svg
    version="1.1"
    viewBox="0 0 14 21"
    width="15"
    height="21"
    fill="${viewerColor.background}"
    stroke="${viewerColor.foreground}"
    xmlns="http://www.w3.org/2000/svg"
    style="${transformStyle}"
  >
    <path
      d="m6.9997 0.52185c-3.584 0-6.4779 2.8044-6.4779 6.2635 0 1.0877 0.28708 2.111 0.79034 3.0027l5.6878 9.2182 5.6878-9.2182c0.50325-0.89165 0.79034-1.915 0.79034-3.0027 0-3.4592-2.8939-6.2635-6.4779-6.2635z"
      stroke-width="1.0437"
    />
  </svg>`;
}

/** * Enum representing the various states of the viewers process. Helps indicate if the next step can execute or if it needs to wait on a previous one
 @enum {number}
  */
export enum DATA_STATUS {
  noMeta = 1,
  // hasMeta = 2,
  hasDatasets = 3,
  hasSearch = 4,
  hasLayers = 5,
}

/** Creates an OpenLayers feature from an Location object and geometry.
 * @template T - The type of geometry (Point, LineString, or Polygon).
 * @param {Location} location - The location object containing necessary properties for the feature (title, id, and datasetIdentifier).
 * @param {T} geometry - The geometry object defining the shape of the feature (e.g., Point, LineString, or Polygon).
 * @returns {Feature<T>} The created feature with the specified geometry and location */
export function featureFromLocation<T extends Point | LineString | Polygon>(
  location: Location,
  geometry: T,
): Feature<T> {
  return new Feature({
    geometry: geometry,
    name: location.title,
    id: location.id,
    datasetIdentifier: location.datasetIdentifier,
  }) as Feature<T>;
}

// [
//   {
//     "id": 8,
//     "name": "Hawaii",
//     "county": "Hawaii",
//     "geometry": "POLYGON ((-155.91051601038833 20.337509328030443, -155.94896815882248 20.157128946813405, -155.88854335413643 19.997189133166014, -155.93248866663129 19.924904873464254, -156.02037929163004 19.878418950669296, -156.09728358850728 19.800912113008422, -156.14672206507186 19.733708966103165, -156.10826991663777 19.625090316862519, -156.03685878382123 19.562989507084794, -155.99291347132635 19.392089094481705, -155.94347499476177 19.30397930285644, -155.97643397913518 19.169131363249029, -155.92699550257052 18.992625243924905, -155.81713222131989 18.919891715378345, -155.66881679163509 18.852325086702205, -155.56993983850586 18.883513071573454, -155.49852870569836 19.013400422231417, -155.44909022913376 19.070518772498371, -155.41063808069961 19.106856571073472, -155.25133632288433 19.20544746729577, -155.15795253382487 19.2313826342687, -154.9656917916362 19.278055612924035, -154.76244472132606 19.521575670060393, -154.74596522913487 19.619916164846444, -154.9272396431931 19.702682583540327, -155.13048671351223 20.002351026437353, -155.50951503381981 20.157128946813405, -155.74022792444262 20.218997237461441, -155.71276210413893 20.27568823264415, -155.7896664010072 20.337509328030443, -155.91051601038833 20.337509328030443))",
//     "countyCode": "HA"
//   },
//   {
//     "id": 2,
//     "name": "Kauai",
//     "county": "Kauai",
//     "geometry": "POLYGON ((-159.74337123500257 22.158755902950553, -159.76671718226518 22.116778168287709, -159.79280971156717 22.076060546051515, -159.80242274866893 22.039150060564058, -159.78594325648675 21.993316753962066, -159.75298427211334 21.960205707579682, -159.63900111781265 21.923265051918136, -159.59780238734814 21.883766162401468, -159.54561732875317 21.876119951881606, -159.4700863228866 21.8684733316361, -159.41790126430061 21.859551756987315, -159.38219569788785 21.890137691433676, -159.33001063929288 21.918169036324134, -159.3039181099999 21.9538373136259, -159.27919887171757 22.14222071329549, -159.29018519984805 22.181647572748581, -159.32589076625183 22.21216399888953, -159.35610316859487 22.240131560610269, -159.44536708461328 22.247758109039076, -159.50167201624933 22.252842243974712, -159.5263912545316 22.240131560610269, -159.55385707484427 22.245215972362029, -159.58269618617663 22.247758109039076, -159.60878871546961 22.233775786468385, -159.74337123500257 22.158755902950553))",
//     "countyCode": "KA"
//   },
//   {
//     "id": 5,
//     "name": "Lanai",
//     "county": "Maui",
//     "geometry": "POLYGON ((-157.0846798287526 20.915871793780543, -157.06820033656143 20.86711751745187, -157.02013515101649 20.836317190063326, -157.00228236781462 20.755436362725469, -156.98030971156268 20.725897646713957, -156.9363643990678 20.716906458435307, -156.85946010219052 20.719475423835686, -156.80727504359558 20.765709346526634, -156.77568935023285 20.828616123104961, -156.82238124476709 20.888930598443835, -156.86357997524058 20.924851116782428, -156.92537807093731 20.950503360033139, -156.98580287562342 20.959480606718298, -157.0530941353899 20.938960394376227, -157.0846798287526 20.915871793780543))",
//     "countyCode": "MA"
//   },
//   {
//     "id": 6,
//     "name": "Maui",
//     "county": "Maui",
//     "geometry": "POLYGON ((-156.5834286080532 21.040251560087107, -156.62600062953737 21.037688074920656, -156.66582606898217 21.005640789630512, -156.69466518031453 20.981280248148011, -156.70290492640564 20.950503360033139, -156.70290492640564 20.897911534422835, -156.6877987252341 20.870967114829735, -156.66170619594112 20.837600662928359, -156.63149379359805 20.809361733066059, -156.58892177211391 20.792672605559087, -156.51751063929737 20.765709346526634, -156.50103114711519 20.787537117943096, -156.46944545375246 20.782401455584264, -156.47081874476314 20.76827748336752, -156.46944545375246 20.746446929239863, -156.46257899867206 20.722044345667467, -156.45983241664169 20.637246945167334, -156.45983241664169 20.61796820752615, -156.40215419398595 20.565260542913666, -156.31289027796757 20.574260701783391, -156.27443812953345 20.570403555805147, -156.22911952601888 20.588402736388197, -156.19753383265618 20.610256028917714, -156.14946864711123 20.614112167031642, -156.10689662562706 20.612826798506578, -156.08904384242516 20.628250504808342, -156.03823207484987 20.642387529394917, -155.9887935982853 20.691214409132957, -155.96819423305303 20.729750849706239, -155.95720790492254 20.758004674132561, -155.96956752406373 20.788821006230197, -156.01488612757831 20.810645435604467, -156.04372523891061 20.824765441941295, -156.09728358851629 20.842734444952065, -156.1192562447682 20.853001483597978, -156.12612269984859 20.87609975793022, -156.16045497523271 20.885081461396986, -156.18242763148464 20.900477417420209, -156.19753383265618 20.918437369528942, -156.21126674281697 20.94665580371575, -156.24971889125112 20.973586620063308, -156.28267787562453 20.969739657497538, -156.37743495570368 20.960763026554829, -156.40902064906641 20.950503360033139, -156.42824672327896 20.941525574773983, -156.45708583461132 20.931264589528869, -156.46944545375246 20.929981916943174, -156.5834286080532 21.040251560087107))",
//     "countyCode": "MA"
//   },
//   {
//     "id": 4,
//     "name": "Molokai",
//     "county": "Maui",
//     "geometry": "POLYGON ((-157.26458095180013 21.232386903219858, -157.26458095180013 21.206783262101208, -157.25908778773939 21.1875776171623, -157.27419398891089 21.167088845052813, -157.30852626429498 21.145316415791875, -157.32363246546649 21.09279327713795, -157.26046107875007 21.078698540923529, -157.13274501429751 21.079979935819889, -157.07918666469186 21.079979935819889, -156.96932338344118 21.059476292258744, -156.88967250453356 21.03512454564013, -156.8484737740601 21.023588118083847, -156.73723720179871 21.074854289959017, -156.68917201625382 21.16580820256199, -156.7125179635164 21.173491891239824, -156.72350429164689 21.1875776171623, -156.80864833461524 21.193979776205921, -156.8525936471101 21.185016675894897, -156.90615199672476 21.186297152074054, -156.92812465296774 21.195260174732322, -156.95009730921964 21.220865814465018, -156.97069667445189 21.231106826684631, -157.00228236781462 21.22854664028333, -157.01738856898612 21.201662000917842, -157.0530941353899 21.199101303734448, -157.12587855921711 21.208063549646912, -157.18081019984245 21.218305450335251, -157.200036274064 21.225986409443411, -157.22750209437669 21.232386903219858, -157.2329952584374 21.229826739039503, -157.26458095180013 21.232386903219858))",
//     "countyCode": "MA"
//   },
//   {
//     "id": 3,
//     "name": "Oahu",
//     "county": "Honolulu",
//     "geometry": "POLYGON ((-158.29317592250095 21.581430390678548, -158.2396175728953 21.534173042293091, -158.23275111781487 21.472843289307331, -158.19429896938072 21.4421687212466, -158.1833126412503 21.391030108962248, -158.1489803658572 21.376963853596038, -158.11464809047311 21.278462254142617, -157.97045253382936 21.302773986226583, -157.94024013148632 21.296376551779986, -157.89080165492175 21.29509703147388, -157.8358700142964 21.261825595707474, -157.81389735804444 21.24262711544646, -157.76995204554959 21.24262711544646, -157.74111293421726 21.265664991477305, -157.71227382289388 21.266944767816653, -157.70952724086354 21.252866616273089, -157.67931483852047 21.2567062456182, -157.67244838344007 21.27078403007868, -157.63536952601666 21.302773986226583, -157.64772914515777 21.343710968280533, -157.69854091273305 21.357780417798931, -157.69304774867234 21.394866125710163, -157.7191402779743 21.412766206551378, -157.72875331507612 21.4242722435633, -157.71227382289388 21.462619150539087, -157.75209926234771 21.474121256201833, -157.79741786586226 21.469009321350853, -157.82351039515527 21.497122742293758, -157.81389735804444 21.538005292719877, -157.829003559225 21.56355104296361, -157.94298671351669 21.728212818727496, -157.98967860805092 21.739693833273972, -158.0349972115655 21.733315605079525, -158.08580897914078 21.687384010603626, -158.12151454554453 21.628672326346276, -158.1558468209376 21.613352307895784, -158.2231380807041 21.612075566412887, -158.28493617640984 21.598030666313434, -158.29866908656169 21.583984403109426, -158.29317592250095 21.580153367574848, -158.29317592250095 21.581430390678548))",
//     "countyCode": "OA"
//   }
// ]

/** Represents an option on the Zoom To... tools menu.
 * WKT is parsed using the project
 */
export interface ZoomToOptionProp {
  /** One or more points or polygons expressed as WKT that will be used to determine a centroid to move to and whose extent will be zoomed to. */
  wkt: string;
  /** Label used on the Zoom To tools menu */
  label: string;
  /** Optional minimum zoom level will override the default behavior, which is to zoom to the extent of the features defined by the WKT */
  minZoom?: number;
  /** Optional maximum zoom level will override the default behavior, which is to zoom to the extent of the features defined by the WKT */
  maxZoom?: number;
  /** Optionally specify whether the provided `center` Coordinate requires conversion from Long/Lat to OL Coordinate */
  // isLonLat?: boolean;
}

/** Internal representation of an option on the Zoom To... tools menu. */
export interface ZoomToOption {
  /** An OL Geometry converted from the user WKT */
  geometry: Geometry;
  /** Label used on the Zoom To tools menu */
  label: string;
  /** Optional minimum zoom level will override the default behavior, which is to zoom to the extent of the features defined by the WKT */
  minZoom?: number;
  /** Optional maximum zoom level will override the default behavior, which is to zoom to the extent of the features defined by the WKT */
  maxZoom?: number;
}

export interface ViewerColor {
  /** Color used for the text */
  foreground: string;
  /** Color used for the pin */
  background: string;
  /** Background color with opacity added */
  fill: string;
  /** Flag generally indicating whether the foreground is black */
  isDarkForeground: boolean;
}

export interface ViewerResult {
  id: number;
  incidentId: number; // TODO: Replace with entityId
  siteId: null; // TODO: Replace with entityId
  facilityId: null; // TODO: Replace with entityId
  locationType: string;
  description: string | null;
  comment: null;
  geometryWkt: string;
  centroidWkt: string;
  isPublic: boolean;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
  subTitle: string;
  title: string;
  summary: string;
  recordDate: string;
  viewerDataset?: number;
  viewerDatasetId?: number;
  // datasetNumber?: number;
  datasetIdentifier?: number;
}

/** The different source types we have support and logic for in the viewerStore.
 * NOTE: google is currently unsupported.
 */
export type SourceType = "osm" | "arcgis" | "google";

/** A LayerSourceValue wrapped in a formkit friendly format for use in the dropdown */
export interface LayerSource {
  label: string;
  value: number;
  source: LayerSourceValue;
}

/** Required properties to create layers on the map.  */
export interface LayerSourceValue {
  id: number;
  url: string;
  refUrl: string | null;
  type: SourceType;
  attribution: string;
  thumbnail?: string;
  layerId?: number;
  minZoom?: number;
  maxZoom: number;
  active?: boolean;
  color?: string;
}

/**
 * Supported color combinations for Viewer geometry. There are 12 variations.
 */
export const viewerColors: ViewerColor[] = [
  // The first set have dark backgrounds and white text.
  {
    foreground: "rgb(255,255,255)",
    background: "rgb(31,120,180)",
    fill: "rgba(31,120,180,.2)",
    isDarkForeground: false,
  }, //"#1f78b4"
  { foreground: "rgb(255,255,255)", background: "rgb(51,160,44)", fill: "rgba(51,160,44,.2)", isDarkForeground: false }, //"#33a02c"
  { foreground: "rgb(255,255,255)", background: "rgb(227,26,28)", fill: "rgba(227,26,28,.1)", isDarkForeground: false }, //"#e31a1c"
  { foreground: "rgb(255,255,255)", background: "rgb(255,127,0)", fill: "rgba(255,127,0,.2)", isDarkForeground: false }, //"#ff7f00"
  {
    foreground: "rgb(255,255,255)",
    background: "rgb(106,61,154)",
    fill: "rgba(106,61,154,.15)",
    isDarkForeground: false,
  }, //"#6a3d9a"
  { foreground: "rgb(255,255,255)", background: "rgb(177,89,40)", fill: "rgba(177,89,40,.2)", isDarkForeground: false }, //"#b15928"
  // The second set have light backgrounds and black text.
  { foreground: "rgb(0,0,0)", background: "rgb(166,206,227)", fill: "rgba(166,206,227,.5)", isDarkForeground: true }, //"#a6cee3" -- may need help with this one. at .7 it still barely shows up on water but its overpowering the map
  { foreground: "rgb(0,0,0)", background: "rgb(178,223,138)", fill: "rgba(178,223,138,.3)", isDarkForeground: true }, // "#b2df8a" -- very very similary to the green on the map
  { foreground: "rgb(0,0,0)", background: "rgb(251,154,153)", fill: "rgba(251,154,153,.2)", isDarkForeground: true }, //"#fb9a99"
  { foreground: "rgb(0,0,0)", background: "rgb(253,191,111)", fill: "rgba(253,191,111,.3)", isDarkForeground: true }, //"#fdbf6f"
  { foreground: "rgb(0,0,0)", background: "rgb(202,178,214)", fill: "rgba(202,178,214,.45)", isDarkForeground: true }, //"#cab2d6"
  { foreground: "rgb(0,0,0)", background: "rgb(255,255,153)", fill: "rgba(255,255,153,.25)", isDarkForeground: true }, //"#ffff99"
];

export interface ViewerDatasetGroup {
  /**
   * All Datasets within a group share a common color.
   */
  colors: ViewerColor;
}

export interface ViewerDataset {
  /** Number that will match back to a Locations 'DatasetNumber' property. This matches a location to a group and its data.  */
  id: number;
  /**
   * Human readable string used in the UI to identify this dataset
   */
  label: string;
  /**
   * Long form description used in the UI to describe this dataset
   */
  description: string;
  /**
   * Controls whether this dataset's data is included on the map and in the list.
   */
  isVisible: boolean;
  /** All datasets must be a part of a group. The group defines the map feature colors */
  datasetGroup: ViewerDatasetGroup;

  /** The style used by features in this dataset for fill and stroke (polygons and linestrings)*/
  fillAndStroke: Style;

  /** Pin icon used by features in this dataset */
  pinIcon: Icon;
  /** Locations belonging to this dataset */
  results: Location[];

  /** Layer containing all of the points for this dataset*/
  layer?: VectorLayer;

  /** Layer containing all of the linestrings for this dataset*/
  lineLayer?: VectorLayer;

  /** Layer containing all of the polygons for this dataset */
  polyLayer?: VectorLayer;

  //todo - source, entity ( meta)
}

/** A very minimal version of the ViewerDataset. Used for inital set up. */
export interface ViewerDatasetProp
  extends SomeOptional<
    Omit<ViewerDataset, "fillAndStroke">,
    "results" | "layer" | "lineLayer" | "polyLayer" | "isVisible" | "pinIcon" | "description"
  > {}

/** An extension of the ViewerDatasetProp designed to support metadata requirements */
export interface DatasetMetadata extends Omit<ViewerDatasetProp, "datasetGroup"> {
  entity?: string;
  sourceSystem?: string;
  subCategory?: string;
  description?: string;
}

/** The required format for fetching locations with datasets */
export interface DatasetPayload {
  identifier?: number;
  entity?: string;
  sourceSystem?: string;
  subCategory?: string;
}

/** Defines items that the Viewer component provides to its children components */
export type ViewerListProps = {
  baseLayerGroup: LayerGroup;
  filterChangeHandler: (filterObj: ListFilterViewer) => void;
  isDefaultState: ComputedRef<boolean>;
  debouncedSearch: (isPaged?: boolean) => Promise<any> | undefined;
  // dataRef: Ref<Paging<Location> | undefined>;
  dataRef: Ref<Paging<Location> | undefined>;
  isBouncingRef: Ref<boolean>;
  isStatusPending: ComputedRef<boolean>;
  resultsCount: ComputedRef<string>;
  datasetsQueryRef: Ref<number[]>;
};

/** Allows for registering and updating filters - not currently in use */
export type ListFilterLabels = {
  registerAndUpdateFilters: (filterObject: ListFilterViewer) => void;
};

/** List filter object for viewer - not currently in use */
export type ViewerListFilter = {
  label: string;
  value: Array<string>;
  field: string;
};

/** Filter object for the viewer */
export type ListFilterViewer = {
  field: string;
  filterValue: string | Array<string> | Array<DatasetPayload>;
  isDefault: boolean;
  isIgnored: boolean;
};

//TODO: change viewer.ts to index
export { default as PinIcon } from "@/components/viewer/PinIcon.vue";
// export * from "@/components/viewer/viewer";
export { default as Viewer } from "@/components/viewer/Viewer.vue";
// export * from "@/components/viewer/viewerConfigStore";
export { useViewerConfigStore } from "@/components/viewer/viewerConfigStore";
export type { UseViewerConfigStore } from "@/components/viewer/viewerConfigStore";
// export * from "@/components/viewer/viewerStore";
export { useViewerStore } from "@/components/viewer/viewerStore";
export type { UseViewerStore } from "@/components/viewer/viewerStore";
