<template>
  <FieldGroup :label="label">
    <!-- TODO - add ability to configure requirements and lengths / make schema based? -->
    <FormKit type="group" :name="fkName">
      <FormKit type="text" label="Street" name="street" :validation="streetValidation" />
      <FieldSet :columns="3">
        <template #1>
          <FormKit type="text" label="City" name="city" :validation="cityValidation" />
        </template>
        <template #2>
          <FormKit
            type="dropdown"
            label="State"
            name="state"
            :options="stateArray"
            :validation="stateValidation"
            :selection-removable="stateSelectionRemovable"
          />
        </template>
        <template #3>
          <FormKit type="text" label="Zip Code" name="zipCode" :validation="zipValidation" />
        </template>
      </FieldSet>
      <FormKit v-if="withCountry" type="text" label="Country" name="country" :validation="countryValidation" />
    </FormKit>
  </FieldGroup>
</template>

<script lang="ts">
import type { FormKitProOptionsProp } from "@formkit/pro"; // Do not remove this line. It's necessary for this component to build.
import { PropType, computed, defineComponent } from "vue";
/**
 * Component to add an address section with all the fields necessary to a form.
 * Validates that if one of the address fields are filled out, they all must be.
 */
export default defineComponent({
  name: "AddressInput",
  props: {
    /**
     * Label to go above the entire address section
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Name that distinguishes the address for that entity (E.g. `mailAddress` or `locationAddress`)
     */
    fkName: {
      type: String,
      default: () => "address",
    },
    /**
     * Should be a FormKit compatible array of desired states to chose from
     */
    stateArray: {
      type: Array as PropType<any[]>,
      default: null,
    },
    /**
     * If provided as `true`, this will add the country field to the address. Otherwise, it will be left off.
     */
    withCountry: {
      type: Boolean,
      default: false,
    },
    /** Allows ability to disable selection-removeable feature for state */
    stateSelectionRemovable: {
      type: Boolean,
      default: true,
    },
    /** Controls the validation for the address - allows either all fields or none be filled in, or simply requires all fields
     * @values "all_or_none_in_group_required", "required"
     */
    validation: {
      type: String,
      default: "all_or_none_in_group_required",
    },

    //TODO - Instead of max length, we could change this to allow specification for
    // min as well. Since this will likely become schema, it may make sense to wait.

    /** Allows customization of the max length for the `Street` field.
     * @default 100
     */
    streetLength: {
      type: Number,
      default: 100,
    },
    /** Allows customization of the max length for the `City` field.
     * @default 25
     */
    cityLength: {
      type: Number,
      default: 25,
    },
    /** Allows customization of the max length for the `ZipCode` field.
     * @default 10
     */
    zipCodeLength: {
      type: Number,
      default: 10,
    },
  },
  setup(props, context) {
    const streetValidation = computed(() => `${props.validation}|length:0,${props.streetLength}`);
    const cityValidation = computed(() => `${props.validation}|length:0,${props.cityLength}`);
    const stateValidation = computed(() => `${props.validation}`);
    const zipValidation = computed(() => `${props.validation}|length:0,${props.zipCodeLength}`);
    const countryValidation = computed(() => `${props.validation}`);
    return {
      streetValidation,
      cityValidation,
      stateValidation,
      zipValidation,
      countryValidation,
    };
  },
  components: {},
});
</script>
