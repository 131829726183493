import type { Document, DocumentsResult } from "@/components/entity/document/index.js";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

/** Returns a store for Entity Documents (Documents for the current entity), creates it if necessary. */
export const useEntityDocumentStore = defineStore("entityDocumentStore", () => {
  //TODO: Consider using composition to add paging info?

  // state
  const documents = ref([] as Array<Document>);
  const paging = ref({
    count: 0,
    totalResultsCount: 0,
    href: "",
  });

  // getters

  //   const count = computed(() => documents.value.length);

  // actions
  /** Fills the the store based on the based in result from the API
   *
   * @param {DocumentsResult} docsResult Object used to fill the store
   */
  function fill(docsResult: DocumentsResult) {
    documents.value = docsResult._items || [];
    paging.value.totalResultsCount = docsResult.totalResultsCount;
    paging.value.count = docsResult.count;
    paging.value.href = docsResult.href;
  }

  /** Removes a document from the store and updates the total results count.
   * Used when deleting a document.
   *
   * @param {Document} item Document to remove
   */
  function remove(item: Document) {
    const itemIndex = documents.value.indexOf(item);
    documents.value.splice(itemIndex, 1);
    --paging.value.totalResultsCount;
  }

  return {
    // state
    documents,
    paging,
    // getters
    // count,
    // actions
    fill,
    remove,
  };
});

export type UseEntityDocumentStore = ReturnType<typeof useEntityDocumentStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEntityDocumentStore, import.meta.hot));
}
