import { useApi } from "@/utils/api/useApi.js";
import type { FormKitNode } from "@formkit/core";
import { computed, ref, toValue } from "vue";
import type { Lookup, LookupFiltersProp } from "./lookup.js";
import { getLookupGroupByIdOrCode } from "./lookupGroupApi.js";

/**
 * Primarily used by LookupDropdown, it allows a caller to get a list of Lookups filtered based on whether they're active or the initial value.
 * @param groupCode The groupCode used by the methods and objects returned by this composable.
 * @param node Optional FormKitNode - useful when you need to reset the value of a component that uses the options returned by this method.
 * @returns
 */
export function useLookup(groupCode: string, node?: FormKitNode<any>) {
  const initialValue = ref<string | undefined>();
  const isLoading = ref(true);
  const options = ref<{ label: string; value: string }[]>([]);

  const { exec: fetchLookup } = useApi(getLookupGroupByIdOrCode, {
    responseAdapter: (response) => {
      return response.data;
    },
  });

  const filterAndConvertLookups = (
    lookups: Lookup[],
    filters: LookupFiltersProp | undefined,
    currentValue: string | string[] | undefined,
  ) => {
    let isModelValueInLookups = false;
    const isCurrentValueArray = Array.isArray(currentValue);
    const isCurrentValueArrayInLookups = isCurrentValueArray ? currentValue.map((x) => false) : [];
    const lookupResult = new Array<{ label: string; value: string; sortWeight: number }>();

    lookups.forEach((lookup) => {
      let isIncluded;

      if (!lookup.isActive && lookup.murn !== initialValue.value) {
        // Only include Active + InitialValue (i.e. current)
        isIncluded = false;
      } else if (!lookup.metadata?.filtersOn || !filters) {
        // If there are no other filters, include it.
        isIncluded = true;
      } else {
        // Otherwise include based on the props.filters
        isIncluded = Object.entries(lookup.metadata.filtersOn).every(([key, metadata]) => {
          const filterValue = toValue(toValue(filters)?.[key]);
          if (!filterValue || filterValue.length === 0) return true;

          const filterValues = Array.isArray(filterValue) ? filterValue : [filterValue];
          return filterValues.some((value) => metadata.values.includes(value));
        });
      }

      if (isIncluded) {
        lookupResult.push({
          label: lookup.description,
          value: lookup.murn,
          sortWeight: lookup.sortWeight,
        });

        if (isCurrentValueArray) {
          const index = currentValue.indexOf(lookup.murn);
          if (index > -1) isCurrentValueArrayInLookups[index] = true;
        } else if (lookup.murn === currentValue) {
          isModelValueInLookups = true;
        }
      }
    });

    // If the current value is no longer in the lookup, it needs to be reset
    if (isCurrentValueArray) {
      if (currentValue?.length > 0 && isCurrentValueArrayInLookups.some((x) => !x) && node) {
        node.input([]);
      }
    } else if (currentValue && !isModelValueInLookups && node) {
      node.input(undefined);
    }

    lookupResult.sort((a, b) => a.sortWeight - b.sortWeight);

    return lookupResult;
  };

  const loadOptions = async (filters: LookupFiltersProp | undefined, currentValue: string | string[] | undefined) => {
    options.value = [];
    isLoading.value = true;
    try {
      const group = await fetchLookup(groupCode);
      if (!group) {
        options.value = [];
        return options.value;
      }

      const lookups = group.lookups._items;
      options.value = filterAndConvertLookups(lookups, filters, currentValue);
    } catch (error) {
      console.error("Failed to load options:", error);
      options.value = [];
    } finally {
      isLoading.value = false;
    }
    return options.value;
  };

  return {
    /** The initialValue may not be available when calling useLookup. This allows the caller to specify it later. */
    initialValue,
    /** Boolean indicating whether the `options` are being fetched or filtered. */
    isLoading,
    /** A FormKit friendly sorted array of Lookups. Filtered where `isActive` is `true` or the Lookup's Murn equals the initialValue. */
    options,
    /**
     * Hydrates the `options` list.
     * @param filters Additional filters to apply beyond isActive and whether it matches the `initialValue`
     * @param currentValue If `node` was specified when calling `useLookup`, this will check to see if  `currentValue` is included in the filtered list. If not, the node's value will be set to `[]` for arrays and `undefined` for all other cases.
     * @returns
     */
    loadOptions,
    /**
     * Filters, converts, and sorts a Lookup array.
     * Includes Lookups matching the `initialValue` specified in `useLookup`, or where `isActive` = `true`.
     * Sorts using the `Lookup.sortWeight` property.
     * @param lookups The array of Lookup objects to filter
     * @param filters Additional filters to apply beyond isActive and whether it matches the `initialValue`
     * @param currentValue If `node` was specified when calling `useLookup`, this will check to see if  `currentValue` is included in the filtered list. If not, the node's value will be set to `[]` for arrays and `undefined` for all other cases.
     * @returns
     */
    filterAndConvertLookups,
  };
}
