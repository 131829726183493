<template>
  <div v-if="isLoading"><SkeletonButton /></div>
  <div class="inline-flex rounded-md shadow" v-if="primaryAction">
    <button
      @click="internalHandler(primaryAction.handler)"
      :disabled="isDisabled"
      type="button"
      class="inline-flex group/button min-w-24 items-center justify-center text-sm font-medium transition-colors duration-150 ease-in disabled:cursor-not-allowed py-2 px-4 border focus:z-10"
      :class="[hasDropdown ? `rounded-l-md` : `rounded-md`, variants[variant]]"
    >
      {{ primaryAction.label }}
    </button>
    <Menu as="div" class="relative block" v-if="hasDropdown">
      <MenuButton
        :disabled="isDisabled"
        class="relative inline-flex items-center rounded-r-md px-2 py-2 border focus:z-10"
        :class="[variants[variant], dropdownVariants[variant]]"
      >
        <span class="sr-only">Open actions</span>
        <ChevronDownIcon class="size-5" aria-hidden="true" />
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
        >
          <div class="py-1">
            <template v-for="(item, index) in items" :key="item.label">
              <div
                v-if="index > 1 && item.groupId !== items?.[index - 1].groupId"
                class="border-t-neutral-300 border-t my-1.5"
              ></div>
              <MenuItem v-slot="{ active }">
                <div
                  v-show="index !== 0"
                  @click="internalHandler(item.handler)"
                  class="block px-4 py-2 text-sm"
                  :class="[
                    active && item.variant === 'danger' ? 'bg-red-100 text-red-900' : '',
                    active && item.variant === 'warn' ? 'bg-yellow-100 text-yellow-900' : '',
                    active && (!item.variant || !['danger', 'warn'].includes(item.variant))
                      ? 'bg-gray-100 text-gray-900 outline-none'
                      : '',
                    !active ? 'text-gray-700' : '',
                  ]"
                >
                  {{ item.label }}
                </div>
              </MenuItem>
            </template>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script lang="ts">
import { ActionsButtonItem, ActionsButtonItemsProp, ActionsButtonVariants } from "@/components/button/button.js";
import { SkeletonButton } from "@/components/common/index.js";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { PropType, computed, defineComponent, ref } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";

const variants: { [key: string]: string } = {
  base: ``,
  create: `text-white border-transparent bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  plain: `border-neutral-300 text-neutral-700 bg-white hover:bg-neutral-200 hover:text-neutral-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  // "plain-subtle": `border-neutral-300 text-neutral-700 bg-inherit hover:text-white hover:bg-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  priority: `text-white border-transparent bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  // "priority-subtle": `border-neutral-300 text-neutral-700 bg-inherit hover:text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  warn: `text-white border-transparent bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  danger: `text-white border-transparent bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  // "danger-subtle": `border-neutral-300 text-neutral-700 bg-inherit hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
};

const dropdownVariants: { [key: string]: string } = {
  base: ``,
  create: `border-l border-l-white `,
  plain: `border-l border-l-neutral-300`,
  // "plain-subtle": `border-l border-l-neutral-300`,
  priority: `border-l border-l-white`,
  // "priority-subtle": `border-l border-l-white`,
  warn: `border-l border-l-white`,
  danger: `border-l border-l-white`,
};

/**
 * A split button featuring a Primary Action button and a dropdown button for access to additional actions.
 */
export default defineComponent({
  name: "ActionsButton",
  //inheritAttrs: false,
  props: {
    /** The first item in the array is the Primary Action. Others will be shown in the dropdown ordered by index. */
    items: {
      type: Array as PropType<ActionsButtonItemsProp>,
    },
    /**
     * Optional styling defaults to "priority".
     * @values base, create, plain, priority, warn, danger
     */
    variant: {
      type: String as PropType<ActionsButtonVariants>,
      default: "priority",
    },
  },
  setup(props) {
    const router = useRouter();

    const primaryAction = computed(() => props.items?.[0]);
    const hasDropdown = computed(() => (props.items?.length ?? 0) > 1);
    const isLoading = computed(() => props.items === undefined);
    const isDisabled = ref(false);

    const internalHandler = async (handler: ActionsButtonItem["handler"]) => {
      isDisabled.value = true;
      await handler();
      isDisabled.value = false;
    };

    return { primaryAction, hasDropdown, isDisabled, isLoading, internalHandler, variants, dropdownVariants };
  },
  components: {
    ChevronDownIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    SkeletonButton,
  },
});
</script>

<style module></style>
