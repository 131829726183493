import { App } from "vue";

import * as badge from "@/components/badge/index.js";
import * as button from "@/components/button/index.js";
import * as cards from "@/components/cards/index.js";
import * as common from "@/components/common/index.js";
import * as audit from "@/components/entity/audit/index.js";
import * as document from "@/components/entity/document/index.js";
import * as note from "@/components/entity/note/index.js";
import * as fk from "@/components/fk/index.js";
import * as form from "@/components/form/index.js";
import * as layouts from "@/components/layouts/index.js";
import * as list from "@/components/list/genericList/index.js";
import * as listCards from "@/components/list/listCards/index.js";
import * as listTable from "@/components/list/listTable/index.js";
import * as lookup from "@/components/lookup/index.js";
import * as modals from "@/components/modals/index.js";
import * as notifications from "@/components/notifications/index.js";
import * as read from "@/components/read/index.js";
import * as relatedEntities from "@/components/relatedEntities/index.js";
import * as schema from "@/components/schema/index.js";
import * as simpleMap from "@/components/simpleMap/index.js";
import * as viewer from "@/components/viewer/index.js";

//todo
function install(app: App) {
  for (const key in badge) {
    // @ts-expect-error
    app.component(key, badge[key]);
  }
  for (const key in button) {
    // @ts-expect-error
    app.component(key, button[key]);
  }
  for (const key in cards) {
    // @ts-expect-error
    app.component(key, cards[key]);
  }
  for (const key in common) {
    // @ts-expect-error
    app.component(key, common[key]);
  }
  for (const key in audit) {
    // @ts-expect-error
    app.component(key, audit[key]);
  }
  for (const key in document) {
    // @ts-expect-error
    app.component(key, document[key]);
  }
  for (const key in note) {
    // @ts-expect-error
    app.component(key, note[key]);
  }

  for (const key in fk) {
    // @ts-expect-error
    app.component(key, fk[key]);
  }
  for (const key in form) {
    // @ts-expect-error
    app.component(key, form[key]);
  }
  for (const key in layouts) {
    // @ts-expect-error
    app.component(key, layouts[key]);
  }
  for (const key in list) {
    // @ts-expect-error
    app.component(key, list[key]);
  }
  for (const key in listCards) {
    // @ts-expect-error
    app.component(key, listCards[key]);
  }
  for (const key in listTable) {
    // @ts-expect-error
    app.component(key, listTable[key]);
  }
  for (const key in lookup) {
    // @ts-expect-error
    app.component(key, lookup[key]);
  }
  for (const key in modals) {
    // @ts-expect-error
    app.component(key, modals[key]);
  }
  for (const key in notifications) {
    // @ts-expect-error
    app.component(key, notifications[key]);
  }
  for (const key in read) {
    // @ts-expect-error
    app.component(key, read[key]);
  }
  for (const key in relatedEntities) {
    // @ts-expect-error
    app.component(key, relatedEntities[key]);
  }
  for (const key in schema) {
    // @ts-expect-error
    app.component(key, schema[key]);
  }
  for (const key in simpleMap) {
    // @ts-expect-error
    app.component(key, simpleMap[key]);
  }
  for (const key in viewer) {
    // @ts-expect-error
    app.component(key, viewer[key]);
  }
}

import "./assets/fonts/inter.css";

export default { install };

// export * from "./components";
//export type * from "./types/helpers";
//export * from "./utils";
