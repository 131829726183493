<template>
  <LayoutChildList>
    <Authorize permission-name="ReadAnnotation" :resource="{ [idField]: $route.params.id }" with-redirect>
      <ListCardsLocal
        :entity-label="entityNoteConfigStore.noteLabel"
        :entity-label-plural="entityNoteConfigStore.noteLabelPlural"
        :items="entityNoteStore.annotations"
        is-child-list
        :create-to.once="{ name: routeNamePrefix + 'New', params: { ...$route.params } }"
        :create-to-auth.once="createRrNoteAR"
        @deleteClick="deleteAnnotation"
        hide-filters
      >
        <template #cards="cardsProps">
          <li
            v-for="item in cardsProps.items"
            :key="item.id"
            class="mx-4 bg-white px-4 py-6 shadow sm:rounded-lg sm:p-6"
          >
            <article :aria-labelledby="'listCard-title-' + item.id">
              <div>
                <div class="flex space-x-3">
                  <div class="flex-shrink-0">
                    <InitialBadge :names="item.createdBy.name" size="lg" aria-hidden="true"></InitialBadge>
                  </div>
                  <div class="min-w-0 flex-1">
                    <p class="text-sm font-medium text-neutral-900">
                      {{ item.createdBy.name }}
                      <!-- <a :href="question.author.href" class="hover:underline">{{ question.author.name }}</a> -->
                    </p>
                    <p class="text-sm text-neutral-500" :id="'listCard-title-' + item.id">
                      {{ longDateTimeString(item.createdOnUtc) }}
                      <!-- TODO: Improve styling of output of filter, or add a component. -->
                      <!-- January 3, 2023 <span class="text-neutral-400">at</span> 11:43 AM -->
                    </p>
                  </div>
                  <div class="flex flex-shrink-0 self-center">
                    <Authorize permission-name="DeleteAnnotation" :resource="{ [idField]: $route.params.id }">
                      <Menu as="div" class="relative inline-block text-left">
                        <div>
                          <MenuButton
                            class="-m-2 flex items-center rounded-full p-2 text-neutral-400 hover:text-neutral-600"
                          >
                            <span class="sr-only">Open actions</span>
                            <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                          </MenuButton>
                        </div>

                        <transition
                          enter-active-class="transition ease-out duration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95"
                        >
                          <MenuItems
                            class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            <div class="py-1">
                              <MenuItem v-slot="{ active }">
                                <router-link
                                  :to="{
                                    name: routeNamePrefix + 'Edit',
                                    params: { ...$route.params, annotationId: item.id },
                                  }"
                                  :class="[
                                    active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700',
                                    'flex px-4 py-2 text-sm',
                                  ]"
                                >
                                  <PencilIcon class="mr-3 h-5 w-5 text-neutral-400" aria-hidden="true" />
                                  <span>Edit</span></router-link
                                >
                              </MenuItem>
                              <MenuItem v-slot="{ active }">
                                <button
                                  type="button"
                                  :class="[
                                    active ? 'bg-neutral-100 text-neutral-900' : 'text-neutral-700',
                                    'flex w-full px-4 py-2 text-sm',
                                  ]"
                                  @click="openConfirmDelete(item)"
                                >
                                  <TrashIcon
                                    class="mr-3 h-5 w-5"
                                    aria-hidden="true"
                                    :class="[active ? `text-red-600` : `text-neutral-400`]"
                                  />
                                  <span>Delete</span>
                                </button>
                              </MenuItem>
                            </div>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </Authorize>
                  </div>
                </div>
                <!-- <h2
                  :id="'listCard-title-' + item.id"
                  class="mt-4 hidden text-base font-medium text-neutral-900"
                >
                  {{ item[idField] }}
                </h2> -->
              </div>
              <!-- Was mt-2 with the title. -->
              <div class="mt-4 space-y-4 whitespace-pre-wrap break-words text-sm text-neutral-700">
                {{ item.noteText }}
              </div>
              <!-- <div class="mt-6 flex justify-between space-x-8">
                    <div class="flex space-x-6">
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <HandThumbUpIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">{{ question.likes }}</span>
                          <span class="sr-only">likes</span>
                        </button>
                      </span>
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <ChatBubbleLeftEllipsisIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">{{ question.replies }}</span>
                          <span class="sr-only">replies</span>
                        </button>
                      </span>
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <EyeIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">{{ question.views }}</span>
                          <span class="sr-only">views</span>
                        </button>
                      </span>
                    </div>
                    <div class="flex text-sm">
                      <span class="inline-flex items-center text-sm">
                        <button type="button" class="inline-flex space-x-2 text-neutral-400 hover:text-neutral-500">
                          <ShareIcon class="h-5 w-5" aria-hidden="true" />
                          <span class="font-medium text-neutral-900">Share</span>
                        </button>
                      </span>
                    </div>
                  </div> -->
            </article>
          </li>
        </template>
      </ListCardsLocal>
    </Authorize>
    <ConfirmDelete
      :entity-label="entityNoteConfigStore.noteLabel"
      :entity-label-plural="entityNoteConfigStore.noteLabelPlural"
      @deleteClick="deleteAnnotation"
      ref="confirmDeleteRef"
    ></ConfirmDelete>
  </LayoutChildList>
</template>

<script lang="ts">
import { LayoutChildList } from "@/components/layouts";
import { ListCardsLocal } from "@/components/list/listCards";
import { ConfirmDelete } from "@/components/modals";
import { InitialBadge } from "@/components/badge";

import { useApi } from "@/utils/api";
import { longDateTimeString } from "@/utils/filters";

import { Authorize, useAuthorization } from "@/utils/auth/authorization";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisVerticalIcon, PencilIcon, TrashIcon } from "@heroicons/vue/20/solid";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { Annotation } from ".";
import { deleteAnnotationById } from "@/components/entity/note/annotationApi";
import { useEntityNoteConfigStore } from "@/components/entity/note/entityNoteConfigStore";
import { useEntityNoteStore } from "@/components/entity/note/entityNoteStore";

/** This component displays the ListCards of notes for a given entity */
export default defineComponent({
  name: "EntityNotes",
  components: {
    Authorize,
    LayoutChildList,
    ListCardsLocal,
    InitialBadge,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    EllipsisVerticalIcon,
    PencilIcon,
    TrashIcon,
  },
  props: {
    /** The ID field for the entity these notes belong to (E.g. exampleEntityId) */
    idField: {
      type: String,
      required: true,
    },
    /** The route name prefix for the entity note pages (E.g. "ExampleEntityNote").
     *  This prefix is the string that when combined with "Edit" or "New" will create the proper route name for each  */
    routeNamePrefix: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const entityNoteStore = useEntityNoteStore();
    const entityNoteConfigStore = useEntityNoteConfigStore();

    const handleRowClick = () => {};

    const { authorizationClient, AuthRequest } = useAuthorization();
    const route = useRoute();

    /* Optional way to validate things if not using the Authorize component */
    // const readAnnotationAR = new AuthRequest();
    // readAnnotationAR.permissionName = "ReadAnnotation";
    // authorizationClient.isAuthorizedRedirect(readAnnotationAR, useRouter());

    const { exec: execDeleteAnnotation } = useApi(deleteAnnotationById, {
      responseAdapter: (response) => {
        return response.data;
      },
    });

    let itemToDelete: Annotation | null = null;
    async function deleteAnnotation() {
      if (!itemToDelete) return;

      await execDeleteAnnotation({ id: itemToDelete.id, [props.idField]: route.params.id });
      entityNoteStore.deleteNote(itemToDelete);
    }

    const confirmDeleteRef = ref<typeof ConfirmDelete | null>(null);
    const openConfirmDelete = function (item: Annotation) {
      itemToDelete = item;
      confirmDeleteRef.value?.openConfirmation();
    };

    const createRrNoteAR = new AuthRequest();
    createRrNoteAR.permissionName = "CreateAnnotation";
    createRrNoteAR.resource = { [props.idField]: route.params.id };

    return {
      createRrNoteAR,
      longDateTimeString,
      handleRowClick,
      entityNoteStore,
      deleteAnnotation,
      confirmDeleteRef,
      openConfirmDelete,
      entityNoteConfigStore,
    };
  },
});
</script>
