import { plainMurnAddressFromFormkit, plainMurnAddressToFormkit } from "@/components/fk/AddressInputLookup";
import {
  fillAddressMurnValues,
  fillAllMurnValues,
  fillPrefixedAddressMurnValues,
  getLookupDescription,
} from "@/components/lookup";
import { LE } from "@casa/constants/enumsNew";
import { Abode, AbodeNew } from "@casa/views/abode/abode";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: Abode) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as Abode; //TODO: ts
  return addAuthRequestFields(result);
}
async function responseAdapter(response: { data: Abode }) {
  response.data.testStateMurn = LE.State.OH;
  response.data.testCountryMurn = LE.Country.USA;
  await fillAllMurnValues(response.data);
  // await fillAddressMurnValues(response.data);
  // await fillPrefixedAddressMurnValues(response.data, ["test"]);
  // console.log(response.data);
  if (typeof response.data.hydrographicAreaMurn === "string") {
    response.data.hydrographicAreaMurn = JSON.parse(response.data.hydrographicAreaMurn as string);
  }

  addAuthRequestFields(response.data);
  //TODO: TS
  return response.data;
}

function requestAdapter(data: Abode) {
  if (data.hydrographicAreaMurn) {
    data.hydrographicAreaMurn = JSON.stringify(data.hydrographicAreaMurn);
  }

  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit<T extends Abode | AbodeNew>(data: T) {
  plainMurnAddressToFormkit(data);
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: Abode) {
  plainMurnAddressFromFormkit(data as Abode);
}
