import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";

/** Returns App Settings store, creates if necessary
 *
 * @property agencyName default is "Modernary"
 * @property agencyLogoSrc default is "logo-m-black.svg"
 * @property listTableTake default is 40
 * @property autocompleteTake default is 10
 * @property pageTitleBase default is "MUI"
 * @property applicationTitle default is "Casa Administration System Application"
 * @property showLoginButtons default is true
 * @property showSimpleNav default is false
 * @property bypassAuth default is false
 * @property baseURL default is "Unconfigured"
 * @property apiHost default is a Symbol("Unconfigured")
 * @property systemIdentifier default is ""
 * @property viteBaseUrl default is ""
 * @property appVersion default is "". This is set once automatically by an apiService response interceptor.
 * @property productVersion default is "". This is set once automatically by an apiService response interceptor.
 * @property cacheTtl default is 60 minutes.
 */
export const useAppSettingsStore = defineStore("AppSettingsStore", () => {
  const agencyName = ref("Modernary");
  const agencyLogoSrc = ref("logo-m-black.svg");
  const listTableTake = ref(40);
  const autocompleteTake = ref(10);
  const pageTitleBase = ref("CASA");
  const applicationTitle = ref("Casa Administration System Application");
  const showLoginButtons = ref(true);
  const showSimpleNav = ref(false);
  const bypassAuth = ref(false);
  const baseURL = "Unconfigured";
  const apiHost = Symbol("Unconfigured");
  const systemIdentifier = ref("");
  const viteBaseUrl = ref("");
  const appVersion = ref("");
  const productVersion = ref("");
  const cacheTtl = ref(60 * 1000 * 60); // 60 minutes in ms;

  return {
    agencyName,
    agencyLogoSrc,
    listTableTake,
    autocompleteTake,
    pageTitleBase,
    applicationTitle,
    showLoginButtons,
    showSimpleNav,
    bypassAuth,
    baseURL,
    apiHost,
    systemIdentifier,
    viteBaseUrl,
    appVersion,
    productVersion,
    cacheTtl,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppSettingsStore, import.meta.hot));
}
