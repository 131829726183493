import { NotifyOptions } from "@/components/notifications/NotifyOptions.js";

export class NotifyOptionsError extends NotifyOptions {
  /** @deprecated */
  hide403: boolean;
  hideStatusCodes: number[] | undefined;

  constructor(
    hide: boolean,
    group: string,
    type: string,
    title: string,
    text: string,
    created: string,
    updated: string,
    deleted: string,
    fetched: string,
    time: number,
    hide403: boolean,
    hideStatusCodes?: number[],
  ) {
    super(hide, group, type, title, text, time);
    this.created = created;
    this.updated = updated;
    this.deleted = deleted;
    this.fetched = fetched;
    this.hide403 = hide403; // Only hide 403 errors
    this.hideStatusCodes = hideStatusCodes;
  }

  get text() {
    if (this._text) {
      return this._text;
    }

    const prefix = "Could not";
    if (this.updated) {
      return `${prefix} update ${this.updated}`;
    } else if (this.created) {
      return `${prefix} create ${this.created}`;
    } else if (this.deleted) {
      return `${prefix} delete ${this.deleted}`;
    } else if (this.fetched) {
      return `${prefix} get ${this.fetched}`;
    }
    return this._text;
  }
  set text(val) {
    this._text = val;
  }

  /**
   * Entity name injected in standard Created title
   * @type {string}  */
  created;
  /**
   * Entity name injected in standard Updated title
   * @type {string}  */
  updated;
  /**
   * Entity name injected in standard Deleted title
   * @type {string}  */
  deleted;
  /**
   * Entity name injected in standard Fetching title
   * @type {string}  */
  fetched;

  toOptions() {
    return {
      group: this.group,
      type: this.type,
      title: this.title,
      text: this.text,
    };
  }
}
