/* 
// "FormKit" address in JS - 
entity {
  prefixAddress {
    street
    city
    stateMurn
    zipCode
    countryMurn
  }
}

// address as it is on the original entity - 
entity {
  prefixStreet
  prefixCity
  prefixStateMurn
  prefixZipCode
  prefixCountryMurn
}
*/

//TS:TODO replace with usage of Capitalize<>? - https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html#capitalizestringtype
const fieldNames = ["city", "countryMurn", "stateMurn", "street", "zipCode"] as const;
type fields = (typeof fieldNames)[number];
const fieldNamesSuffix = ["City", "CountryMurn", "StateMurn", "Street", "ZipCode"] as const;
type fieldsSuffix = (typeof fieldNamesSuffix)[number];

export type PrefixedAddressWithMurn<Prefix extends string> = {
  [P in `${Prefix}${fieldsSuffix}`]?: string;
};

export type AddressWithMurn = {
  [P in `${fields}`]?: string;
};

export type PrefixedAddressWithMurnField<Prefix extends string> = {
  [P in `${Prefix}Address`]?: AddressWithMurn;
};

export type HasPrefixedAddressWithMurn<Prefix extends string> = PrefixedAddressWithMurn<Prefix> &
  PrefixedAddressWithMurnField<Prefix>;
export type HasPlainAddressWithMurn = PrefixedAddressWithMurnField<"murn"> & AddressWithMurn;

/**
 * Converts the passed in `data` object from it's FormkitForm back into its original form.
 * Accepts an object that has a "prefixed" address and divides the values from this property
 * back into their respective properties on the `data` object.
 * @param {HasPrefixedAddressWithMurn<Prefix>} data Data object with the prefixed address
 * @param {Prefix} prefix prefix used on the address
 * @returns
 */
export function murnAddressFromFormkit<Prefix extends string>(
  data: HasPrefixedAddressWithMurn<Prefix>,
  prefix: Prefix,
) {
  const addressField = data[`${prefix}Address`] as AddressWithMurn | undefined;
  if (!addressField) return;

  for (let index in fieldNamesSuffix) {
    const fieldNamePrefixed = (prefix + fieldNamesSuffix[index]) as keyof PrefixedAddressWithMurn<Prefix>;
    const fieldName = fieldNames[index];

    (data as PrefixedAddressWithMurn<Prefix>)[fieldNamePrefixed] = addressField?.[fieldName];
  }

  delete data[`${prefix}Address`];
}

/**
 * Converts the passed in `data` object from it's original form into a Formkit compatabile form.
 * Accepts an object with a prefixed address that is seperated out into its individual parts and
 * then combines it into a object that FormKit will accept.
 * Appends this new object to the `data` object on a property named by the `prefix` followed by "Address"
 *
 * @param {HasPrefixedAddressWithMurn<Prefix>} data Object containing the prefixed address
 * @param {Prefix} prefix Prefix for the `data` objects address
 */
export function murnAddressToFormkit<Prefix extends string>(data: HasPrefixedAddressWithMurn<Prefix>, prefix: Prefix) {
  (data[`${prefix}Address`] as PrefixedAddressWithMurnField<Prefix>) = {
    street: data[`${prefix}Street`],
    city: data[`${prefix}City`],
    stateMurn: data[`${prefix}StateMurn`],
    zipCode: data[`${prefix}ZipCode`],
    countryMurn: data[`${prefix}CountryMurn`],
  } as AddressWithMurn;
}

/**
 * Converts the passed in `data` object from it's FormkitForm back into its original form.
 * Accepts an object that has a "plain" address (contains the `locationAddress` property) and
 * divides the values from this property back into their respective properties on the `data` object.
 * @param {HasPlainAddressWithMurn} data Data object to be converted
 */
export function plainMurnAddressFromFormkit(data: HasPlainAddressWithMurn) {
  data.street = data.murnAddress?.street;
  data.city = data.murnAddress?.city;
  data.stateMurn = data.murnAddress?.stateMurn;
  data.zipCode = data.murnAddress?.zipCode;
  data.countryMurn = data.murnAddress?.countryMurn;
}

/**
 * Converts the passed in `data` object from it's original form into a Formkit compatabile form.
 * Accepts an object with a "plain" address (no prefix) that is seperated out into its individual
 * parts and then combines it into a object that FormKit will accept.
 * Appends this new object to the `data` object on a property named `locationAddress`
 * @param {HasPlainAddressWithMurn} data Data object with plain address
 */
export function plainMurnAddressToFormkit(data: HasPlainAddressWithMurn) {
  data.murnAddress = {
    street: data.street,
    city: data.city,
    stateMurn: data.stateMurn,
    zipCode: data.zipCode,
    countryMurn: data.countryMurn,
  };
}
