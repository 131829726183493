<template>
  <FormKit
    type="autocomplete"
    :label="label"
    :name="field"
    :placeholder="placeholder"
    :value="selectedOption"
    :options="allItemsRef"
    :option-loader="optionLoader"
    @node="setNode"
    empty-message="None Found"
    selection-removable
    open-on-remove
    selections-class="flex flex-wrap gap-x-2 mt-2"
    validation="has_no_validation"
    inner-class="bg-white"
    label-class="text-neutral-800"
  >
    <template #selection="{ option, classes }">
      <div :class="classes.selection">
        <div :class="`${classes.option} `">
          {{ option.label }}
        </div>
      </div>
    </template></FormKit
  >
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/** Provides a single-select FormKit autocomplete component that will filter the entity's list results. */
export default defineComponent({
  name: LIST_FILTER_LOOKUP,
});
</script>

<script setup lang="ts">
import type { ListFilterLabels, ListFiltersHelpers, ListTableProps } from "@/components/list/genericList";
import { LIST_FILTER_LOOKUP } from "@/components/list/genericList/componentNames.js";
import injectionSymbols from "@/components/list/genericList/injectionSymbols.js";
import { useLookup } from "@/components/lookup/useLookup.js";
import { capitalizeString } from "@/utils/filters";
import { FormKitNode } from "@formkit/core";
import { useSessionStorage } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { PropType, Ref, computed, defineComponent, inject, ref, unref } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";

const props = withDefaults(
  defineProps<{
    /** default value for the dropdown */
    defaultId?: string;
    /**  default description for the dropdown */
    defaultDescription?: string;
    /** Label for the dropdown */
    label?: string;
    /** Optional specification for the plural label - will default to just adding `s` to the end of the `label`*/
    labelPlural?: string;
    /** Name of the field to filter on */
    field: string;
    /** The code of the LookupGroup to use for this autocomplete */
    groupCode?: string;
    /** Promise that returns a sorted, FormKit compatabile, array of autocomplete options. Used when items cannot come from a LookupGroup. */
    itemsPromise?: Promise<{ label: string; value: string }[] | null | undefined>;
  }>(),
  {
    defaultId: () => "",
    defaultDescription: () => "",
    label: (props: any) => capitalizeString(props.field),
    labelPlural: (props: any) => props.label + "s",
    groupCode: (props) => (props.itemsPromise ? "" : "$invalid$"),
  },
);

const route = useRoute();
const storeName = route.name?.toString() + "-Filter" + props.field;

// For now, assume that all props are provided before rendering.
const allItemsRef = ref<{ label: string; value: string }[]>([]);

const itemsMap = new Map<string, string>([]);

const { filterChangeHandler } = inject(injectionSymbols.GenericListPropsKey) as ListTableProps;
const { registerAndUpdateFilters } = inject(injectionSymbols.ListFilterLabelsKey) as ListFilterLabels;
const { getIndexOfFilterComponent } = inject(injectionSymbols.ListFiltersHelpersKey) as ListFiltersHelpers;

const isQueryBlank = Object.entries(route.query).length === 0;
const selectedQueryRef = useRouteQuery(props.field, props.defaultId);
const LOADING_VALUE = "Loading...";

let optionsPromise:
  | Promise<
      | {
          label: string;
          value: string;
        }[]
      | null
      | undefined
    >
  | undefined;
if (props.itemsPromise) {
  optionsPromise = props.itemsPromise;
} else {
  const { loadOptions } = useLookup(props.groupCode);
  optionsPromise = loadOptions(undefined, undefined);
}

let getFilterDescriptions = (val: string) => (val && itemsMap.has(val) ? [itemsMap.get(val)!] : []);

const selectedSessionRef = useSessionStorage<any>(storeName, props.defaultId) as Ref<string>;
if (isQueryBlank && selectedSessionRef.value) {
  selectedQueryRef.value = selectedSessionRef.value;
}
let selectedOption = ref();
selectedOption.value = selectedQueryRef.value;
let isValueUpdatedByQuery = false;

itemsMap.set(selectedOption.value, LOADING_VALUE);

registerAndUpdateFilters({
  label: props.label,
  value: getFilterDescriptions(selectedOption.value),
  field: props.field,
  sortOrder: getIndexOfFilterComponent(props.field),
});

const isDefault = () => selectedOption.value === props.defaultId;
const isIgnored = () => selectedOption.value === "";

let dropdown: FormKitNode<unknown> | undefined;

const placeholder = ref("All " + props.labelPlural);
const setPlaceholder = (val: string) => {
  placeholder.value = val ? "" : "All " + props.labelPlural;
};
setPlaceholder(selectedOption.value);

const setNode = (dropdownInput: FormKitNode<unknown>) => {
  dropdown = dropdownInput;
  dropdown.on("input", async ({ payload }) => {
    if (isValueUpdatedByQuery) {
      isValueUpdatedByQuery = false;
      return;
    }
    selectedOption.value = payload;
    setPlaceholder(selectedOption.value);
    const queryValue = isIgnored() ? undefined : payload;
    selectedQueryRef.value = queryValue;
  });
};

onBeforeRouteUpdate((to, from) => {
  const newValue = to.query[props.field];
  const oldValue = from.query[props.field];
  if (newValue === oldValue) {
    return;
  } else {
    const val = newValue?.toString() ?? "";
    isValueUpdatedByQuery = selectedOption.value !== val;
    selectedOption.value = val;
    setPlaceholder(selectedOption.value);
    dropdown?.input(val);
  }

  selectedSessionRef.value = selectedOption.value;

  registerAndUpdateFilters({
    label: props.label,
    value: getFilterDescriptions(selectedOption.value),
    field: props.field,
    sortOrder: getIndexOfFilterComponent(props.field),
  });

  filterChangeHandler({
    field: props.field,
    filterValue: selectedOption.value,
    isDefault: isDefault(),
    isIgnored: isIgnored(),
  });
});

if (!isIgnored()) {
  filterChangeHandler({
    field: props.field,
    filterValue: selectedOption.value?.toString?.() ?? "",
    isDefault: isDefault(),
    isIgnored: isIgnored(),
  });
}

function setItemsMap() {
  allItemsRef.value.forEach((item: { label: string; value: any; __original?: any }) => {
    if (item.__original) {
      itemsMap.set(item.__original, item.label);
    } else {
      itemsMap.set(item.value, item.label);
    }
  });
}

const optionsResult = await optionsPromise;
if (!optionsResult) {
  throw new Error(`Failed to load ${props.label} values for ListFilterLookupMultiple.`);
}

allItemsRef.value = optionsResult;
setItemsMap();
registerAndUpdateFilters({
  label: props.label,
  value: getFilterDescriptions(selectedOption.value),
  field: props.field,
  sortOrder: getIndexOfFilterComponent(props.field),
});

const optionLoader = async (id: any, cachedOption: any) => {
  // The cachedOption results in the value being shown on the UI instead of the label in our case, so we aren't using it.
  const result = {
    label: itemsMap.get(id) ?? "",
    value: id.toString(),
  };
  return result;
};
</script>
