<template>
  <div class="pt-3">
    <!-- <div class="pb-3 text-neutral-500 font-medium">Filters</div> -->
    <div class="space-y-2">
      <Suspense>
        <!-- @slot Slot to place the desired filter components in  -->
        <slot name="default"></slot>
        <template #fallback>
          <div class="mb-2 mt-4 flex w-full place-content-center">
            <FormKitIcon icon="spinner" class="h-8 w-8 animate-spin rounded-full text-neutral-600" />
          </div>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script lang="ts">
import {
  LIST_DROPDOWN_FILTER,
  LIST_DROPDOWN_FILTER_MULTIPLE,
  LIST_FILTER_LOOKUP,
  LIST_FILTER_LOOKUP_MULTIPLE,
  LIST_KEYWORD_FILTER,
} from "@/components/list/genericList/componentNames";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import { FormKitIcon } from "@formkit/vue";
import { defineComponent, provide } from "vue";

/** Wrapper component for the ListKeywordFilter, ListDropdownFilter, and ListDropdownFilterMultiple.
 *  Determines the order of the filters so they can be properly displayed in the filter tags
 */
export default defineComponent({
  name: "ListFilters",
  components: { FormKitIcon },
  setup(props, { slots }) {
    provide(injectionSymbols.ListFiltersHelpersKey, {
      // TODO: Consider building a map or otherwise memoizing this. Unlikely that filter order will change...
      getIndexOfFilterComponent: (field: string) => {
        const defaultSlot = slots.default?.();
        if (!defaultSlot || !field) return -1;

        for (let i = 0; i < defaultSlot.length; ++i) {
          const child = defaultSlot[i];

          const type = child.type as any; // VNode doesn't have "name" on the type... even though it does when it's a component.
          let fieldName = null;
          if (type?.name === LIST_KEYWORD_FILTER) {
            fieldName = "keywords";
          } else if (
            type?.name === LIST_DROPDOWN_FILTER ||
            type?.name === LIST_DROPDOWN_FILTER_MULTIPLE ||
            type?.name === LIST_FILTER_LOOKUP ||
            type?.name === LIST_FILTER_LOOKUP_MULTIPLE
          ) {
            fieldName = child.props?.field;
          }

          if (field === fieldName) return i;
        }
        return -1;
      },
    });
  },
});
</script>

<style module></style>
