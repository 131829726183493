<template>
  <div class="h-full overflow-auto">
    <div class="">
      <div
        class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
      >
        <!-- Card Content goes here -->
        <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
          <div>
            <h1 class="text-2xl font-extrabold">
              {{ appSettingsStore.applicationTitle }}
              <span class="font-semibold">({{ appSettingsStore.pageTitleBase }})</span>
            </h1>
            <h2 class="mt-2 text-xl text-sky-500 md:mt-1">
              <span class="font-semibold"><LoginButton class="hover:underline" /></span>
              or
              <RegisterButton class="cursor-pointer font-semibold hover:underline">Register</RegisterButton>
              to get started
            </h2>
            <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
              <p>Casa is a system used to develop, test, and demonstrate MUI components.</p>
            </div>
            <!-- put the stuff we demonstrate here?  -->

            <!-- <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <h3>Recycling</h3>
                <ul>
                  <li>Submit annual recycling reports</li>
                  <li>View historical recycled and diverted materials</li>
                  <li>Secure access to your company or agency data</li>
                </ul>
              </div>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <h3>Solid Waste Disposal</h3>
                <ul>
                  <li>Submit quarterly, semi-annual, and annual disposal reports</li>
                  <li>View historical disposal data</li>
                  <li>Secure access to your facility data</li>
                </ul>
              </div>-->
          </div>
          <!-- <div><router-link to="viewerTest">link plain</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 1 } }">link 1</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 2 } }">link 2</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 3 } }">link 3</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 4 } }">link 4</router-link></div> -->
          <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
            <LoginButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign In" />
            <RegisterButton
              :as="BaseButton"
              variant="priority"
              class="w-full sm:w-auto"
              label="Register"
            ></RegisterButton>
          </div>
        </div>
      </div>
    </div>
    <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
      <MdrnAttribution />
    </footer>
  </div>
</template>

<script lang="ts">
import { InitialBadge } from "@mui/components/badge";
import { BaseButton } from "@mui/components/button";
import { MdrnAttribution, NoneFoundFlag, useAppNav, useAppSettingsStore } from "@mui/components/common";
import { LoginButton, LogoutButton, RegisterButton, useUser } from "@mui/utils/auth/authentication";

import { EnvelopeIcon, PhoneIcon } from "@heroicons/vue/20/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent, onMounted, ref, watch } from "vue";

// Home content for unauth'd users
export default defineComponent({
  name: "HomePublic",
  setup() {
    const appSettingsStore = useAppSettingsStore();

    return { BaseButton, appSettingsStore };
  },
  components: {
    BaseButton,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
    LogoutButton,
  },
});
</script>
