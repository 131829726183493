<template>
  <component :is="as" type="button" @click.prevent="logout">Sign Out</component>
</template>

<script lang="ts">
import { redirectToLogout } from "@/utils/auth/authentication/bffAuthService.js";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

/** An unstyled button used for Logging Out. Styles will be automatically inherited. */
export default defineComponent({
  name: "LogoutButton",
  props: {
    /** Name string of a registered component or a Component that this should be rendered as.  */
    as: {
      type: [String, Object], // This should be String, Component, but for some reason the import is broken.
      default: "button",
    },
  },
  setup() {
    const router = useRouter();

    return {
      logout() {
        redirectToLogout();
        router.push({ path: "/" });
      },
    };
  },
});
</script>
