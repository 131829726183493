import appraisalService from "@casa/views/appraisal/appraisalService";
import { apiService, PagingApiAndNotifierConfig } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
import { apiHosts } from "@casa/api/constants/apiHosts";

import { Appraisal, AppraisalNew, AppraisalQuery } from "./appraisal";
import { type AxiosResponse } from "axios";
import { getAbodeById } from "@casa/views/abode/abodeApi";

const URL = "appraisals";
const ENTITY_NAME = "Appraisal";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService<Appraisal>(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getAppraisals = (params: AppraisalQuery, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getAppraisalById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewAppraisal = async (ids: { [key: string]: number | string }) => {
  const defaultApp = {
    data: {
      ...appraisalService.getInitialData(),
      ...ids,
    },
  } as AxiosResponse<AppraisalNew, any>;

  if (ids.abodeId) {
    return getAbodeById(ids.abodeId).then((abodeResult) => {
      defaultApp.data.abode = abodeResult.data;
      defaultApp.data.abodeId = abodeResult.data.id;
      return defaultApp;
    });
  } else {
    return defaultApp;
  }
};

/**
 *
 * @param {Object} ids Object having either an `id` for an Application or a `facilitySiteId`, `permitId`, and type
 * @param {ApiOptions} config
 * @returns {api.get}
 */

type AppraisalIds = {
  id?: string | number;
};
const getAppraisalByIdOrNew = (ids: AppraisalIds, config = {}) => {
  if (!ids.id) {
    return getNewAppraisal(ids);
  } else {
    return getAppraisalById(ids.id, config) as Promise<AxiosResponse<AppraisalNew, any>>;
  }
};

const createAppraisal = (appraisal: Appraisal, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, appraisal, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateAppraisal = (appraisal: Appraisal, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + appraisal.id, appraisal, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Appraisal} appraisal This cannot be a 'ref' to a appraisal
 */
const saveAppraisal = (appraisal: Appraisal, config = {}) => {
  if (appraisal.id) {
    return updateAppraisal(appraisal, config);
  } else {
    return createAppraisal(appraisal, config);
  }
};

/**
 * @param {Int} id
 */
const deleteAppraisalById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteAppraisalById,
  getAppraisals,
  getAppraisalById,
  getNewAppraisal,
  getAppraisalByIdOrNew,
  createAppraisal,
  updateAppraisal,
  saveAppraisal,
};
