export { default as Badge } from "@/components/badge/Badge.vue";
export { default as InitialBadge } from "@/components/badge/InitialBadge.vue";
export { default as NumberBadge } from "@/components/badge/NumberBadge.vue";
export enum BadgeColor {
  GREEN = "green",
  ORANGE = "orange",
  CYAN = "cyan",
  NEUTRAL = "neutral",
  RED = "red",
}
