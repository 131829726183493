<template>
  <div class="mt-4 space-y-4 text-sm text-neutral-700" v-bind="$attrs">
    <!-- @slot Optional slot to place an icon before the label/value (such as with phone numbers, emails, etc) -->
    <slot name="icon"></slot>
    <span class="pr-2 text-neutral-500" v-bind="$attrs" v-if="label">{{ label }}</span
    >{{ val }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
/** Component that makes up body content of the ListCard  */
export default defineComponent({
  name: "ListCardItem",
  props: {
    /** Optional label to be displayed before the value */
    label: {
      type: String,
    },
    /** Value to be displayed */
    val: {
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
</script>

<style module></style>
