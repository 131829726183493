<template>
  <slot
    :apiStatus="apiStatus"
    :dataRef="dataRef"
    :countLabel="countLabel"
    :isErrorOrEmpty="isErrorOrEmpty"
    :isStatusSuccess="isStatusSuccess"
    :isStatusError="isStatusError"
    :infiniteLoadHandler="infiniteLoadHandler"
    :sortByQueryRef="sortByQueryRef"
    :entityLabelPlural="entityLabelPlural"
    :isDefaultState="isDefaultState"
  ></slot>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.
/**
 * This component is a wrapper for the other list components (`ListCards` and `ListTable`) that provides generic/common functionality.
 * Logic for filter and sort changes are handled here. It provides the `injectionSymbols.ListFilterLabelsKey`,
 * `injectionSymbols.ListSharedFiltersKey`, and `injectionSymbols.GenericListPropsKey` to be used by nested components.
 */
export default { name: "GenericList" };
</script>

<script setup lang="ts" generic="R extends Paging<PagedType<R>>, T extends Paging<PagedType<T>>">
import { useAppSettingsStore } from "@/components/common";
import type { ListApi, ListFilter, ListSortByArray } from "@/components/list/genericList";
import injectionSymbols from "@/components/list/genericList/injectionSymbols";
import {
  Paging,
  PagedType,
  apiStatus,
  queryToSortByMap,
  routeQueryToSortByQuery,
  usePagedApi,
  usePaging,
} from "@/utils/api";
import { AuthRequest, withAuthorization } from "@/utils/auth/authorization";
import { useSessionStorage } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { computed, provide, readonly, ref } from "vue";
import { RouteLocationRaw, onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import { SharedListFilter } from ".";

const props = withDefaults(
  defineProps<{
    /** Label for the entity being used in the list  */
    entityLabel: string;
    /** Plural version of the `entityLabel`. If not provided, this will default to appending an "s" to the `entityLabel` */
    entityLabelPlural?: string;
    /** Api object responsible for populating the list. `apiCall` property must be present. `config` and `additionalFilters` are optional */
    api: ListApi<R, T>;
    /** Route to the create screen for this entity  */
    createTo?: RouteLocationRaw;
    /** Request for authorizing if the create option is availble  */
    createToAuth?: AuthRequest;
    /** take for the backend call (how many results should be returned) */
    take?: number;
    /** An array of field names for the entity to use as the default sorting properties. Prefix with a "-" to reverse sort order. */
    sortByDefault: ListSortByArray;
    /** Renders the list as a child list  */
    isChildList?: boolean;
    /** Optional prop to hide the filters.  */
    hideFilters?: boolean;
  }>(),
  {
    entityLabelPlural: (props: any) => props.entityLabel + "s",
    isChildList: false,
    hideFilters: false,
  },
);

const {
  dataRef,
  resetData,
  statusRef,
  setStatus,
  exec,
  execPaged,
  isStatusIdle,
  isStatusPending,
  isStatusSuccess,
  isStatusError,
} = withAuthorization(usePagedApi<PagedType<R>, PagedType<T>, R, T>(props.api.apiCall, props.api.config));

const router = useRouter();
const route = useRoute();
const storeNameOrderBy = (route.name as string) + "-OrderBy";
const appSettingsStore = useAppSettingsStore();

const isQueryBlank = Object.entries(route.query).length === 0;

const sortByQueryRef = useRouteQuery("sortBy", props.sortByDefault, {
  transform: (x) => {
    if (typeof x === "string") return [x];
    return x;
  },
});

const sortBySessionRef = useSessionStorage<ListSortByArray>(storeNameOrderBy, []);

if (isQueryBlank && sortBySessionRef.value?.length > 0) {
  sortByQueryRef.value = sortBySessionRef.value;
}

const sortByMapRef = ref(queryToSortByMap(sortByQueryRef.value));

const { query, debouncedSearch, isBouncingRef, filterState, isDefaultState } = usePaging({
  additionalFilters: props.api.additionalFilters || {},
  sortByMapRef,
  sortByQueryRef,
  exec,
  execPaged,
  defaultTake: props.take || appSettingsStore.listTableTake,
  defaultSort: props.sortByDefault,
});

/**
 * Handles filter changes and fires a new query based on them
 *
 * @param {object} filterObj
 * @param {string} filterObj.field Name of the property whose value has changed
 * @param {string|object} filterObj.filterValue New value for the filter
 * @param {boolean} filterObj.isDefault Boolean indicating whether the filter value is the default value
 * @param {boolean} filterObj.isIgnored Boolean indicating whether the filter value should be excluded from the query
 */
const filterChangeHandler = (filterObj: ListFilter) => {
  if (filterObj.isIgnored) {
    delete query[filterObj.field];
  } else if (typeof filterObj.filterValue === "string" || typeof filterObj.filterValue === "number") {
    query[filterObj.field] = filterObj.filterValue;
  } else if (Array.isArray(filterObj.filterValue)) {
    query[filterObj.field] = filterObj.filterValue;
  }
  filterState.value[filterObj.field] = filterObj;

  resetData();
  debouncedSearch();
};

onBeforeRouteUpdate((to, from) => {
  // quick and dirty query change check.
  if (JSON.stringify(to.query) !== JSON.stringify(from.query) || Object.entries(to.query).length === 0) {
    let sortBy = sortByQueryRef.value;
    //improve default handling
    if (!to.query.sortBy) {
      // we've updated the URL, but sortByQueryRef is stale!
      sortBy = props.sortByDefault;
    }

    sortBySessionRef.value = sortBy;
    sortByMapRef.value = queryToSortByMap(sortBySessionRef.value);
    query.orderBy = routeQueryToSortByQuery(sortBy);

    resetData();
    debouncedSearch();
  }
});

const sortChangeHandler = ($event: KeyboardEvent | PointerEvent | MouseEvent, field: string) => {
  const descendingField = "-" + field;
  if (!$event.ctrlKey) {
    // single column
    if (sortByQueryRef.value.some((x) => x === field)) {
      sortByQueryRef.value = [descendingField];
    } else {
      sortByQueryRef.value = [field];
    }
  } else {
    // multi-column
    const fieldIndex = sortByQueryRef.value.findIndex((x) => x === field || x === descendingField);
    if (fieldIndex === -1) {
      sortByQueryRef.value = [...sortByQueryRef.value, field]; // push doesn't fire the watch.
    } else if (sortByQueryRef.value[fieldIndex] === descendingField) {
      // splice doesn't fire watch
      sortByQueryRef.value = [
        ...sortByQueryRef.value.slice(0, fieldIndex),
        ...sortByQueryRef.value.slice(fieldIndex + 1),
      ];
    } else {
      // Just setting the value won't fire watch
      sortByQueryRef.value = [
        ...sortByQueryRef.value.slice(0, fieldIndex),
        descendingField,
        ...sortByQueryRef.value.slice(fieldIndex + 1),
      ];
    }
  }
};

const hasDeletedItems = ref(false);
// TODO: Obviously $state isn't any, but the library used doesn't provide types.
const infiniteLoadHandler = async ($state: any) => {
  // It's possible that the filters have reduced the number of pages to 0 and we already have all results.
  if (dataRef.value && dataRef.value._items.length === dataRef.value.totalResultsCount) {
    $state.loaded();
    return;
  }
  if (isBouncingRef.value || isStatusPending.value) {
    return;
  }
  let isPaged = dataRef.value && dataRef.value._items.length > 0;
  if (hasDeletedItems.value) {
    // Items were deleted, so we cannot reliably get the next page.
    // We don't automatically refresh the list because they may want to delete more or they may just want to navigate away.
    resetData();
    isPaged = false;
  }

  await debouncedSearch(isPaged);
  hasDeletedItems.value = false;
};

const countLabel = computed(
  () =>
    (dataRef.value?.totalResultsCount || "0") +
    " " +
    (dataRef.value?.totalResultsCount === 1
      ? props.entityLabel?.toLowerCase()
      : props.entityLabelPlural?.toLowerCase()),
);

const isErrorOrEmpty = computed(
  () => isStatusError.value || (dataRef.value?.totalResultsCount === 0 && isStatusSuccess.value),
);

const removeItem = (itemToDelete: PagedType<T>) => {
  if (!dataRef.value) return;

  const itemIndex = dataRef.value._items.indexOf(itemToDelete);
  dataRef.value._items.splice(itemIndex, 1);
  --dataRef.value.totalResultsCount;
  hasDeletedItems.value = true;
};

const sharedFilters = ref<Array<SharedListFilter>>([]);

const registerAndUpdateFilters = (filterObject: SharedListFilter) => {
  for (let i = 0; i < sharedFilters.value.length; ++i) {
    let filter = sharedFilters.value[i];

    if (filter.field === filterObject.field) {
      // Existing filter with no data - remove it.
      if (!filterObject.value || filterObject.value.length === 0) {
        sharedFilters.value.splice(i, 1);
        return;
      }
      // Existing filter with data - update it.
      filter.value = filterObject.value;
      filter.label = filterObject.label;
      return;
    }
  }

  // New filter with data - add it and sort the array.
  if (filterObject.value && filterObject.value.length > 0) {
    sharedFilters.value.push(filterObject);
    sharedFilters.value.sort((a, b) => a.sortOrder - b.sortOrder);
  }
};

provide(injectionSymbols.ListFilterLabelsKey, { registerAndUpdateFilters });
provide(injectionSymbols.ListSharedFiltersKey, sharedFilters);

provide(injectionSymbols.GenericListPropsKey, {
  createTo: props.createTo,
  createToAuth: props.createToAuth,
  entityLabel: props.entityLabel,
  entityLabelPlural: props.entityLabelPlural,
  filterChangeHandler,
  isChildList: props.isChildList,
  sortByMapRef: readonly(sortByMapRef),
  sortChangeHandler,
  isDefaultState,
  removeItem,
  hideFilters: props.hideFilters,
});
</script>

<style module></style>
