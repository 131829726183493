<template>
  <LayoutAppContent v-if="!userRef.isAuthPending">
    <HomePublic v-if="!isAuthenticated && !isInternal" />
    <HomeExternal v-if="isExternalAuthenticated" />
    <HomeInternal v-if="isAuthenticated && isInternal" />

    <div class="h-full overflow-auto" :class="{ 'bg-neutral-100': isAuthenticated }" v-if="false">
      <div class="">
        <!-- content -->
        <div class="mx-auto mb-5 mt-5 max-w-5xl px-2" v-if="isAuthenticated && !abodeNavItem.isAuthPending">
          <div
            class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
          >
            <!-- Card Content goes here -->
            <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
              <div>
                <h1 class="text-2xl font-extrabold">Casa Administration System</h1>
                <h2 class="mt-2 text-xl text-neutral-500 md:mt-1">
                  <span class="font-semibold">Welcome, {{ userRef.firstName }}</span>
                </h2>
                <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                  <p>
                    <router-link
                      :to="{ name: abodeNavItem.name }"
                      v-if="abodeNavItem.isAuthorized"
                      class="text-sky-500"
                    >
                      <span>View abode information</span></router-link
                    ><span v-else>View abode information</span>
                    managed in the CASA system. Abodes include detached single family homes, condominiums, duplexes, and
                    other types of dwellings.
                  </p>
                </div>
              </div>
              <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
                <LogoutButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign Out" />
              </div>
            </div>
            <div class="flex justify-center" v-if="false">
              <h2 class="mt-2 mb-4 text-xl text-sky-500 md:mt-1 md:mb-6">
                View all
                <span class="font-semibold">
                  <router-link :to="{ name: abodeNavItem.name }" v-if="abodeNavItem.isAuthorized">
                    <span class="cursor-pointer font-semibold hover:underline">{{ abodeNavItem.label }}</span>
                  </router-link></span
                >
              </h2>
            </div>
          </div>
          <!-- <div class="pb-5">
            <h3 class="text-base font-semibold leading-6 text-neutral-900">Active Handlers by Generator Status</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <router-link
                :to="{ name: routeNames.handlerList, query: { federalWasteGeneratorIn: [id], status: 'Active' } }"
                v-for="[id, item] in generatorsWithCounts"
                :key="id"
                class="rounded-lg bg-white px-4 py-5 shadow sm:p-6 border border-transparent transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
              >
                <dt class="truncate text-sm font-medium text-neutral-500">{{ item.longDescription }}</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-neutral-900">{{ item.count }}</dd>
              </router-link>
            </dl>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-6 text-neutral-900">Active Handlers by County</h3>
            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <router-link
                :to="{ name: routeNames.handlerList, query: { countyCodeIn: [id], status: 'Active' } }"
                v-for="[id, item] in countyCodesWithCounts"
                :key="id"
                class="rounded-lg bg-white px-4 py-5 shadow sm:p-6 border border-transparent transition-all hover:border-sky-200 hover:bg-sky-100 focus:border-sky-200 focus:bg-sky-100"
              >
                <dt class="truncate text-sm font-medium text-neutral-500">{{ item.description }} County</dt>
                <dd class="mt-1 text-3xl font-semibold tracking-tight text-neutral-900">{{ item.count }}</dd>
              </router-link>
            </dl>
          </div> -->
        </div>

        <SimpleMap class="h-96 w-full bg-neutral-800" :topo-json="hiTopoJson" />

        <!-- <div class="p-5 text-center sm:pt-10" v-if="isBureauOrHd || isInternal">
          <h1
            class="relative inline-block w-full rounded-lg border-4 border-neutral-300 bg-white p-12 text-center sm:w-1/2"
          >
            <FaceSmileIcon class="mx-auto h-12 w-12 text-neutral-400"></FaceSmileIcon>
            <span class="mt-4 block text-xl font-medium text-neutral-900"
              >Welcome {{ userRef.firstName }}!</span
            >
          </h1>
        </div> -->

        <!-- Home features content for auth'd and unauth'd users alike. We toggle widgets based on auth status. (and eventually, user profile config) -->
        <div
          v-if="!isAuthenticated && !isInternal"
          class="mx-0 mb-4 mt-4 bg-sky-50 shadow sm:mx-5 sm:my-5 sm:rounded-lg md:mx-8 md:my-8 xl:mx-auto xl:max-w-5xl"
        >
          <!-- Card Content goes here -->
          <div class="justify-between px-4 py-5 sm:p-10 lg:flex">
            <div>
              <h1 class="text-2xl font-extrabold">Casa</h1>
              <h2 class="mt-2 text-xl text-sky-500 md:mt-1">
                <span class="font-semibold"><LoginButton class="hover:underline" /></span>
                or
                <RegisterButton class="cursor-pointer font-semibold hover:underline">Register</RegisterButton>
                to get started
              </h2>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <p>Casa is a system used to develop, test, and demonstrate MUI components.</p>
              </div>
              <!-- put the stuff we demonstrate here?  -->

              <!-- <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <h3>Recycling</h3>
                <ul>
                  <li>Submit annual recycling reports</li>
                  <li>View historical recycled and diverted materials</li>
                  <li>Secure access to your company or agency data</li>
                </ul>
              </div>
              <div class="prose prose-neutral mt-4 prose-li:marker:text-neutral-400 md:mt-5">
                <h3>Solid Waste Disposal</h3>
                <ul>
                  <li>Submit quarterly, semi-annual, and annual disposal reports</li>
                  <li>View historical disposal data</li>
                  <li>Secure access to your facility data</li>
                </ul>
              </div>-->
            </div>
            <div><router-link to="viewerTest">link plain</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 1 } }">link 1</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 2 } }">link 2</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 3 } }">link 3</router-link></div>
            <div><router-link :to="{ name: 'ViewerTest', query: { ds: 4 } }">link 4</router-link></div>
            <div class="mt-6 items-start justify-start gap-5 space-y-5 sm:flex sm:space-y-0 lg:mt-0">
              <LoginButton :as="BaseButton" variant="priority-hollow" class="w-full sm:w-auto" label="Sign In" />
              <RegisterButton
                :as="BaseButton"
                variant="priority"
                class="w-full sm:w-auto"
                label="Register"
              ></RegisterButton>
            </div>
          </div>
        </div>
      </div>
      <footer class="sticky top-[100vh] mt-20 flex h-10 flex-col items-center p-2">
        <MdrnAttribution />
      </footer>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import { additionalMetadata, metadataContext } from "@casa/views/viewer/viewerSettings";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/vue/20/solid";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { FaceSmileIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { InitialBadge } from "@mui/components/badge";
import { BaseButton } from "@mui/components/button";
import {
  MdrnAttribution,
  // LayoutAppContent,
  NoneFoundFlag,
  SkeletonButton,
  useAppNav,
} from "@mui/components/common";
import { SimpleMap } from "@mui/components/simpleMap";
import { useViewerStore } from "@mui/components/viewer";
import { LoginButton, RegisterButton, useUser } from "@mui/utils/auth/authentication";
import { useAuthorization } from "@mui/utils/auth/authorization";
import {
  // SimpleMap,
  // SkeletonButton,
  noLabelSchema,
  sortByAlphabeticalByField,
} from "@mui/utils/helpers";
import { computed, defineComponent, ref, watch } from "vue";
// import { getFacilitySites } from "@casa/views/facilitySite/facilitySiteApi";
import HomeExternal from "@casa/components/HomeExternal.vue";
import HomeInternal from "@casa/components/HomeInternal.vue";
import HomePublic from "@casa/components/HomePublic.vue";
import hiTopoJson from "@casa/constants/hawaii-counties-topo.json";
import { routeNames } from "@casa/routes";
import LayoutAppContent from "@mui/components/layouts/LayoutAppContent.vue";
import LogoutButton from "@mui/utils/auth/authentication/LogoutButton.vue";

export default defineComponent({
  setup() {
    const viewerStore = useViewerStore();
    viewerStore.metadataContext = metadataContext;
    viewerStore.additionalMetadata = additionalMetadata;
    viewerStore.hydrateDatasetMetadata();
    const { userRef } = useUser();
    const noLabel = noLabelSchema();

    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();
    const { navigationItems, userNavigationItems } = useAppNav();

    // Example usage of throttledAuthorize -
    // authorizationClient.throttledAuthorize(isBureauAR).then((result) => {
    //   isBureau.value = result?.isSuccess;
    // }, handlePreempts);

    // authorizationClient.throttledAuthorize(isHealthDistrictAR).then((result) => {
    //   isHealthDistrict.value = result?.isSuccess;
    // }, handlePreempts);

    type userClaims = {
      type: string;
      value: string;
    };

    const isInternal = computed(
      () => Boolean(userRef.value?.claims?.some((x: userClaims) => x.type === "role" && x.value === "Internal")), //TODO:TS
    );

    const isAuthenticated = computed(() => userRef.value.isAuthenticated);
    const isExternalAuthenticated = computed(() => isAuthenticated?.value && !isInternal.value);

    watch(
      isAuthenticated,
      (newValue) => {
        if (newValue) {
          // execGetOrganizations({ expand: ["RecyclingReports"] });
          // execGetFacilitySites();
        }
      },
      { immediate: true },
    );

    const rrAccessRequest = ref({ entityName: "", requestText: "" });
    rrAccessRequest.value.entityName = "Organization";

    const drAccessRequest = ref({ entityName: "", requestText: "" });
    drAccessRequest.value.entityName = "Facility Site";

    //TODO: probably not the best approach.
    const abodeNavItem = computed(
      () => navigationItems.find((x) => x.label === "Casas")!.items.find((x) => x.name === routeNames.abodes)!,
    );

    return {
      abodeNavItem,
      noLabel,
      navigationItems,
      isExternalAuthenticated,
      isInternal,
      userRef,
      isAuthenticated,
      drAccessRequest,
      rrAccessRequest,
      BaseButton,
      viewerStore,
      hiTopoJson,
      // getFacilitySites,
    };
  },
  components: {
    LayoutAppContent,
    HomeInternal,
    HomeExternal,
    HomePublic,
    LogoutButton,
    SkeletonButton,
    BaseButton,
    // LayoutAppContent,
    InitialBadge,
    LoginButton,
    RegisterButton,
    PlusIcon,
    ChevronRightIcon,
    MdrnAttribution,
    FaceSmileIcon,
    NoneFoundFlag,
    PhoneIcon,
    EnvelopeIcon,
    SimpleMap,
  },
});
</script>
