import {
  getLookupDescription,
  getLookupLongDescription,
  getLookupLongDescriptionWithFallback,
} from "@/components/lookup/helpers.js";
import { RouteLocationRaw } from "vue-router";

export type EntityPreview = {
  label: string;
  title: string;
  prop1?: string;
  prop2?: string;
  to: RouteLocationRaw;
  viewAllTo?: RouteLocationRaw;
};

export type ReadFieldListChildUpdateHandler = (value: boolean) => void;

export enum LookupFormat {
  description = "description",
  longDescription = "longDescription",
  longDescriptionWithFallback = "longDescriptionWithFallback",
}

/** Takes a rawValue and formats it based on its contents. */
export async function readValueFormatter<T extends any>(
  values: { rawValue: T; lookupFormat?: LookupFormat },
  config: { isNullVisible?: boolean; joinAsNewlines?: boolean },
) {
  // Object for values is more extensible, but it also forces all of the values/lookupFormat to be read, which helps the computed that will probably be using this detect its dependencies.
  const joinDelimiter = config.joinAsNewlines ? "\n" : ", ";
  const nullResult = config.isNullVisible ? "—" : "";

  // const accessTheMurn = values.lookupFormat; // computedAsync tracks its dependencies based on its first execution. If props.value is falsey, it'll never reach the murn to add the dependency.
  if (values.rawValue === null || values.rawValue === undefined || values.rawValue === "") {
    return nullResult;
  } else if (values.rawValue === true) {
    return "Yes";
  } else if (values.rawValue === false) {
    return "No";
  } else if (values.lookupFormat) {
    let lookupFormatter = getLookupDescription;
    switch (values.lookupFormat) {
      case LookupFormat.longDescription:
        lookupFormatter = getLookupLongDescription;
      case LookupFormat.longDescriptionWithFallback:
        lookupFormatter = getLookupLongDescriptionWithFallback;
    }

    if (typeof values.rawValue === "string") {
      return await lookupFormatter(values.rawValue);
    } else if (Array.isArray(values.rawValue)) {
      const validVal = values.rawValue.filter((item) => item || item === 0 || item === false);
      if (validVal.length === 0) return nullResult;

      return (await Promise.all(values.rawValue.map((x) => lookupFormatter(x)))).join(joinDelimiter);
    }
  } else if (Array.isArray(values.rawValue)) {
    const validVal = values.rawValue.filter((item) => item || item === 0 || item === false);
    if (validVal.length === 0) return nullResult;

    return values.rawValue.join(joinDelimiter);
  }

  return values.rawValue;
}
