<template>
  <LayoutAppContent>
    <div
      class="flex-1 min-h-0 grid transition-all duration-200 bg-neutral-100"
      :class="
        viewerStore.showSidebar && (!viewerStore.isMobileMode || viewerStore.showDetails !== 'maximize') && !hideSidebar
          ? 'grid-cols-[100%_0] lg:grid-cols-[380px_auto]'
          : 'grid-cols-[0_100%]'
      "
    >
      <!-- Sidebar -->
      <!-- from-sky-50 to-neutral-200 bg-gradient-to-bl -->
      <div class="mr-0.5 shadow flex flex-row transition-all relative">
        <ViewerSidebar />
      </div>
      <div
        class="grid min-h-0 h-full transition-all ease-in-out duration-500"
        :class="[
          viewerStore.showDetails === 'hide' && !hideDetails && 'grid-rows-[1fr_0fr]',
          viewerStore.showDetails === 'show' &&
            !hideDetails &&
            'grid-rows-[0fr_1fr] lg:grid-rows-[1fr_0.2fr] 3xl:grid-rows-[1fr_0.3fr]',
          viewerStore.showDetails === 'maximize' && !hideDetails && 'grid-rows-[0fr_1fr]',
        ]"
      >
        <!-- Map Pane -->
        <div class="flex flex-col bg-neutral-500 transition-[height] min-h-0 relative" id="MapPane">
          <!-- <div id="popup"></div> -->

          <div
            class="absolute top-1 left-2 flex flex-wrap gap-y-1.5 gap-x-2 mr-12"
            :class="[
              viewerStore.showDetails === 'hide' && !hideDetails && 'z-10',
              viewerStore.showDetails === 'show' && !hideDetails && 'lg:z-10',
              viewerStore.showDetails === 'maximize' && !hideDetails && 'hidden',
            ]"
          >
            <!-- prettier-ignore -->
            <ViewerMapDatasetButton 
            v-for="([id, dataset], index) in viewerStore.datasets"
              :dataset="(dataset as ViewerDataset)"
              :index="index"
            ></ViewerMapDatasetButton>
          </div>
          <ViewerTools
            :class="[
              viewerStore.showDetails === 'hide' && !hideDetails && 'z-10',
              viewerStore.showDetails === 'show' && !hideDetails && 'lg:z-10',
              viewerStore.showDetails === 'maximize' && !hideDetails && 'hidden',
            ]"
          ></ViewerTools>
        </div>
        <!-- Details Pane -->
        <div
          class="bg-white transition-[height] min-h-0 relative border-t flex"
          :class="[
            viewerStore.showDetails === 'hide' && !hideDetails && 'border-neutral-300',
            viewerStore.showDetails === 'show' && !hideDetails ? 'border-white lg:border-neutral-300' : '',
            viewerStore.showDetails === 'maximize' && !hideDetails ? 'border-white' : '',
          ]"
        >
          <div
            class="top-0 -translate-y-full absolute right-1.5 bg-white py-1.5 pr-1.5 pl-1 rounded-t-md text-neutral-400 flex gap-x-1"
          >
            <ViewerPanelButton
              :active="false"
              :disabled="viewerStore.showDetails === 'hide'"
              label="Hide Details"
              @click="viewerStore.detailsHide"
              :class="[
                'transition-all bg-neutral-200 text-neutral-500 group',
                viewerStore.showDetails === 'hide' ? 'opacity-50 pointer-events-none' : 'opacity-100',
              ]"
              ><template #icon><ChevronDownIcon class="w-5 h-5 group-hover:translate-y-0.5" /></template
            ></ViewerPanelButton>
            <ViewerPanelButton
              :active="false"
              :label="viewerStore.showDetails === 'hide' ? 'Show Details' : 'Expand Details'"
              @click="expandDetails"
              :class="['transition-all bg-neutral-200 text-neutral-500 group']"
              ><template #icon><ChevronUpIcon class="w-5 h-5 group-hover:-translate-y-0.5" /></template
            ></ViewerPanelButton>
          </div>
          <div v-if="viewerStore.currentLocation" class="overflow-y-auto flex-1">
            <div class="pt-3 md:pb-5" :class="[viewerStore.showDetails === 'maximize' ? 'pt-5' : '']">
              <div class="px-4 sm:px-6 md:px-8 flex items-start justify-between">
                <div class="min-w-0 flex-1">
                  <h2 class="text-large mt-2 font-bold leading-7 text-neutral-800">
                    <!-- <span class="uppercase">{{ viewerStore.currentLocation?.sourceSystem }}</span
                    > -->
                    <span class="text-neutral-500 pr-3">{{ viewerStore.currentDatasetLabel }}</span
                    >{{ viewerStore.currentLocation?.subTitle }}
                  </h2>
                  <h1
                    class="mt-1 text-2xl font-bold leading-7 text-neutral-800 sm:text-3xl sm:tracking-tight mb-2 md:mb-0"
                  >
                    <!-- @slot Slot for the entity's heading if it is not new -->
                    {{ viewerStore.currentLocation?.title }}
                  </h1>
                </div>
                <div class="md:flex lg:mt-0 lg:ml-4">
                  <button
                    type="button"
                    class="flex-none rounded-full h-7 w-7 p-0.5 text-neutral-400 border-neutral-300 hover:bg-neutral-400 hover:border-neutral-400 hover:text-white bg-white"
                    :class="[
                      viewerStore.showDetails === 'show' ? 'lg:hidden' : '',
                      viewerStore.showDetails === 'maximize' ? '' : '',
                    ]"
                  >
                    <XMarkIcon class="h-6 w-6" @click="viewerStore.closeDetails()" />
                  </button>
                </div>
              </div>
            </div>
            <div class="px-4 sm:px-6 md:px-8 pb-4">{{ viewerStore.currentLocation?.summary }}</div>
          </div>
          <div v-else class="bg-neutral-200 p-4 flex min-h-full items-center justify-center flex-1">
            <div>
              <NoneFoundFlag label="No location selected" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </LayoutAppContent>
</template>

<script lang="ts">
import { useAppSettingsStore } from "@/components/common";
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import LayoutAppContent from "@/components/layouts/LayoutAppContent.vue";
import ViewerMapDatasetButton from "@/components/viewer/ViewerMapDatasetButton.vue";
import ViewerPanelButton from "@/components/viewer/ViewerPanelButton.vue";
import ViewerPanelList from "@/components/viewer/ViewerPanelList.vue";
import ViewerSidebar from "@/components/viewer/ViewerSidebar.vue";
import { useViewerStore } from "@/components/viewer/viewerStore";
import { queryToSortByMap, routeQueryToSortByQuery, useApi, usePagedApi, usePaging } from "@/utils/api";
import { useUser } from "@/utils/auth/authentication";
import { Authorize, useAuthorization, withAuthorization } from "@/utils/auth/authorization";
import { formatNumber } from "@/utils/filters";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/20/solid";
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { computed, defineComponent, onMounted, onUnmounted, provide, ref, watch } from "vue";

import { NoneFoundFlag } from "@/components/common";
import { ListFilter, ListSortByArray } from "@/components/list/genericList";
import type { ListFilterViewer, ViewerDataset, ViewerListFilter } from "@/components/viewer";
import { DATA_STATUS, getMapPin, viewerColors } from "@/components/viewer";
import ViewerTools from "@/components/viewer/ViewerTools.vue";
import injectionSymbols from "@/components/viewer/injectionSymbols";
import { Location } from "@/components/viewer/location";
import { getLocationById, getLocations } from "@/components/viewer/locationApi";
import { useRouteQuery } from "@vueuse/router";
import Feature, { type FeatureLike } from "ol/Feature";
import olMap from "ol/Map.js";
import Attribution from "ol/control/Attribution";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import LayerGroup from "ol/layer/Group";
import VectorSource from "ol/source/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";

/** Renders a complete Location Viewer.
 * The user projection, metadataConect, and optional additionalMetadata should be provided where component is utilized. */
export default defineComponent({
  name: "Viewer",
  components: {
    Authorize,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
    SkeletonButton,
    NoneFoundFlag,
    ChevronDownIcon,
    ChevronUpIcon,
    ViewerPanelList,
    ViewerSidebar,
    ViewerPanelButton,
    ViewerMapDatasetButton,
    ViewerTools,
  },
  props: {
    /** Option to hide the sidebar. Default: false*/
    hideSidebar: {
      //TODO: remove this?
      type: Boolean,
      default: false,
    },
    /** Option to hide details. Default: false  */
    hideDetails: {
      //TODO: remove this?
      type: Boolean,
      default: false,
    },
    /** Sort by default specification. Default: "title". Currently not in use */
    sortByDefault: {
      //TODO: implement this
      // type: ListSortByArray,
      default: ["title"],
    },
  },
  setup(props, context) {
    const appSettingsStore = useAppSettingsStore();
    const viewerStore = useViewerStore();
    const { userRef } = useUser();

    const router = useRouter();
    const route = useRoute();

    const baseLayerGroup = new LayerGroup({ layers: [] });

    viewerStore.hydrateDatasetMetadata();

    const expandDetails = () => {
      if (viewerStore.showDetails === "hide") viewerStore.detailsShow();
      else viewerStore.detailsMax();
    };

    const { exec: getLocation } = useApi(getLocationById);

    const attribution = new Attribution({
      collapsible: false,
      className: "absolute bottom-px right-20 px-1 text-xs text-neutral-600 bg-white bg-opacity-70 [&>button]:hidden",
    });

    const {
      dataRef,
      resetData,
      statusRef,
      setStatus,
      exec,
      execPaged,
      isStatusIdle,
      isStatusPending,
      isStatusSuccess,
      isStatusError,
    } = withAuthorization(usePagedApi<Location, Location>(getLocations, {}));

    const sortByQueryRef = useRouteQuery("sortBy", ["title"], {
      transform: (x) => {
        if (typeof x === "string") return [x];
        return x;
      },
    });

    const sortByMapRef = ref(queryToSortByMap(sortByQueryRef.value));

    const { query, debouncedSearch, isBouncingRef, filterState, isDefaultState } = usePaging({
      additionalFilters: {}, //props.api.additionalFilters || {},
      sortByMapRef,
      sortByQueryRef,
      exec,
      execPaged,
      defaultTake: appSettingsStore.listTableTake,
      defaultSort: ["title"], //props.sortByDefault,
    });

    const datasetsQueryRef = useRouteQuery("ds", new Array<string>(), {
      transform: (x) => (Array.isArray(x) ? x.map((y) => parseInt(y)) : [parseInt(x)]),
    });

    const filterChangeHandler = (filterObj: ListFilterViewer) => {
      if (filterObj.isIgnored) {
        delete query[filterObj.field];
      } else if (typeof filterObj.filterValue === "string" || typeof filterObj.filterValue === "number") {
        query[filterObj.field] = filterObj.filterValue;
      } else if (Array.isArray(filterObj.filterValue)) {
        query[filterObj.field] = filterObj.filterValue;
      }

      filterState.value[filterObj.field] = filterObj as ListFilter;

      viewerStore.syncedQueryRef = query;
      resetData();

      //TODO: need to discuss
      if (
        viewerStore.dataStatus === DATA_STATUS.hasDatasets ||
        viewerStore.dataStatus === DATA_STATUS.hasSearch ||
        viewerStore.dataStatus === DATA_STATUS.hasLayers
      ) {
        debouncedSearch();
        viewerStore.doSearch(query);
      }
    };

    let initialUpdate = false;
    onBeforeRouteUpdate((to, from) => {
      //Somehow try/catch here fixes an edge case where the datasetsQueryRef doesn't update properly.
      try {
        if (!from.query.ds) {
          initialUpdate = false;
          return;
        }

        // quick and dirty query change check.
        if (JSON.stringify(to.query) !== JSON.stringify(from.query) || Object.entries(to.query).length === 0) {
          let sortBy = sortByQueryRef.value;
          //improve default handling
          if (!to.query.sortBy) {
            // we've updated the URL, but sortByQueryRef is stale.
            sortBy = ["title"]; //props.sortByDefault;
          }

          // With the way feature selection currently works, you have to reset the selection if any part of the query (dataset, keywords) changes.
          viewerStore.selectFeature(undefined);

          if (JSON.stringify(to.query.ds) !== JSON.stringify(from.query.ds)) {
            let sets = to.query.ds;
            if (sets && !Array.isArray(sets)) sets = [sets];

            viewerStore.datasets.forEach((set) => {
              const isSetVisible = Boolean(sets?.includes(set.id.toString()));

              viewerStore.toggleLayer(set as ViewerDataset, isSetVisible);
            });

            // necessary?
            filterChangeHandler({
              field: "datasetRequest",
              filterValue: viewerStore.datasetPayloadRef,
              isDefault: false,
              isIgnored: false,
            });
          }

          //activeDatasetQueries
          sortByMapRef.value = queryToSortByMap(sortByQueryRef.value);
          query.orderBy = routeQueryToSortByQuery(sortByQueryRef.value);

          viewerStore.syncedQueryRef = query;
          resetData();
          debouncedSearch();
        }
      } catch (error) {
        console.log(error);
      }
    });

    const theMap = ref<olMap>();
    onMounted(() => {
      const aMap = new olMap({
        controls: [attribution],
        target: "MapPane",
      });

      theMap.value = aMap;
      initialUpdate = true;
      viewerStore.fill(aMap, datasetsQueryRef, baseLayerGroup); // This will setup layers, views, etc on the Map.

      // console.log(getUserProjection()); //3857
      // console.log(aMap.getView().getProjection()); //3857
      // console.log(viewerStore.theMap?.getView().getProjection()); //3857
    });

    // Resetting the viewer store frees up resources
    onUnmounted(() => {
      // TODO: add a viewstore dispose method.
      // viewerStore.reset(router, route.query);
    });

    // const sharedFilters = ref<Array<ViewerListFilter>>([]);

    // const registerAndUpdateFilters = (filterObject: ViewerListFilter) => {
    //   for (let i = 0; i < sharedFilters.value.length; ++i) {
    //     let filter = sharedFilters.value[i];

    //     if (filter.field === filterObject.field) {
    //       // Existing filter with no data - remove it.
    //       if (!filterObject.value || filterObject.value.length === 0) {
    //         sharedFilters.value.splice(i, 1);
    //         return;
    //       }
    //       // Existing filter with data - update it.
    //       filter.value = filterObject.value;
    //       filter.label = filterObject.label;
    //       return;
    //     }
    //   }

    //   // New filter with data - add it and sort the array.
    //   if (filterObject.value && filterObject.value.length > 0) {
    //     sharedFilters.value.push(filterObject);
    //     // sharedFilters.value.sort((a, b) => a.sortOrder - b.sortOrder);
    //   }
    // };

    const resultsCount = computed(
      () => formatNumber(dataRef.value?.totalResultsCount, { decimalPlaces: 0, toFixed: false }) ?? "-",
    );

    provide(injectionSymbols.ViewerListPropsKey, {
      baseLayerGroup,
      isDefaultState,
      debouncedSearch,
      dataRef,
      isBouncingRef,
      isStatusPending,
      filterChangeHandler,
      resultsCount,
      datasetsQueryRef,
    });

    return {
      expandDetails,
      appSettingsStore,
      viewerStore,
      userRef,
    };
  },
});
</script>

<style module></style>
