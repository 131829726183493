<template>
  <ListTableCellBase class="hidden text-sm" :class="className">
    <!-- @slot Slot for the contents -->
    <slot name="default">{{ parsedValue }}</slot>
  </ListTableCellBase>
</template>

<script lang="ts">
import { LookupFormat, readValueFormatter } from "@/components/read/read.js";
import { computedAsync } from "@vueuse/core";
import { defineComponent, PropType, ref } from "vue";
import ListTableCellBase from "./ListTableCellBase.vue";

/** Used for a standard data column in a ListTable.
 * Data may be automatically formatted by using the `val` prop or you may do your own formatting in the default slot. */
export default defineComponent({
  name: "ListTableCell",
  components: {
    ListTableCellBase,
  },
  props: {
    /** Specifies the TailWind breakpoint at which the cell becomes visible */
    breakpoint: {
      type: String as PropType<"sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl">,
      required: true,
    },
    /** Darkens the text to 900 to emphasize it over other data in the table. */
    darken: {
      type: Boolean,
      default: false,
    },
    /** Aligns the cell contents to the right and prevents wrapping. Cannot be used with `multiline`. */
    numeric: {
      type: Boolean,
      default: false,
    },
    /** Prevents wrapping. Use with caution. Cannot be used with `multiline`. */
    nowrap: {
      type: Boolean,
      default: false,
    },
    /** Renders newlines in the value. Commonly used with multiple Lookups. Cannot be used with `nowrap` or `numeric` */
    multiline: {
      type: Boolean,
      default: false,
      validator(value, props) {
        return !value || (!props.nowrap && !props.numeric);
      },
    },
    /** Rendering strategy for a Lookup value. If provided, the value will be treated as a Lookup. longDescriptionWithFallback is not supported on list pages. */
    lookupFormat: {
      type: String as PropType<LookupFormat>,
      default: undefined,
      validator(value) {
        return value !== LookupFormat.longDescriptionWithFallback;
      },
    },
    /** Optional value to be parsed and then displayed, this is overridden by using the default slot */
    val: {},
  },
  setup(props) {
    const className = ref(` ${props.breakpoint}:table-cell `);

    if (props.numeric) {
      className.value += " whitespace-nowrap tabular-nums text-right ";
    } else if (props.nowrap) {
      className.value += " whitespace-nowrap ";
    } else if (props.multiline) {
      className.value += " whitespace-pre-wrap ";
    }

    if (props.darken) {
      className.value += " text-neutral-900 ";
    } else {
      className.value += " text-neutral-500 ";
    }

    const parsedValue = computedAsync(async () => {
      return await readValueFormatter(
        { rawValue: props.val, lookupFormat: props.lookupFormat },
        { isNullVisible: false, joinAsNewlines: props.multiline },
      );
    });

    return { className, parsedValue };
  },
});
</script>

<style module></style>
