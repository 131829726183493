<template>
  <!-- @slot Slot to place your content when the authorization check passed -->
  <slot v-if="!isRequestPending && isAuthorized" />
  <!-- @slot Slot to place content when the authorization check failed -->
  <slot v-else-if="!isRequestPending && isAuthorized === false" name="fallback" />
  <!-- @slot Slot to display while the authorization check is in progress -->
  <slot v-else-if="isRequestPending" name="pending"></slot>
</template>

<script lang="ts">
import { getUser } from "@/utils/auth/authentication/useUser";
import { useAuthorization } from "@/utils/auth/authorization/useAuthorization";
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";

/** Wrapper component that allows you to control how to handle content that needs authorization.
 * It can run the auth check for you, and show the content if it is authorized.
 * It also allows you to display different content if the auth check is pending or came back false.
 *
 * Events:
 *
 * `authz-check` -  Let's you listen for permission changes.
 */
export default defineComponent({
  name: "Authorize",
  emits: ["authz-check"], // Let's you listen for permission changes.
  // It's critical that the shape of the props here matches the shape of the AuthRequest class. This enables you to v-bind an AuthRequest instance to the component.
  props: {
    requestKey: Number,
    permissionName: String,
    policyName: String,
    groupName: String,
    roleName: String,
    resource: Object,
    resourceName: String,
    resourceId: String,
    systemIdentifier: String,
    /** Be default, if you specify no props or all propss are falsey, your content will be shown. Setting `hideByDefault` to true will instead hide your content. */
    hideByDefault: {
      type: Boolean,
      default: false,
    },
    withRedirect: {
      type: Boolean,
      default: false,
    },
    /** Throwaway prop just to allow us to v-bind an AuthRequest to Authorize */
    correlationId: String,
    /** Throwaway prop just to allow us to v-bind an AuthRequest to Authorize */
    secondaryIdentifier: [String, Number, Object],
    /** Throwaway prop just to allow us to v-bind an AuthRequest to Authorize */
    identityClaims: [String, Number, Object, Array],
  },
  setup(props, context) {
    const userRef = getUser();
    const router = useRouter();
    const { authorizationClient, AuthRequest, handlePreempts } = useAuthorization();

    const isAuthorized = ref<boolean | null>(null);
    const isRequestPending = ref(false);
    watch(
      [() => userRef.value?.subjectId, props],
      ([subjectId, newProps]) => {
        const request = new AuthRequest();
        const propsForRequest: any = { ...newProps }; // Extra nonsense to make TS happy.
        delete propsForRequest.hideByDefault;
        delete propsForRequest.withRedirect;
        Object.assign(request, propsForRequest);

        if (request.isEmpty()) {
          isAuthorized.value = !newProps.hideByDefault;
          // TODO: If we haven't actually checked, should we still emit an event?
          return;
        }

        isRequestPending.value = true;
        // console.log(request.requestKey, "sent"); // Track if requests are being consolidated
        authorizationClient.throttledAuthorize(request).then((result) => {
          const authorized = result?.isSuccess;
          // console.log(request.requestKey, "authorized");  // Track if requests are being consolidated
          if (!authorized && newProps.withRedirect) {
            router.replace({ name: "NotAuthorized" });
          }
          isAuthorized.value = authorized;
          context.emit("authz-check", authorized);
          isRequestPending.value = false;
        }, handlePreempts);
      },
      { immediate: true },
    );

    return { isAuthorized, isRequestPending };
  },
});
</script>
