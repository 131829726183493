<template>
  <div
    class="flex-1 min-h-0 grid transition-all duration-200 bg-neutral-100"
    :class="showFilterSidebar && !hideFilters ? 'grid-cols-[100%_0] lg:grid-cols-[300px_auto]' : 'grid-cols-[0_100%]'"
  >
    <!-- Filter Sidebar -->
    <div class="from-sky-50 to-neutral-200 bg-gradient-to-bl mr-0.5 shadow overflow-y-auto transition-all">
      <div class="px-2 pb-2" :class="showFilterSidebar ? 'visible' : 'invisible'">
        <div class="flex px-3 py-2 sticky top-0 z-10 -mx-2 lg:hidden backdrop-blur-sm">
          <BaseButton
            @click="
              toggleFilterSidebar();
              focusOnToggle();
            "
            variant="priority"
            label="Apply Filters"
            ref="closeFilterButton"
            class="drop-shadow-md !py-1 !px-2 font-normal flex-1 mr-4"
          ></BaseButton>
          <div class="hidden lg:block"></div>
          <BaseButton
            @click="resetFiltersAndSort()"
            variant="danger-hollow-subtle"
            label="Reset"
            class="drop-shadow-md !py-1 !px-2 font-normal"
          ></BaseButton>
        </div>

        <div class="flex justify-between px-1 pt-2">
          <div class="flex items-end">
            <span class="">Filters</span>
          </div>
          <div class="flex">
            <BaseButton
              @click="resetFiltersAndSort()"
              variant="danger-hollow-subtle"
              label="Reset"
              class="hidden lg:inline-flex !py-1 !px-2 font-normal"
            ></BaseButton>
          </div>
        </div>
        <!-- @slot Place filters here -->
        <slot name="filters"></slot>
      </div>
    </div>

    <div class="flex flex-col min-h-0">
      <div
        class="shadow-inner px-1.5 py-2.5 lg:p-1.5 bg-sky-50 flex border-b border-b-sky-200"
        :class="showFilterSidebar ? 'invisible lg:visible' : 'visible'"
        v-if="!hideFilters"
      >
        <button
          type="button"
          @click="
            toggleFilterSidebar();
            focusOnClose();
          "
          class="ml-1.5 mr-2 my-auto group inline-flex flex-none items-center transition-colors rounded-full border border-transparent bg-sky-600 p-0.5 text-white shadow-sm ring-offset-neutral-100 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 md:p-1"
          ref="toggleFilterButton"
        >
          <ChevronLeftIcon
            class="text-white h-4 w-4"
            :class="[showFilterSidebar ? '' : 'rotate-180']"
            aria-hidden="true"
          />
          <FunnelIcon class="text-white h-5 w-5 group-hover:hidden" aria-hidden="true" />
          <FunnelIconSolid class="text-white h-5 w-5 hidden group-hover:block" aria-hidden="true" />
          <span class="sr-only">Open/Close Filters</span>
        </button>
        <div class="flex-1 items-center flex gap-x-4 lg:gap-x-2 flex-wrap gap-y-3 lg:gap-y-1">
          <template v-for="(filter, filterIndex) in filtersList" :key="filter.field">
            <div
              v-if="filter.value?.length > 0"
              class="inline-flex items-center transition-colors flex-nowrap whitespace-nowrap rounded-md border-neutral-200 border text-xs font-medium"
              :class="[
                filterIndex >= filterCountCutoffSmall &&
                  filterIndex < filterCountCutoffLarge &&
                  'hidden sm:inline-flex',
                filterIndex >= filterCountCutoffLarge && 'hidden lg:inline-flex',
              ]"
            >
              <div
                class="inline-flex items-center pl-2 pr-1 py-1 gap-x-0.5 rounded-l-md bg-neutral-100 text-neutral-600"
              >
                {{ filter.label }}{{ filter.value?.length < 3 ? ":" : "" }}
                <span v-if="filter.value?.length < 3" class="text-black"
                  ><span v-for="(val, index) in filter.value" :key="val"
                    >{{ val }}<span v-if="index < filter.value?.length - 1" class="text-neutral-500"> OR </span></span
                  ></span
                >
                <span v-else class="">({{ filter.value?.length }})</span>
              </div>
              <button
                type="button"
                class="group relative h-6 w-5 rounded-r-md bg-neutral-100 hover:bg-neutral-300"
                @click="removeFilter(filter)"
              >
                <span class="sr-only">Remove {{ filter.label }} Filter</span>
                <svg viewBox="0 0 14 14" class="h-5 w-5 stroke-neutral-700 group-hover:stroke-red-700">
                  <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
              </button>
            </div>
          </template>

          <div
            v-if="numberOfFiltersSmall > 0"
            class="inline-flex items-center transition-colors sm:hidden flex-nowrap whitespace-nowrap rounded-md border-neutral-200 border text-xs font-medium py-1 px-2 bg-neutral-100"
          >
            +{{ numberOfFiltersSmall }} more...
          </div>
          <div
            v-if="numberOfFiltersLarge > 0"
            class="hidden sm:inline-flex lg:hidden items-center transition-colors flex-nowrap whitespace-nowrap rounded-md border-neutral-200 border text-xs font-medium py-1 px-2 bg-neutral-100"
          >
            +{{ numberOfFiltersLarge }} more...
          </div>
        </div>
      </div>
      <section
        aria-labelledby="table-label"
        class="flex flex-col flex-1 overflow-y-scroll lg:order-last"
        :class="showFilterSidebar ? 'invisible lg:visible' : 'visible'"
        id="scrollingTableSection"
      >
        <!-- @slot default -->
        <slot></slot>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/** Component used to render and control the list filters side bar and filter tags.  */
export default { name: "ListFiltersAndData" };
</script>

<script setup lang="ts">
import type { ListSharedFilters, ListTableProps, SharedListFilter } from "@/components/list/genericList";
import { ChevronLeftIcon } from "@heroicons/vue/16/solid";
import { FunnelIcon } from "@heroicons/vue/24/outline";
import { FunnelIcon as FunnelIconSolid } from "@heroicons/vue/24/solid";
import { useFocus } from "@vueuse/core";
import { computed, inject, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import injectionSymbols from "@/components/list/genericList/injectionSymbols.js";
import { useCssBreakpointStore } from "@/components/common/cssBreakpointStore.js";

const router = useRouter();
const route = useRoute();
const { isChildList, isDefaultState, hideFilters } = inject(injectionSymbols.GenericListPropsKey) as ListTableProps;

const filterCountCutoffSmall = ref(3);
const filterCountCutoffLarge = ref(6);

const filtersList = inject(injectionSymbols.ListSharedFiltersKey) as ListSharedFilters;

const closeFilterButton = ref();
const toggleFilterButton = ref();

const getNumberOfActiveFilters = () => {
  let activeFiltersCount = 0;
  filtersList.value.forEach((filter) => {
    if (filter.value?.length > 0) {
      activeFiltersCount += 1;
    }
  });
  return activeFiltersCount;
};

const numberOfFilters = computed(() => filtersList.value.length);

const numberOfFiltersSmall = computed(() => numberOfFilters.value - filterCountCutoffSmall.value);
const numberOfFiltersLarge = computed(() => numberOfFilters.value - filterCountCutoffLarge.value);

const { focused: closeFilterButtonFocus } = useFocus(closeFilterButton);
const { focused: toggleFilterButtonFocus } = useFocus(toggleFilterButton);

const focusOnClose = () => {
  setTimeout(() => {
    closeFilterButtonFocus.value = true;
  }, 0);
};

const focusOnToggle = () => {
  setTimeout(() => {
    toggleFilterButtonFocus.value = true;
  }, 0);
};

const cssBreakpointStore = useCssBreakpointStore();

// Hide by default on child lists, show by default for list pages.
const isSingleColumn = cssBreakpointStore.isMobile || cssBreakpointStore.isSm || cssBreakpointStore.isMd;
const showFilterSidebar = ref(!isChildList && !isSingleColumn);
const toggleFilterSidebar = () => {
  showFilterSidebar.value = !showFilterSidebar.value;
};

const resetFiltersAndSort = () => {
  router.replace({ query: {} });
};

const removeFilter = (filter: SharedListFilter) => {
  let currentQuery = { ...route.query };
  delete currentQuery[filter.field];
  router.replace({ query: currentQuery });
};
</script>
