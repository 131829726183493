import { sanitizeArray } from "@/utils/helpers/sanitizeArray.js";

/** Sanitizes two arrays using `sanitizeArray` and then compares them, ignoring order.
 * @returns true if the two arrays have the same values, regardless of order
 */
export function areArraysEqual<T>(
  arr1: Array<T | null | undefined> | T | undefined | null,
  arr2: Array<T | null | undefined> | T | undefined | null,
) {
  const cleanArray1 = sanitizeArray(arr1);
  const cleanArray2 = sanitizeArray(arr2);
  const testSet = new Set(cleanArray1);
  const size = testSet.size;
  cleanArray2.forEach((x) => testSet.add(x));
  // If the set didn't change in size and the original arrays were the same length, the contents are the same.
  return size === testSet.size && cleanArray1.length === cleanArray2.length;
}
