<template>
  <FormKit
    type="form"
    id="theFormId"
    :value="annotation"
    v-model="annotation"
    @submit="handleSubmit"
    :actions="false"
    autocomplete="off"
  >
    <template #default="{ state: formState }">
      <div class="pb-5 sm:flex-1 sm:overflow-auto" v-if="annotation">
        <FormSection>
          <template #sectionName>{{ (isNew ? "New " : "Edit ") + entityNoteConfigStore.noteLabel }}</template>
          <template #default>
            <FormKit type="textarea" label="Note" name="noteText" validation="required:trim|length:0,255" />
          </template>
        </FormSection>
      </div>
      <FormActions :save-action="saveAction" :cancel-action="cancelAction" :form-state="formState"></FormActions>
    </template>
  </FormKit>
</template>

<script lang="ts">
import { FormActions, FormSection } from "@/components/form";
import { useApi } from "@/utils/api/useApi";
import { childRouteCancelAction } from "@/utils/helpers/routeCancelActions";
import { submitForm } from "@formkit/core";
import { defineComponent, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Annotation } from ".";
import { getAnnotationByIdOrNew, saveAnnotation } from "@/components/entity/note/annotationApi";
import { useEntityNoteConfigStore } from "@/components/entity/note/entityNoteConfigStore";
import { useEntityNoteStore } from "@/components/entity/note/entityNoteStore";

/** This the create and edit page for an entity's notes  */
export default defineComponent({
  name: "EntityNote",
  emits: [],
  props: {
    /** Name of the field ID (entityId) that the note belongs to */
    idField: {
      type: String,
      required: true,
    },
    /** Name of the route for the notes list page for this entity */
    listName: {
      type: String,
      required: true,
    },
    /** Object containing additional Ids and their values that the note should be tied to (E.g. {exampleEntityId: 3}) */
    additionalIds: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const isNew = !route.params.annotationId;
    const entityNoteStore = useEntityNoteStore();
    const entityNoteConfigStore = useEntityNoteConfigStore();

    /*
      Always fetch the entity data again in an Edit component.
      (All others should inject what's provided by the *Base.vue component)
      This reduces the probability of running into a handful of issues.
    */
    const { dataRef: annotation, statusRef, setStatus, exec: fetchAnnotation } = useApi(getAnnotationByIdOrNew);

    watch(
      () => route.params.annotationId,
      (newValue, oldValue) => {
        if (isNew) {
          fetchAnnotation({ [props.idField]: route.params.id });
        } else if (!newValue) {
          return; // Catch route change invalidating the params.
        } else {
          fetchAnnotation({ id: newValue, [props.idField]: route.params.id });
        }
      },
      {
        immediate: true,
      },
    );

    const getParams = () => {
      const { annotationId, ...rest } = route.params;
      return rest;
    };

    const cancelAction = function () {
      childRouteCancelAction({ router, routeName: props.listName, params: getParams() });
    };

    const { dataRef: annotationResult, exec: execSaveAnnotation } = useApi(saveAnnotation);

    const handleSubmit = async (data: Annotation) => {
      for (let key in props.additionalIds) {
        data[key] = props.additionalIds[key];
      }
      const note = await execSaveAnnotation(data);
      if (!note) return; //error saving?

      if (isNew) {
        entityNoteStore.addNote(note);
      } else {
        entityNoteStore.updateNote(note);
      }
      cancelAction();
    };
    const saveAction = function () {
      submitForm("theFormId");
    };

    return {
      cancelAction,
      saveAction,
      handleSubmit,
      annotation,
      isNew,
      entityNoteConfigStore,
    };
  },
  components: {
    FormActions,
    FormSection,
  },
});
</script>
