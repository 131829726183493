<template>
  <span
    class="inline-flex"
    :class.once="[
      bordered && `shadow-[0_0_0_2px_#fff]`,
      size === 'sm' && `rounded-full px-2 text-xs font-semibold leading-5`,
      size === 'md' && `items-center rounded-xl px-3 py-0.5 align-middle text-sm font-medium tracking-normal`,
    ]"
    :class="[badgeColor && $style[badgeColor]]"
  >
    {{ parsedValue }}
  </span>
</template>

<script lang="ts">
import { useApi } from "@/utils/api";
import { computedAsync } from "@vueuse/core";
import { PropType, computed, defineComponent, ref } from "vue";
import { getGroupCodeAndCodeFromLookupMurn, getLookupByMurn, getLookupMetadata } from "../lookup";
import { getLookupGroupByIdOrCode } from "../lookup/lookupGroupApi";
import { LookupFormat, readValueFormatter } from "../read";
import { BadgeColor } from ".";
/**
 * Component for commonly used badge. Typically used with statuses.
 */
export default defineComponent({
  name: "Badge",
  props: {
    /**
     * Text to display inside badge.
     * If provided in addition to the murn, this will take precedence over the label retrived from the murn.
     */
    label: {
      type: String,
    },
    /**
     * Color for the badge.
     * This should be used if the badge is using the label prop and not getting the label and color from a lookup.
     * If provided in addition to the murn, this will take precedence over the color retrived from the murns group-code.
     * @values green, orange, cyan, neutral, red
     */
    // TODO: ENUM THIS
    variant: {
      type: String as PropType<BadgeColor>,
    },
    /**
     * Optional size specification
     * @values sm, md
     */
    size: {
      type: String as PropType<"sm" | "md">,
      default: () => "md",
      validator: (val: string) => !val || ["sm", "md"].includes(val),
    },
    /**
     * Will apply a border to the badge if set to true
     */
    bordered: {
      type: Boolean,
      default: false,
    },
    /** Rendering strategy for a Lookup value. If provided, the value will be treated as a Lookup */
    lookupFormat: {
      type: String as PropType<LookupFormat>,
      default: undefined,
    },
    /** Value to be parsed and then displayed. The label and color will be determined by this value.
     * If the label or color prop are provided, they will take precedence over this.
     */
    murn: {
      type: String,
    },
  },
  setup(props) {
    const badgeColor = ref(BadgeColor.NEUTRAL as string);

    const parsedValue = computedAsync(async () => {
      let result;
      if (props.murn && !props.label) {
        //The not check on label here is just to ensure that the statement of the label being overriden is true.
        result = await readValueFormatter(
          { rawValue: props.murn, lookupFormat: props.lookupFormat },
          { isNullVisible: true, joinAsNewlines: false },
        );

        let meta = await getLookupMetadata(props.murn);
        if (meta?.badgeColor) {
          badgeColor.value = meta.badgeColor as string;
        } else {
          console.warn(`No badge color metadata provided for this lookup: ${props.murn}`);
        }
      } else {
        result = props.label;
      }

      return result;
    });

    if (props.variant) {
      badgeColor.value = props.variant;
    }

    return { badgeColor, parsedValue };
  },
});
</script>

<style module>
.green {
  @apply bg-green-100 text-green-800;
}
.orange {
  @apply bg-orange-100 text-orange-800;
}
.cyan {
  @apply bg-cyan-100 text-cyan-800;
}
.neutral {
  @apply bg-neutral-200 text-neutral-800;
}
.red {
  @apply bg-red-100 text-red-800;
}
</style>
