<template>
  <FormKit
    type="dropdown"
    :options="options"
    :option-loader="optionLoader"
    selection-removable
    empty-message="None Found"
    @node="setNode"
  ></FormKit>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/** Provides access to the Lookups within a LookupGroup. Formkit properties pass through to the internal component.
 * While Lookups are being fetched and filtered, the control will render in a loading state.
 * If a group is not found or the group is empty (possibly due to isActive status or filters) the control will remain in the loading state.
 */
export default { name: "LookupDropdown" };
</script>

<script setup lang="ts">
import { ref, watch } from "vue";
import type { Lookup, LookupFiltersProp } from "@/components/lookup/lookup.js";
import { useLookup } from "@/components/lookup/useLookup.js";
import type { FormKitOptionsItem } from "@formkit/inputs";
import { FormKitNode } from "@formkit/core";
import { until, useDebounceFn } from "@vueuse/core";

const props = defineProps<{
  /** The code of the LookupGroup to use for this dropdown */
  groupCode: string;
  /** Filters enable you to "cascade" dropdowns or to perform additional filtering based on Lookup metadata */
  filters?: LookupFiltersProp;
}>();

const options = ref<{ label: string; value: string }[]>([]);

const optionLoader = async (id: string, cachedOption: FormKitOptionsItem<any>) => {
  // options are set to [] when loading. Wait for them to be defined before using them.
  await until(options).toMatch((x) => (x?.length ?? 0) > 0);

  return options.value?.find((x) => x.value === id)?.label;
};

const setNode = (node: FormKitNode<any>) => {
  const { initialValue, isLoading, loadOptions, filterAndConvertLookups } = useLookup(props.groupCode, node);

  const inputEvent = node.on("input", (val) => {
    if (val && !initialValue.value) {
      initialValue.value = val.payload;
      node.off(inputEvent);
    }
  });

  const debouncedLoadOptions = useDebounceFn(() => {
    return loadOptions(props.filters, node.value);
  }, 100);

  const handleLoadOptions = (
    result: {
      label: string;
      value: string;
    }[],
  ) => {
    if (result) options.value = result;
    else options.value = [];
  };

  debouncedLoadOptions().then(handleLoadOptions);

  watch(
    () => props.groupCode,
    () => {
      debouncedLoadOptions().then(handleLoadOptions);
    },
  );

  watch(
    () => props.filters,
    () => {
      debouncedLoadOptions().then(handleLoadOptions);
    },
    { deep: true },
  );
};
</script>
