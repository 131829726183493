<template>
  <div class="sm:rounded-lg lg:col-span-5">
    <section :aria-labelledby.once="sectiondHeadingIdRef" class="bg-white shadow sm:rounded-lg">
      <div
        class="flex items-center justify-between flex-wrap gap-y-2 px-4 py-5 sm:px-6 border-neutral-200"
        :class="!$slots.entities && 'border-b'"
      >
        <div class="flex-none">
          <div :class="$slots.status && 'md:flex items-center'">
            <h2
              :id.once="sectiondHeadingIdRef"
              class="text-lg font-medium leading-6"
              :class="[
                noData ? 'text-neutral-500' : 'text-neutral-900',
                $slots.status && 'md:inline-block mb-1 md:mb-0 mr-4',
              ]"
            >
              <router-link
                :to="viewTo"
                class="underline decoration-sky-300 underline-offset-2 hover:decoration-2"
                v-if="!noData && viewTo"
                >{{ heading }}</router-link
              >
              <span v-else>{{ heading }}</span>
            </h2>
            <!-- @slot Slot to add a status to the heading - typically a status badge -->
            <slot name="status"></slot>
          </div>
          <p class="mt-1 max-w-2xl text-sm text-neutral-500" :class="[subHeading ? '' : 'hidden']">
            {{ subHeading }}
          </p>
        </div>
        <div class="ml-4 flex min-w-fit flex-none items-start justify-start gap-4" v-if="headingData">
          {{ headingData }}
        </div>
        <div class="ml-4 flex min-w-fit flex-wrap items-start justify-start gap-4" v-if="!headingData">
          <!-- @slot Slot to add addition actions that may requried that are not delete or edit -->
          <slot name="actions"></slot>
          <!-- <BaseButton
            @click="$router.push(viewTo)"
            variant="plain"
            label="View"
            v-if="!hideDefaultActions && !noneFound && viewTo"
            class="min-w-fit"
          ></BaseButton> -->
          <Authorize v-bind="editAuthRequest" hide-by-default>
            <BaseButton
              @click="$emit('editClick')"
              variant="priority-subtle"
              label="Edit"
              v-if="!hideDefaultActions && !noneFound"
            >
              <template #iconBefore><PencilIcon /></template>
            </BaseButton>
            <template #pending><SkeletonButton /></template>
          </Authorize>
          <Authorize v-bind="deleteAuthRequest" hide-by-default>
            <BaseButton
              @click="openConfirmDelete"
              class="min-w-fit"
              variant="danger-subtle"
              icon
              label="Delete"
              v-if="!hideDefaultActions"
            >
              <template #iconBefore><TrashIcon /></template>
            </BaseButton>
          </Authorize>
        </div>
      </div>
      <div v-if="!noData && !noneFound && $slots.entities">
        <!-- @slot Slot to add data about other entities - ReadCardEntitiyPreviews component would be used here -->
        <slot name="entities"></slot>
      </div>
      <div v-if="noneFound" class="bg-neutral-50 p-10 sm:rounded-b-lg">
        <div class="text-center">
          <NoneFoundFlag :label="noneFoundLabel" />
          <Authorize v-bind="createAuthRequest" hide-by-default>
            <div class="mt-6">
              <BaseButton variant="create" :label="'Enter ' + entityLabel" @click="$emit('createClick')"
                ><template #iconBefore><PlusIcon /></template
              ></BaseButton>
            </div>
          </Authorize>
        </div>
      </div>
      <div class="px-4 py-5 sm:px-6" v-if="!noData && !noneFound">
        <!-- @slot Slot to add tabular data to include with the entity  -->
        <slot name="tabularData"></slot>
        <dl class="grid max-w-4xl grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <!-- @slot Slot for the main content. Typically `ReadField` components are used here -->
          <slot></slot>
        </dl>
      </div>
      <div v-if="!noData && !noneFound && viewAllTo">
        <RouterLink
          :to="viewAllTo"
          class="block bg-neutral-50 px-4 py-4 text-center text-sm font-medium text-neutral-500 hover:text-neutral-700 sm:rounded-b-lg"
          >View All {{ entityLabelPlural }}</RouterLink
        >
      </div>
    </section>
    <ConfirmDelete
      v-if="!hideDefaultActions"
      :entity-label="entityLabel"
      :related-entities-label="relatedEntitiesLabel"
      @deleteClick="$emit('deleteClick')"
      ref="confirmDeleteRef"
      :condition="deleteCondition"
    ></ConfirmDelete>
  </div>
</template>

<script lang="ts">
import BaseButton from "@/components/button/BaseButton.vue";
import { NoneFoundFlag } from "@/components/common";
import SkeletonButton from "@/components/common/SkeletonButton.vue";
import ConfirmDelete from "@/components/modals/ConfirmDelete.vue";
import { Authorize, AuthRequest } from "@/utils/auth/authorization";
import { getUniqueId } from "@/utils/helpers";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { PencilIcon, TrashIcon } from "@heroicons/vue/24/solid";
import { defineComponent, PropType, ref } from "vue";
import { RouteLocationRaw } from "vue-router";

/** Component used to style and display data about an entity.
 * This component also allows for actions such as Create, Edit, and Delete to be included. (Edit and Delete are deprecated and should use ActionsButton instead.)
 * It is typically used on the 'View' screen for an entity, but is not limitied this usage
 * (E.g. A single view screen can have one ReadCard for the entity's details and then another ReadCard for some other pertinent information about the entity or for a related entity)
 *
 * Events:
 *
 * `createClick` - Triggered when the "Enter  + `entityLabel`" button is clicked.
 *
 * `editClick` - Deprecated - Triggered when the "Edit" button is clicked.
 *
 * `deleteClick` - Deprecated - Triggered when the "Delete" button is clicked.
 * */

export default defineComponent({
  name: "ReadCard",
  emits: ["createClick", "editClick", "deleteClick"],
  components: {
    Authorize,
    BaseButton,
    ConfirmDelete,
    PencilIcon,
    TrashIcon,
    SkeletonButton,
    NoneFoundFlag,
    PlusIcon,
  },
  props: {
    /** Heading that should go on the top of the card (E.g. 'Example Entity Details') */
    heading: {
      type: String,
      required: true,
    },
    /** Optional subheading to be displayed  */
    subHeading: { type: String },
    /** If present, this will take the place of any actions. */
    headingData: {
      type: String,
      default: null,
    },
    /** This property will hide the edit and delete buttons that are provided by default
     * @deprecated  Switch to using ActionsButton
     */
    hideDefaultActions: { type: Boolean, default: false },
    /** This is the auth request required to create an entity */
    createAuthRequest: { type: AuthRequest, default: null },
    /** This is the auth request required to edit the entity
     *  @deprecated  Switch to using ActionsButton
     */
    editAuthRequest: { type: AuthRequest, default: null },
    /** This is the auth request required to delete the entity
     *  @deprecated  Switch to using ActionsButton and manually creating the ConfimDelete dialog
     */
    deleteAuthRequest: { type: AuthRequest, default: null },
    /** this is the label for the entity. It will be used on the create label */
    entityLabel: {
      type: String,
      default: () => "ENTITY LABEL MISSING ON READ CARD",
    },
    /** this is the plural form of the entity label - used on the 'view all' link (if view all is present).
     * By default, it will be the entity label with an 's' appended
     */
    entityLabelPlural: {
      type: String,
      default: (rawProps: any) => rawProps.entityLabel + "s",
    },
    /** This is the label used in the delete confirmation to alert the user of any entities that will be deleted as a result of the current entity being deleted
     * @deprecated Switch to using ActionsButton and manually creating the ConfimDelete dialog
     */
    relatedEntitiesLabel: {
      type: String,
      default: null,
    },
    /** If provided, this will turn the heading into a link that routes to this location */
    viewTo: {
      type: Object as PropType<RouteLocationRaw>,
    },
    /** If provided, this will add a section at the bottom of this card that routes to this location to 'view all' of the provided entity type */
    viewAllTo: {
      type: Object as PropType<RouteLocationRaw>,
    },
    /** This is an optional function to provide is there needs to be some validation before a delete can occur (E.g. Can't delete an entity if it is being used a FK on another entity)
     *  @deprecated Switch to using ActionsButton and manually creating the ConfimDelete dialog
     */
    deleteCondition: {
      type: Function,
      default: null,
    },
    /** This can be provided to remove most contents of the card if there is no data available  */
    noData: {
      type: Boolean,
      default: false,
    },
    /** If provided, this will display the none found flag and allow the create button to appear */
    noneFound: {
      type: Boolean,
      default: false,
    },
    /** This is an optional label to include with the `noneFound` property if you want to alter the wording. Defaults to "None Found" */
    noneFoundLabel: {
      type: String,
      default: "None Found",
    },
  },
  setup(props, context) {
    const sectiondHeadingIdRef = ref(getUniqueId("sectionHeading"));

    const confirmDeleteRef = ref<typeof ConfirmDelete | null>(null);
    const openConfirmDelete = function () {
      confirmDeleteRef.value?.openConfirmation();
    };

    return {
      sectiondHeadingIdRef,
      openConfirmDelete,
      confirmDeleteRef,
    };
  },
});
</script>

<style module></style>
