import { longDate, timeOnly } from "@/utils/filters/index.js";
import { getMurnString } from "@/utils/helpers/murn.js";

import type {
  AuditEntry,
  EntityAuditActivityType,
  EntityAuditActivityTypes,
  EntityAuditItem,
  EntityWithAudits,
  Timeline,
  TimelineGroup,
} from "@/components/entity/audit/index.js";
import { PlusCircleIcon, StarIcon, TrashIcon } from "@heroicons/vue/20/solid";
import { formatDistance } from "date-fns/fp/formatDistance";
import { Ref, unref } from "vue";

export const entityAuditActivityTypes: EntityAuditActivityTypes = {
  added: {
    icon: PlusCircleIcon,
    bgColorClass: "bg-green-500",
    textColorClass: "text-green-500",
  },
  modified: {
    bgColorClass: "bg-neutral-400",
    textColorClass: "text-neutral-500",
  },
  deleted: {
    icon: TrashIcon,
    bgColorClass: "bg-neutral-400",
    textColorClass: "text-neutral-500",
  },
  statusChange: { icon: StarIcon, bgColorClass: "bg-sky-500", textColorClass: "text-sky-500" },
};

export class TimelineItem {
  constructor(id: string, type: EntityAuditActivityType, action: string, audituserName: string, date: Date) {
    this.id = id;
    this.type = type;
    this.action = action;
    this.auditUserName = audituserName;
    this.date = date;
  }
  id: string;
  type: EntityAuditActivityType;
  action: string;
  auditUserName: string;
  date: Date;
  displayDate?: string; // "30m ago", //widget
  displayTime?: string; // "3:00 PM" // regular
  changes?: any; // Future use for showing change details.
}

export const getEntityAudits = (auditEntries: AuditEntry[], entityType: string, entityId: number): Timeline => {
  const now = new Date();
  const groupMap: Map<string, TimelineGroup> = new Map();

  auditEntries.forEach((auditGroup) => {
    // Get/Set the group for this day.
    const startTime = new Date(auditGroup.startTimeUtc);
    const groupKey = startTime.toLocaleDateString();
    let group: TimelineGroup | undefined = groupMap.get(groupKey);
    if (!group) {
      group = {
        day: startTime,
        dayDisplay: longDate(startTime),
        items: [],
      };
      groupMap.set(groupKey, group);
    }

    buildTimeline(auditGroup, entityType, entityId, now, group.items);
  });

  const groups: TimelineGroup[] = [...groupMap.values()].sort((a, b) => b.day.getTime() - a.day.getTime());
  groups.forEach((group) => {
    group.items.sort((a, b) => b.date.getTime() - a.date.getTime());
  });
  return groups;
};

export const getEntityAuditsForWidget = (
  auditEntries: AuditEntry[],
  entityType: string,
  entityId: number,
): TimelineItem[] => {
  const timelineItems: TimelineItem[] = [];
  const now = new Date();

  auditEntries.forEach((auditGroup) => {
    // Look through children to find out what kind of change this is.
    buildTimeline(auditGroup, entityType, entityId, now, timelineItems);
  });

  timelineItems.sort((a, b) => b.date.getTime() - a.date.getTime());

  return timelineItems;
};

// If the entity or auditHistory is null, we return null because we don't know for sure.
// Otherwise returns a boolean.
/**
 * Checks if an entity is authorized for audits
 * @param {EntityWithAudits | Ref<EntityWithAudits> | Ref<EntityWithAudits | undefined>} entity Entity to be checked
 * @returns `null` if the entity or audit history is null, `true` if it is authorized, `false` if it is not
 */
export const isAuthorizedForAudits = (
  entity: EntityWithAudits | Ref<EntityWithAudits> | Ref<EntityWithAudits | undefined>,
) => {
  if (unref(entity) === undefined) return null;

  const history = unref(entity)!.auditHistory;
  return !history ? null : history.httpStatusCode !== "403";
};

function buildTimeline(
  auditGroup: AuditEntry,
  entityType: string,
  entityId: number,
  now: Date,
  timelineItems: TimelineItem[],
) {
  const addTimelineItem = (auditItem: EntityAuditItem, actionOveride?: keyof EntityAuditActivityTypes) => {
    let activityType: keyof EntityAuditActivityTypes =
      actionOveride || (auditItem.action.toLocaleLowerCase() as keyof EntityAuditActivityTypes); // TODO improve this

    //change the user name if it was created by a hangfire event
    let auditUserName;
    if (auditItem.auditUser.name === "hangfire") {
      auditUserName = "System";
    } else {
      auditUserName = auditItem.auditUser.name;
    }

    const timelineItem: TimelineItem = new TimelineItem(
      auditItem.correlationId,
      entityAuditActivityTypes[activityType],
      auditItem.action,
      auditUserName,
      new Date(auditItem.dateTimeUtc),
    );

    timelineItem.displayDate = formatDistance(timelineItem.date, now);
    timelineItem.displayTime = timeOnly(timelineItem.date);

    // The defaults above are fine for create, but actions like "modified" may actually be status changes, check for that here.
    if (auditItem.oldValues["Status"] && auditItem.newValues["Status"] !== auditItem.oldValues["Status"]) {
      timelineItem.type = entityAuditActivityTypes.statusChange;
      timelineItem.action = auditItem.newValues["Status"];
    }

    timelineItems.push(timelineItem);
  };

  const murnString = getMurnString(entityType, entityId).toLowerCase();
  let hasPushed = false;
  for (let key in auditGroup.items) {
    const auditItem = auditGroup.items[key];
    if (auditItem.murn.toLowerCase() !== murnString) {
      // For now, skip anything not specific to the entity, in the future when details are supported, these would be converted to "changes"
      continue;
    }

    addTimelineItem(auditItem);
    hasPushed = true;
  }
  // If we haven't pushed, this is probably a change involving children, classify as a modification.
  if (!hasPushed && auditGroup.items[0]) {
    addTimelineItem(auditGroup.items[0], "modified");
  }
}
