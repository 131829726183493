<template>
  <button
    :class="[
      `inline-flex group/button items-center justify-center text-sm font-medium transition-colors duration-150 ease-in disabled:cursor-not-allowed`,
      !isLink && ` rounded-md py-2`,
      !isLink && square && `px-3`,
      !isLink && !square && `px-4`,
      !borderless && !isLink && `border shadow`,
      variants[variant],
    ]"
    v-bind="$attrs"
    type="button"
    @click="toFunction"
  >
    <div
      :class="[isLink ? `h-4 w-4` : `h-5 w-5`, icon ? '' : `-ml-1 mr-2`]"
      aria-hidden="true"
      v-if="$slots.iconBefore"
    >
      <!-- @slot Slot to place an icon before the label -->
      <slot name="iconBefore"></slot>
    </div>
    <span :class="{ 'sr-only': icon }">
      <!-- @slot Slot for the label -->
      <slot name="label">{{ label }}</slot></span
    >
    <div class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" v-if="$slots.iconAfter">
      <!-- @slot Slot to place an icon after the label -->
      <slot name="iconAfter"></slot>
    </div>
  </button>
</template>

<script lang="ts">
// TODO: Improve variant support

import { PropType, computed, defineComponent } from "vue";
import { RouteLocationRaw, useRouter } from "vue-router";

//inline-flex justify-center px-4 py-2 border  shadow-sm text-sm font-medium rounded-md  focus:ring-pink-500"
const variants: { [key: string]: string } = {
  base: ``,
  create: `text-white border-transparent bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "create-hollow": `border-green-600 text-green-600 bg-inherit hover:bg-green-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-neutral-200 disabled:text-neutral-400 disabled:border-neutral-200`,
  plain: `border-neutral-300 text-neutral-700 bg-white hover:bg-neutral-200 hover:text-neutral-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "plain-subtle": `border-neutral-300 text-neutral-700 bg-inherit hover:text-white hover:bg-neutral-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-neutral-400 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  priority: `text-white border-transparent bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "priority-subtle": `border-neutral-300 text-neutral-700 bg-inherit hover:text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "priority-hollow": `border-sky-600 text-sky-600 bg-inherit hover:bg-sky-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-neutral-200 disabled:text-neutral-400 disabled:border-neutral-200`,
  warn: `text-white border-transparent bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  danger: `text-white border-transparent bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "danger-hollow": `border-red-600 text-red-600 bg-inherit hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 disabled:border-neutral-200 `,
  "danger-hollow-subtle": `border-neutral-500 text-neutral-700 bg-inherit hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "danger-subtle": `border-neutral-300 text-neutral-700 bg-inherit hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  link: `text-sky-600 bg-transparent hover:text-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "link-danger": `text-red-600 bg-transparent hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  "link-danger-subtle": `text-neutral-600 bg-transparent hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
  valid: `text-white border-transparent bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-neutral-200 disabled:text-neutral-400 `,
};

/**
 * Standard base styling for buttons
 */
export default defineComponent({
  name: "BaseButton",
  //inheritAttrs: false,
  props: {
    // TODO: connect me to a tooltip component. Overridden by the contents of the "label" slot.
    /**
     * Label to be displayed on button
     */
    label: {
      type: String,
      // required: true,
    },
    /**
     * Optional styling defaults to "plain".
     * @values create, plain, priority, warn, danger, link, valid
     */
    variant: {
      type: String as PropType<
        | "base"
        | "create"
        | "create-hollow"
        | "plain"
        | "plain-subtle"
        | "priority"
        | "priority-subtle"
        | "priority-hollow"
        | "warn"
        | "danger"
        | "danger-hollow"
        | "danger-hollow-subtle"
        | "danger-subtle"
        | "link"
        | "link-danger"
        | "link-danger-subtle"
        | "valid"
      >,
      default: "plain",
    },
    /**
     * This will render the button as an icon only - no text.
     */
    icon: Boolean,
    /**
     * If provided as `true` when the variant isn't `link`, this will change the button padding from `px-4` to `px-3`
     */
    square: Boolean,
    /**
     * If provided as `true` it will prevent the border from being applied to the button
     */
    borderless: Boolean,
    /**
     * If provided, the button will route the user to this location on click
     */
    to: { type: Object as PropType<RouteLocationRaw> },
  },
  setup(props) {
    const router = useRouter();

    const isLink = computed(() => props.variant?.startsWith("link"));

    const toFunction = () => {
      if (!props.to) return;

      router.push(props.to);
    };

    return { isLink, variants, toFunction };
  },
});
</script>

<style module></style>
