import { useAppSettingsStore } from "@/components/common/index.js";

// TODO: Learn about mocking Pinia and add tests.
/**
 * Generates the correct murn string for an entity based on their type and id
 *
 * @param {string} resourcePath Path to the entity. Ex. Permit or Permit/Contact
 * @param {string|number} entityId Id of the entity. Ex. 45 or Pending or PermitType/New
 * @returns {string} Returns the murn string using the correct system identifier, entity type and id
 */
export function getMurnString(resourcePath: string, entityId: string | number) {
  const appSettingsStore = useAppSettingsStore();
  return `murn:${appSettingsStore.systemIdentifier}:${resourcePath}/${entityId}`;
}

/**
 * Compares a given murn string to what the murn string should be for a given entity
 *
 * @param {string} murn First murn string to be compared against
 * @param {string} resourcePath Path to the entity. Ex. Permit or Permit/Contact - used to derive second murn string to compare
 * @param {string|number} entityId Id of the entity. Ex. 45 or Pending or PermitType/New - used to derive second murn string to compare
 * @returns {boolean} Returns true if the murn strings match
 */
export function compareToMurn(murn: string, resourcePath: string, entityId: string | number) {
  return getMurnString(resourcePath, entityId).toLowerCase() === murn.toLowerCase();
}

const defaultMurnRegex = /^murn:([\w-]+):([\w-\/]+)\/([\w-]+)$/;
export const lookupMurnRegex = /^murn:([\w-]+):([\w-]+)\/([\w-\/]+)$/;
/** Parses key/value pairs of data from a murn */
export function getMurnParts(murn: string, regex = defaultMurnRegex) {
  const parts = murn.match(regex);
  return {
    systemIdentifier: parts?.[1],
    resourcePath: parts?.[2],
    id: parts?.[3],
  };
}
