import apiService, { ApiOptions } from "@/utils/api/apiService.js";
import { computed, reactive, ref, Ref, watch } from "vue";
// import { apiHosts } from "@/api/constants/apiHosts";
import { useAppSettingsStore } from "@/components/common/index.js";
import type { NotifierConfig, notifyErrorCb, notifySuccessCb } from "@/components/notifications/index.js";
import { useUser } from "@/utils/auth/authentication/useUser.js";
import type { AuthUser } from "@/utils/user/index.js";
import { CreateAxiosDefaults } from "axios";

export interface BffOptions {
  baseURL: string;
  loginUrl: string;
  logoutUrl: string;
  registerUrl: string;
  userUrl: string;
}

const defaultAxiosOptions = {
  baseURL: window.location.origin,
};

let bffOptions: BffOptions | undefined = undefined;

const getDefaultOptions = () => {
  const appSettingsStore = useAppSettingsStore();
  return {
    ...defaultAxiosOptions,
    loginUrl: appSettingsStore.viteBaseUrl + "auth/login",
    logoutUrl: appSettingsStore.viteBaseUrl + "auth/logout",
    registerUrl: "Not Yet Initialized",
    userUrl: appSettingsStore.viteBaseUrl + "api/auth/user",
  };
};

const api = apiService<AuthUser>(Symbol("AUTHN"), defaultAxiosOptions as CreateAxiosDefaults);

const getUser = async (options: BffOptions, config: NotifierConfig = {}) => {
  const successNotifCb: notifySuccessCb = (options) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb: notifyErrorCb = (options) => {
    options.fetched = "auth session";
    config?.errorNotifCb?.(options);
  };

  var userAuth = await api.get(options.userUrl, {
    successNotifCb,
    errorNotifCb,
  });

  //TODO: error handling?
  return userAuth.data;
};

const addReturnUrl = (base: string) => {
  // make sure we redirect back to the current location
  return (base += "?returnUrl=" + encodeURIComponent(window.location.href));
};

export function installBffAuthn(options: BffOptions): void {
  bffOptions = { ...getDefaultOptions(), ...options };
  const { setUser, userRef } = useUser();

  // hydrate userRef in useUser for use throughout the app.
  getUser(bffOptions).then((user) => {
    setUser(user);
  });
}

export function redirectToLogin() {
  if (!bffOptions) throw new Error("bffAuthPlugin must be installed.");
  window.location.href = addReturnUrl(bffOptions.baseURL + bffOptions.loginUrl + "/");
}

export function redirectToLogout() {
  if (!bffOptions) throw new Error("bffAuthPlugin must be installed.");
  window.location.href = bffOptions.baseURL + bffOptions.logoutUrl;
}

export function redirectToRegister() {
  if (!bffOptions) throw new Error("bffAuthPlugin must be installed.");
  window.location.href = bffOptions.registerUrl;
}
