<template>
  <GenericList v-bind="$props">
    <template
      #default="{
        apiStatus,
        dataRef,
        countLabel,
        isErrorOrEmpty,
        isStatusSuccess,
        isStatusError,
        infiniteLoadHandler,
        sortByQueryRef,
        entityLabelPlural,
      }"
    >
      <ListHeader v-bind="$attrs" :total-results-count="dataRef?.totalResultsCount" :count-label="countLabel">
        <!-- @slot Slot for the header -->
        <slot name="header"></slot
      ></ListHeader>
      <ListFiltersAndData :hide-filters="hideFilters">
        <ol
          v-if="dataRef?.totalResultsCount && !isStatusError"
          role="list"
          class="grid grid-cols-1 gap-y-8 py-4"
          :class="[
            cardCols === 1 ? `grid-cols-1` : ``,
            cardCols === 2 ? `lg:grid-cols-2` : ``,
            cardCols === 3 ? `lg:grid-cols-2 2xl:grid-cols-3` : ``,
          ]"
        >
          <!-- @slot Slot for the cards -->
          <slot name="cards" :items="dataRef?._items"></slot>
        </ol>
        <ListCardError v-if="isStatusError" />
        <ListNotFound
          v-if="dataRef?.totalResultsCount === 0 && isStatusSuccess"
          is-list-cards
          :create-label="createLabel"
        ></ListNotFound>

        <InfiniteLoading target="#scrollingCardsSection" @infinite="infiniteLoadHandler" class="pt-px">
          <template #spinner>
            <div class="mb-2 mt-4 flex w-full place-content-center" v-if="!isStatusSuccess && !isStatusError">
              <FormKitIcon icon="spinner" class="h-8 w-8 animate-spin rounded-full bg-neutral-100 text-neutral-600" />
            </div>
          </template>
        </InfiniteLoading>
      </ListFiltersAndData>
    </template>
  </GenericList>
</template>

<script lang="ts">
// Note: When updating this description - you must also update the md file.

/**
 * Component to display list cards for an entity. Usually used to display the child instances of an entity.
 * Differs from `ListCardsLocal` in that it supports paging.
 */
export default { name: "ListCards" };
</script>

<script setup lang="ts" generic="R extends Paging<PagedType<R>>, T extends Paging<PagedType<T>>">
import type { AuthRequest } from "@/utils/auth/authorization";
import type { Paging, PagedType } from "@/utils/api";
import { FormKitIcon } from "@formkit/vue";
import InfiniteLoading from "v3-infinite-loading";
import type { RouteLocationRaw } from "vue-router";
import type { ListApi, ListSortByArray } from "@/components/list/genericList";
import GenericList from "@/components/list/genericList/GenericList.vue";
import ListFiltersAndData from "@/components/list/genericList/ListFiltersAndData.vue";
import ListHeader from "@/components/list/genericList/ListHeader.vue";
import ListNotFound from "@/components/list/listTable/ListNotFound.vue";
import ListCardError from "@/components/list/listCards/ListCardError.vue";

const props = withDefaults(
  defineProps<{
    /** Label for the entity being used in the list cards  */
    entityLabel: string;
    /** Plural version of the `entityLabel`. If not provided, this will default to appending an "s" to the `entityLabel` */
    entityLabelPlural?: string;
    /** Api object responsible for populating the list cards. `apiCall` property must be present. `config` and `additionalFilters` are optional */
    api: ListApi<R, T>;
    /** Route to the create screen for this entity  */
    createTo?: RouteLocationRaw;
    /** Request for authorizing if the create option is availble  */
    createToAuth?: AuthRequest;
    /** take for the backend call (how many results should be returned) */
    take?: number;
    /** An array of field names for the entity to use as the default sorting property */
    sortByDefault: ListSortByArray;
    /** Renders the list as child list cards */
    isChildList?: boolean;
    /** Label for the create function */
    createLabel?: string;
    /** Optional specification for the number of columns to render the cards in
     * @values 1,2,3
     */
    cardCols?: number;
    /** Optional prop to hide the filters. Currently should be used because filtering for ListCards is still in work.  */
    hideFilters?: boolean;
  }>(),
  {
    isChildList: false,
    cardCols: 1,
    hideFilters: false,
  },
);

defineSlots<{
  header?: (props: {}) => any;
  cards?: (props: { items: PagedType<T>[] | undefined }) => any;
}>();
</script>

<style module></style>
