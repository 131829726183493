<template>
  <component :is="as" type="button" @click.prevent="redirectToLogin">Sign In</component>
</template>

<script lang="ts">
import { redirectToLogin } from "@/utils/auth/authentication/bffAuthService.js";
import { defineComponent } from "vue";

/** An unstyled button used for Logging In. Styles will be automatically inherited. */
export default defineComponent({
  name: "LoginButton",
  components: {},
  props: {
    /** Name string of a registered component or a Component that this should be rendered as.  */
    as: {
      type: [String, Object], // This should be String, Component, but for some reason the import is broken.
      default: "button",
    },
  },
  setup() {
    return { redirectToLogin };
  },
});
</script>
