import { NotAuthorized, NotFound } from "@mui/components/common";
import { EntityAuditDetailed } from "@mui/components/entity/audit";

import Home from "./views/Home.vue";
// import { default as inspectionRoutes, inspectionRouteNames } from "./views/inspection/inspectionRoutes";
import { docGenRouteNames, default as docGenRoutes } from "./views/docGen/docGenRoutes";
import { schemaRouteNames, default as schemaRoutes } from "./views/formKitSchema/schemaRoutes";

import kitchenSinkRoutes from "./views/kitchenSink/kitchenSinkRoutes";
// import loginRoutes from "./views/login/loginRoutes";
import { RouterOptions } from "vue-router";
import { enums } from "./constants/enums";
import { abodeRouteNames, default as abodeRoutes } from "./views/abode/abodeRoutes";
import { appraisalRouteNames, default as appraisalRoutes } from "./views/appraisal/appraisalRoutes";
import {
  historicalLandmarkRouteNames,
  default as historicalLandmarkRoutes,
} from "./views/historicalLandmark/historicalLandmarkRoutes";
import { viewerRouteNames, default as viewerRoutes } from "./views/viewer/viewerRoutes";
// import { documentationRouteNames, default as documentationRoutes } from "./views/documentation/documentationRoutes";

import { organizationRouteNames, default as organizationRoutes } from "./views/organization/organizationRoutes";
// Troubleshoot your routes using this - https://paths.esm.dev

export const routeNames = {
  home: "Home",
  notAuthorized: "NotAuthorized",
  // ...analyticsRouteNames,
  // ...applicationRouteNames,
  // ...complaintRouteNames,
  // ...disposalReportRouteNames,
  // ...facilitySiteRouteNames,
  // ...inspectionRouteNames,
  // ...organizationRouteNames,
  // ...permitRouteNames,
  // ...permitVersionRouteNames,
  // ...personRouteNames,
  // ...recyclingReportRouteNames,
  // ...reportRouteNames,
  // ...swmpRouteNames,
  ...abodeRouteNames,
  ...appraisalRouteNames,
  ...viewerRouteNames,
  ...docGenRouteNames,
  ...schemaRouteNames,
  ...organizationRouteNames,
  // ...documentationRouteNames,
  ...historicalLandmarkRouteNames,
  detailedAudit: "EntityAuditDetailed",
};

export const routes: RouterOptions["routes"] = [
  { path: "/", component: Home, name: routeNames.home, meta: { title: "Home" } },
  {
    path: "/unauthorized",
    component: NotAuthorized,
    name: routeNames.notAuthorized,
    meta: { title: "Not Authorized" },
  },
  {
    path: "/detailedAudit",
    // name: "EntityAuditDetailed",
    name: routeNames.detailedAudit,
    meta: { title: "Detailed Audit History", isForm: true },
    component: {
      render: () => <EntityAuditDetailed entityTypes={enums.entityTypes.toDropDown()} />,
    },
  },
  // ...analyticsRoutes,
  // ...applicationRoutes,
  // ...complaintRoutes,
  // ...personRoutes,
  // ...disposalReportRoutes,
  // ...facilitySiteRoutes,
  // ...inspectionRoutes,
  ...kitchenSinkRoutes,
  ...docGenRoutes,
  ...schemaRoutes,
  // // ...loginRoutes,
  // ...organizationRoutes,
  // ...permitRoutes,
  // ...permitVersionRoutes,
  // ...recyclingReportRoutes,
  // ...reportRoutes,
  // ...swmpRoutes,
  ...abodeRoutes,
  ...appraisalRoutes,
  ...viewerRoutes,
  ...organizationRoutes,
  // ...documentationRoutes,
  ...historicalLandmarkRoutes,
  { path: "/:path(.*)", component: NotFound },
];
