// import documentService from "@casa/services/documentService";
import { apiService, getOtherQueryIdStrings, PagingApiAndNotifierConfig } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";

import { NotifyOptionsSuccess, NotifyOptionsError } from "@mui/components/notifications";
import { apiHosts } from "@casa/api/constants/apiHosts";
import { Document } from "@casa/views/document/document";
import type { AxiosResponse } from "axios";

const URL = "documents";
const ENTITY_NAME = "Document";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService<Document>(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getDocuments = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getDocumentDownloadLink = (id: string | number) => {
  return axiosParams.baseURL + URL + "/" + id + "/download";
};

/**
 * @param {Int} id
 */
const getDocumentById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewDocument = async (ids: any) => {
  //TODO:TS
  return { data: { ...ids } } as AxiosResponse<Document, any>;
};

const getDocumentByIdOrDefault = (ids: any = {}, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  if (!ids.id) {
    return getNewDocument(ids);
  } else {
    return getDocumentById(ids.id, config);
  }
};

const createDocument = (document: Document, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, document, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateDocument = (document: Document, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + document.id, document, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Document} document This cannot be a 'ref' to a document
 */
const saveDocument = (document: Document, config: PagingApiAndNotifierConfig = {}) => {
  if (document.id) {
    return updateDocument(document, config);
  } else {
    return createDocument(document, config);
  }
};

/**
 * @param {Int} id
 */
const deleteDocumentById = (ids: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const otherIdStrings = getOtherQueryIdStrings(ids);
  return api.delete(URL + "/" + ids.id + "?" + otherIdStrings, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  getDocumentDownloadLink,
  deleteDocumentById,
  getDocuments,
  getDocumentById,
  getNewDocument,
  getDocumentByIdOrDefault,
  createDocument,
  updateDocument,
  saveDocument,
};
