/* Not exported with the library. You'll want something similar to this for uniquely identifying external hosts in your App */
const CASA = Symbol("CASA");
const SMART = Symbol("SMART");
const INSIGHT = Symbol("INSIGHT");
const IDADMIN = Symbol("IDADMIN");
const DOCGEN = Symbol("DOCGEN");
const LOCAL_EXPRESS = Symbol("LOCAL_EXPRESS");

export const apiHosts = {
  CASA,
  SMART,
  IDADMIN,
  INSIGHT,
  DOCGEN,
  LOCAL_EXPRESS,
};
