import { apiHosts } from "@casa/api/constants/apiHosts";
import abodeService from "@casa/views/abode/abodeService";
import { NotifyOptionsError, NotifyOptionsSuccess } from "@mui/components/notifications";
import { apiService, PagingApiAndNotifierConfig, type Paging } from "@mui/utils/api";
import { serializeQueryParams } from "@mui/utils/helpers";

import type { AxiosResponse } from "axios";
import { Abode, AbodeNew, AbodeQuery, type CanDeleteAbode } from "./abode";

const URL = "abodes";
const ENTITY_NAME = "Abode";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_CASA_API_BASE_URL,
};
const api = apiService<Abode>(apiHosts.CASA, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)

 */
const getAbodes = (params: AbodeQuery, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getAbodeById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewAbode = async (ids: AbodeIds) => {
  const defaultApp = {
    data: {
      ...abodeService.getInitialData(),
      ...ids,
      countryMurn: "murn:casa:lookup/Country/USA",
    },
  } as AxiosResponse<AbodeNew, any>;

  return defaultApp;
};

/**
 *
 * @param {Object} ids Object having either an `id` for an Application or a `facilitySiteId`, `permitId`, and type
 * @param {ApiOptions} config
 * @returns {api.get}
 */

type AbodeIds = {
  id?: string | number;
};
const getAbodeByIdOrNew = (ids: AbodeIds, config = {}) => {
  if (!ids.id) {
    return getNewAbode(ids);
  } else {
    return getAbodeById(ids.id, config) as Promise<AxiosResponse<AbodeNew, any>>;
  }
};

const createAbode = (abode: Abode, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, abode, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateAbode = (abode: Abode, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + abode.id, abode, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Abode} abode This cannot be a 'ref' to a abode
 */
const saveAbode = (abode: Abode, config = {}) => {
  if (abode.id) {
    return updateAbode(abode, config);
  } else {
    return createAbode(abode, config);
  }
};

/**
 * @param {Int} id
 */
const deleteAbodeById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};
const canDeleteApi = apiService<CanDeleteAbode, Paging<CanDeleteAbode>>(apiHosts.SMART, axiosParams);
const canDeleteAbode = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return canDeleteApi.get(URL + "/can-delete/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  createAbode,
  deleteAbodeById,
  getAbodeById,
  getAbodeByIdOrNew,
  getAbodes,
  getNewAbode,
  saveAbode,
  updateAbode,
  canDeleteAbode,
};
